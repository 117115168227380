import { useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { PaperlessSettingsModal } from '@pumpkincare/shared';

import { stripeErrorStatus } from '../../../../constants/stripe-error-status';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import { HDYHAU_OPTIONS, MY_VET_CLINIC } from '../constants/HDYHAUQuestions';
import {
  createIdentity,
  postFinalizeAddAPetQuote,
  postFinalizeQuote,
  postIdentityHdyhau,
  putPetAddon,
  updateIdentity,
} from '../service/quoteFlowService';
import AddressDetails from './AddressDetails';
import BillingForm from './BillingForm';
import CheckoutSuccessModal from './CheckoutSuccessModal';
import PaymentForm from './PaymentForm';
import QuoteSummary from './QuoteSummary';
import ReferralForm from './ReferralForm';
import ShippingForm from './ShippingForm';

function CheckoutTab(props) {
  const { quote, edit } = props;
  const stripe = useStripe();
  const [create] = useCreate();
  const elements = useElements();
  const skusToIgnore = ['embark-dna-kit'];
  const [shippingAddress, setShippingAddress] = useState({});
  const [HDYHAUAnswer, setHDYHAUAnswer] = useState();
  const [HDYHAUVet, setHDYHAUVet] = useState();
  const [isLoading, toggleLoading] = useBooleanInput(false);
  const [isSuccessModalOpen, toggleSuccessModalOpen] = useBooleanInput(false);
  const [userId, setUserId] = useState();
  const [isPaymentEdit, togglePaymentEdit] = useBooleanInput(false);
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] =
    useBooleanInput(true);

  const [nameOnCard, setNameOnCard] = useState();
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPaperlessSettingsModalOpen, togglePaperlessSettingsModal] =
    useBooleanInput(false);

  const notify = useNotify();

  const validShippingAddress = !!shippingAddress.address1 && !!shippingAddress.city;

  const validBillingAddress =
    isBillingSameAsShipping || (!!billingAddress.address1 && !!billingAddress.city);

  const isFormValid =
    (errors.cardNumber &&
      errors.expirationDate &&
      errors.cvc &&
      nameOnCard !== '' &&
      validShippingAddress &&
      phoneNumber &&
      validBillingAddress) ||
    quote.existingUserDetails;

  const isBilledAnnually =
    quote.billingOption === 'annually' ||
    !!quote.existingUserDetails?.is_charged_annually;

  function handleShipmentAddressChange(event) {
    setShippingAddress({ ...shippingAddress, [event.field]: event.value });
  }

  function handleBillingAddressChange(event) {
    setBillingAddress({ ...billingAddress, [event.field]: event.value });
  }

  function submitQuote(payload) {
    postFinalizeAddAPetQuote(quote.id, payload)
      .then(data => {
        setUserId(data.data.user.id);
        toggleSuccessModalOpen(true);
      })
      .catch(e => {
        notify(`Unable to complete transaction: ${e.message}`, {
          type: 'warning',
        });
      })
      .finally(() => {
        toggleLoading(false);
      });
  }

  function handleHDYHAUChange(event) {
    setHDYHAUAnswer(event.target.value);
  }

  function onHDYHAUVetChange(event) {
    setHDYHAUVet(event);
  }

  async function handleSubmitValidateAddress(isPaperless) {
    toggleLoading();
    if (!quote.existingUserDetails) {
      try {
        await create(
          `${RoutePaths.addresses}/rating/validate`,
          {
            data: {
              street_1: shippingAddress.address1,
              street_2: shippingAddress.address2 || '',
              city: shippingAddress.city,
              zipcode: quote.policy_zipcode,
              state: quote.policy_state,
            },
          },
          { returnPromise: true }
        ).then(a => {
          const adressToBeSent = isBillingSameAsShipping
            ? shippingAddress
            : billingAddress;
          create(
            `${RoutePaths.addresses}/billing/validate`,
            {
              data: {
                country: adressToBeSent.country || 'US',
                street_1: adressToBeSent.address1,
                street_2: adressToBeSent.address2 || '',
                city: adressToBeSent.city,
                zipcode: adressToBeSent.zipcode
                  ? adressToBeSent.zipcode
                  : quote.policy_zipcode,
                state: adressToBeSent.state
                  ? adressToBeSent.state
                  : quote.policy_state,
              },
            },
            { returnPromise: true }
          )
            .then(() => {
              handleSubmitClick(isPaperless);
            })
            .catch(err => {
              toggleLoading();
              notify(err.message, {
                type: 'error',
              });
              return false;
            });
        });
      } catch (err) {
        toggleLoading();
        notify(err.message, { type: 'error' });
        return false;
      }
    } else {
      handleSubmitClick(isPaperless);
    }
  }

  function handleSubmitClick(isPaperless) {
    let promises = [];
    quote.quote_pets.forEach((pet, qpIndex) => {
      let addonPayload = [];
      let removedAddon = false;
      // Iterate addons to find Embark kit and remove it
      pet.addons.forEach((addon, aIndex) => {
        if (skusToIgnore.indexOf(addon.addon.sku) > -1) {
          quote.quote_pets[qpIndex].addons.splice(aIndex, 1);
          removedAddon = true;
          return true;
        }
      });
      // Construct payload with existing non-embark addons
      if (removedAddon) {
        quote.quote_pets[qpIndex].addons.forEach(addon => {
          let addonId = addon.addon.id;
          addonPayload.push({
            addon: { id: addonId },
            installments: addon.installments,
          });
        });
        promises.push(
          putPetAddon(quote.id, quote.quote_pets[qpIndex].id, addonPayload)
        );
      }
    });
    // These promises must run before moving on to finalize to remove Embark addons from quote.
    Promise.all(promises)
      .then(() => {
        let billingAddressPayload, shippingAddressPayload, payload;
        if (quote.existingUserDetails) {
          if (isPaymentEdit) {
            if (errors.cardNumber === true && !nameOnCard) {
              notify(
                'Name on card is required when changing credit card information',
                'warning'
              );
              toggleLoading(false);
              return;
            }
            const userShippingAddress = quote.existingUserDetails.rating_address;
            const userBillingAddress = quote.existingUserDetails.billing_address;

            billingAddressPayload = {
              country: billingAddress.country || userBillingAddress.country,
              street_1: billingAddress.address1 || userBillingAddress.street_1,
              street_2: billingAddress.address2 || userBillingAddress.street_2,
              city: billingAddress.city || userBillingAddress.city,
              zipcode: billingAddress.zipcode || userBillingAddress.zipcode,
              state: billingAddress.state || userBillingAddress.state_province,
              name_on_card: nameOnCard || quote.first_name + ' ' + quote.last_name,
            };
            shippingAddressPayload = {
              street_1: shippingAddress.address1 || userShippingAddress.street_1,
              street_2: shippingAddress.address2 || userShippingAddress.street_2,
              city: shippingAddress.city || userShippingAddress.city,
              zipcode: userShippingAddress.zipcode,
              state: userShippingAddress.state_province,
            };

            payload = {
              billing_information: billingAddressPayload,
              shipping_information: shippingAddressPayload,
              phone: phoneNumber || quote.existingUserDetails.phone,
              billed_annually: isBilledAnnually,
            };

            if (errors.cardNumber === true) {
              if (stripe) {
                stripe
                  .createToken(elements.getElement(CardNumberElement))
                  .then(response => {
                    if (response.error) {
                      notify(`[SOMETHING WENT WRONG]: ${response.error}`, {
                        type: 'error',
                      });
                    } else {
                      payload.stripeToken = response.token.id;
                      submitQuote(payload);
                    }
                  });
              } else {
                notify('Stripe is not initialized', 'warning');
              }
            } else {
              submitQuote(payload);
            }
          } else {
            const payload = { billed_annually: isBilledAnnually };
            submitQuote(payload);
          }
        } else {
          if (!HDYHAUAnswer) {
            notify('How did you hear about Pumpkin is a mandatory field', {
              type: 'warning',
            });
            toggleLoading(false);
            return;
          }

          if (HDYHAUAnswer === MY_VET_CLINIC && !quote.vet && !HDYHAUVet) {
            notify('Please select a veterinarian', 'warning');
            toggleLoading(false);
            return;
          }

          const vetId = HDYHAUVet ? HDYHAUVet.id : quote.vet?.id;

          createIdentity().then(response => {
            const identityId = response.id;
            updateIdentity(identityId, {
              ...quote,
              city: shippingAddress.city,
            }).then(() => {
              postIdentityHdyhau(identityId, {
                quote_id: quote.id,
                order: -1,
                option_identifier: HDYHAUAnswer,
                option_value: HDYHAU_OPTIONS.find(
                  option => option.value === HDYHAUAnswer
                ).label,
                vet_id: HDYHAUAnswer === MY_VET_CLINIC ? vetId : undefined,
              });
            });
          });

          billingAddressPayload = {
            country: billingAddress.country || 'US',
            street_1: billingAddress.address1,
            street_2: billingAddress.address2 || '',
            city: billingAddress.city,
            zipcode: billingAddress.zipcode
              ? billingAddress.zipcode
              : quote.policy_zipcode,
            state: billingAddress.state ? billingAddress.state : quote.policy_state,
            name_on_card: nameOnCard,
          };
          shippingAddressPayload = {
            street_1: shippingAddress.address1,
            street_2: shippingAddress.address2 || '',
            city: shippingAddress.city,
            zipcode: quote.policy_zipcode,
            state: quote.policy_state,
          };

          if (stripe) {
            stripe
              .createToken(elements.getElement(CardNumberElement))
              .then(payload => {
                if (payload.error) {
                  throw new Error(payload.error?.message);
                } else {
                  postFinalizeQuote(
                    quote.id,
                    shippingAddressPayload,
                    isBillingSameAsShipping
                      ? {
                          ...shippingAddressPayload,
                          name_on_card: nameOnCard,
                        }
                      : billingAddressPayload,
                    phoneNumber,
                    payload.token.id,
                    isBilledAnnually,
                    isPaperless
                  )
                    .then(data => {
                      setUserId(data.data.user.id);
                      toggleSuccessModalOpen(true);
                    })
                    .catch(e => {
                      const errorMsg =
                        stripeErrorStatus[e?.message]?.error_description ||
                        e.message;
                      notify(`Unable to complete transaction: ${errorMsg}`, {
                        type: 'warning',
                      });
                    })
                    .finally(() => {
                      toggleLoading(false);
                    });
                }
              })
              .catch(e => {
                notify(`[SOMETHING WENT WRONG]: ${e}`, {
                  type: 'error',
                });
              });
          } else {
            notify('Stripe is not initialized', { type: 'warning' });
          }
        }
      })
      .catch(e => {
        notify(`[SOMETHING WENT WRONG]: ${e}`, {
          type: 'error',
        });
      });
  }

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <div style={{ width: '100%' }}>
          {quote.existingUserDetails ? (
            <>
              <Typography>
                Here is the existing address and payment method associated with this
                account.
              </Typography>
              <Typography style={{ color: '#FB5D3E' }}>
                If you would like to make changes, note that this will update for all
                Pet Plans.{' '}
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={togglePaymentEdit}
                >
                  Edit Details
                </span>
              </Typography>
            </>
          ) : null}

          {isPaymentEdit || !quote.existingUserDetails ? (
            <>
              <ShippingForm
                onPhoneNumberChange={setPhoneNumber}
                quote={quote}
                onAddressChange={handleShipmentAddressChange}
              />
              <Typography
                style={{
                  margin: '60px 0 0',
                  fontSize: '20px',
                  lineHeight: '24px',
                  fontWeight: '500',
                }}
              >
                Payment Information
              </Typography>
              <PaymentForm
                onNameCardChange={setNameOnCard}
                errors={errors}
                onErrorsChange={setErrors}
              />
              <BillingForm
                quote={quote}
                onAddressChange={handleBillingAddressChange}
                isBillingSameAsShipping={isBillingSameAsShipping}
                onSameAsShippingChange={setIsBillingSameAsShipping}
              />
            </>
          ) : (
            <AddressDetails existingUserDetails={quote.existingUserDetails} />
          )}

          {!quote.existingUserDetails ? (
            <ReferralForm
              onHDYHAUChange={handleHDYHAUChange}
              HDYHAUAnswer={HDYHAUAnswer}
              onHDYHAUVetChange={onHDYHAUVetChange}
              quote={quote}
            />
          ) : null}

          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              if (!quote.existingUserDetails) {
                togglePaperlessSettingsModal();
              } else {
                handleSubmitValidateAddress();
              }
            }}
            style={{ marginTop: '32px', marginBottom: '32px', width: '100%' }}
            disabled={!isFormValid || isLoading || isSuccessModalOpen}
          >
            {isLoading ? <CircularProgress style={{ color: 'white' }} /> : 'SUBMIT'}
          </Button>
        </div>
        <div style={{ maxWidth: '500px', marginLeft: '24px' }}>
          <QuoteSummary edit={edit} quote={quote} skusToIgnore={skusToIgnore} />
        </div>
      </div>
      <CheckoutSuccessModal showDialog={isSuccessModalOpen} userId={userId} />
      {isPaperlessSettingsModalOpen ? (
        <PaperlessSettingsModal
          onSaveButtonClick={isPaperless => {
            handleSubmitValidateAddress(isPaperless);
            togglePaperlessSettingsModal();
          }}
          saveButtonLabel='Submit'
          emailAddress={quote.email}
          onClose={togglePaperlessSettingsModal}
        />
      ) : null}
    </>
  );
}

export default CheckoutTab;
