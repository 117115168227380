import { useState } from 'react';
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useCustomer, usePet } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import RoutePaths from '../../../routes';
import { AuditLogsLink } from '../../audit-logs';
import CreateDocsModal from '../create-docs-modal/create-docs-modal';
import PetCancelModal from '../pet-cancel-modal';
import PetDetailHeader from './pet-detail-header';
import PetPlansDatagrid from './pet-plan-datagrid';
import PolicyDatagrid from './policy-datagrid';
import WellnessDatagrid from './wellness-datagrid';

function UserTitle({ user_id, pet_name }) {
  const { data: customerData, isLoading: isCustomerLoading } = useCustomer(user_id);
  return !isCustomerLoading ? (
    <span>
      Customer:{' '}
      {customerData
        ? `${customerData.first_name} ${customerData.last_name} - ${customerData.pumpkin_id} - ${pet_name}`
        : ''}
    </span>
  ) : null;
}

function PetShow() {
  const { punks1012SawWork } = useFlags();
  const { id } = useParams();

  const [isCancelPetModalOpen, toggleCancelPetModal] = useBooleanInput();
  const [policyPlan, setPolicyPlan] = useState();
  const [policyId, setPolicyId] = useState();

  const { data: petData, isLoading: isPetDataLoading } = usePet(id);

  const [isCreateDocModalOpen, toggleCreateDocsModal] = useBooleanInput();
  const [create, { isLoading: isSaving }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  function handlePolicyCancelClick(policy, petName) {
    setPolicyPlan({ ...policy, pet_name: petName });
    toggleCancelPetModal();
  }

  function handlePlanCancelClick(plan, petName) {
    setPolicyPlan({ ...plan, pet_name: petName });
    toggleCancelPetModal();
  }

  function handleShowCreateDocsModal(policyId) {
    setPolicyId(policyId);
    toggleCreateDocsModal();
  }

  function handleCreateDocsClick() {
    create(
      `${RoutePaths.policies}/${policyId}/regenerate-docs`,
      {
        data: {},
        returnJson: 'RAW',
      },
      {
        onSuccess: () => {
          toggleCreateDocsModal();
          refresh();
          notify('Documents successfully enqueued', {
            type: 'success',
          });
        },
        onError: error => {
          toggleCreateDocsModal();
          notify(
            `There was an error while trying to create docs: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  return (
    <Show
      title={
        isPetDataLoading ? (
          <CircularProgress />
        ) : (
          <UserTitle user_id={petData?.user_id} pet_name={petData?.name} />
        )
      }
    >
      <SimpleShowLayout>
        <PetDetailHeader
          auditLog={
            <FunctionField
              label={'Audit Logs'}
              render={(record = {}) => {
                return <AuditLogsLink filter={{ pet_id: record.id }} />;
              }}
            />
          }
        />
        <FunctionField
          render={(record = {}) => {
            return (
              <PolicyDatagrid
                record={record}
                onCreateDocsClick={handleShowCreateDocsModal}
                onPetCancelClick={policy =>
                  handlePolicyCancelClick(policy, record.name)
                }
              />
            );
          }}
        />

        {punks1012SawWork && petData?.wellness ? (
          <FunctionField
            render={(record = {}) => {
              return <WellnessDatagrid record={record} />;
            }}
          />
        ) : (
          <FunctionField
            render={(record = {}) => {
              return (
                <PetPlansDatagrid
                  onPetCancelClick={plan => handlePlanCancelClick(plan, record.name)}
                />
              );
            }}
          />
        )}

        <PetCancelModal
          isOpen={isCancelPetModalOpen}
          onClose={toggleCancelPetModal}
          policy_plan={policyPlan}
        />

        {petData ? (
          <CreateDocsModal
            isOpen={isCreateDocModalOpen}
            onClose={toggleCreateDocsModal}
            onConfirm={handleCreateDocsClick}
            petData={petData}
            isSaving={isSaving}
          />
        ) : null}
      </SimpleShowLayout>
    </Show>
  );
}

export default PetShow;
