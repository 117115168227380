import { Resource } from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FaceIcon from '@mui/icons-material/Face';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getUserGroups } from '../../auth';
import RoutePaths from '../../routes';
import { AddressCreate } from '../../sections/addresses';
import { AuditLogsList } from '../../sections/audit-logs';
import { CancellationList, CancellationShow } from '../../sections/cancellations';
import { CancellationCreate } from '../../sections/cancellations';
import ClaimSubmission from '../../sections/claims/claim-submission/claim-submission';
import ClaimList from '../../sections/claims/ClaimList/view/ClaimList';
import ClaimsLibrary from '../../sections/claims/ClaimList/view/ClaimsLibrary';
import ClaimsProcessing from '../../sections/claims/claims-processing';
import EditClaimAttachment from '../../sections/claims/edit-claim-attachment';
import UploadClaimAttachment from '../../sections/claims/upload-claim-attachment';
import { CustomerEdit, CustomerList, CustomerShow } from '../../sections/customers';
import Dashboard from '../../sections/dashboard/view/Dashboard';
import { InsuranceCreate } from '../../sections/insurance';
import {
  InvoiceCreate,
  InvoiceEdit,
  InvoiceList,
  InvoiceShow,
} from '../../sections/invoices';
import { NoteCreate } from '../../sections/notes';
import OpsUserCreate from '../../sections/ops-users/ops-user-create';
import OpsUserList from '../../sections/ops-users/ops-user-list';
import {
  OrganizationsCreate,
  OrganizationsEdit,
  OrganizationsList,
} from '../../sections/organizations';
import { MgaPetPlanCreate, MgaPetPlanEdit } from '../../sections/PetPlans';
import { PetEdit, PetShow } from '../../sections/pets';
import { PlanList } from '../../sections/plans';
import { QuoteCreate, QuoteEdit, QuoteList } from '../../sections/quotes';
import { RefundCreate } from '../../sections/refunds';
import { ReimbursementDetail } from '../../sections/reimbursement';
import { WellnessCreate, WellnessQuoteCreate } from '../../sections/wellness';
const iconStyle = {
  color: '#1976D2',
  width: '18px',
};

export function createMgaAdminResources() {
  const userAuthGroups = getUserGroups();
  const { punks1012SawWork } = useFlags();
  const isSuper = userAuthGroups.some(role => role === 'SuperAdminUserPoolGroup');
  let quotesResources;
  if (punks1012SawWork) {
    quotesResources = [
      <Resource
        key={RoutePaths.quotes}
        name={RoutePaths.quotes}
        create={QuoteCreate}
        edit={QuoteEdit}
      />,
      <Resource
        key={RoutePaths.allQuotes}
        name={RoutePaths.allQuotes}
        list={QuoteList}
        options={{ label: 'Quotes' }}
        icon={<ShoppingCartIcon style={iconStyle} data-testid='menu-icon-quotes' />}
      />,
    ];
  } else {
    quotesResources = [
      <Resource
        key={RoutePaths.quotes}
        name={RoutePaths.quotes}
        create={QuoteCreate}
        edit={QuoteEdit}
        list={QuoteList}
        options={{ label: 'Quotes' }}
        icon={<ShoppingCartIcon style={iconStyle} data-testid='menu-icon-quotes' />}
      />,
    ];
  }
  return [
    <Resource
      key={RoutePaths.dashboard}
      name={RoutePaths.dashboard}
      options={{ label: 'My Dashboard' }}
      list={Dashboard}
      icon={<HomeIcon style={iconStyle} data-testid='menu-icon-my-dashboard' />}
    />,
    <Resource
      key={RoutePaths.customers}
      name={RoutePaths.customers}
      options={{ label: 'Customers' }}
      list={CustomerList}
      show={CustomerShow}
      edit={CustomerEdit}
      icon={<GroupIcon style={iconStyle} data-testid='menu-icon-customers' />}
    />,
    <Resource
      key={RoutePaths.pets}
      name={RoutePaths.pets}
      show={PetShow}
      edit={PetEdit}
    />,
    <Resource
      key={RoutePaths.claims}
      name={RoutePaths.claims}
      list={ClaimList}
      create={ClaimSubmission}
      show={ClaimsProcessing}
      icon={<AssignmentIcon style={iconStyle} data-testid='menu-icon-assignment' />}
    />,
    <Resource
      key={RoutePaths.cancellationDetails}
      name={RoutePaths.cancellationDetails}
      show={CancellationShow}
    />,
    <Resource
      key={RoutePaths.cancellationsDashboard}
      name={RoutePaths.cancellationsDashboard}
      options={{ label: 'Cancellations' }}
      list={CancellationList}
      icon={<TimerIcon style={iconStyle} data-testid='menu-icon-cancellations' />}
    />,
    ...quotesResources,
    <Resource
      key={RoutePaths.invoices}
      name={RoutePaths.invoices}
      options={{ label: 'Invoices' }}
      create={InvoiceCreate}
      list={InvoiceList}
      show={InvoiceShow}
      edit={InvoiceEdit}
      icon={<AttachMoneyIcon style={iconStyle} data-testid='menu-icon-invoices' />}
    />,
    <Resource
      key={RoutePaths.plans}
      name={RoutePaths.plans}
      options={{ label: 'Prevent Plans' }}
      list={PlanList}
      icon={
        <FormatListNumberedIcon
          style={iconStyle}
          data-testid='menu-icon-prevent-plans'
        />
      }
    />,
    <Resource
      key={RoutePaths.reimbursements}
      name={RoutePaths.reimbursements}
      show={ReimbursementDetail}
    />,
    <Resource
      key={RoutePaths.addresses}
      name={RoutePaths.addresses}
      create={AddressCreate}
    />,
    <Resource key={RoutePaths.products} name={RoutePaths.products} />,
    <Resource key={RoutePaths.diagnosis} name={RoutePaths.diagnosis} />,
    <Resource key={RoutePaths.practices} name={RoutePaths.practices} />,
    <Resource
      key={RoutePaths.claimsLibrary}
      name={RoutePaths.claimsLibrary}
      options={{ label: 'Claims Library' }}
      list={ClaimsLibrary}
    />,

    <Resource
      key={RoutePaths.refunds}
      name={RoutePaths.refunds}
      create={RefundCreate}
    />,

    <Resource
      key={RoutePaths.petPlans}
      name={RoutePaths.petPlans}
      edit={MgaPetPlanEdit}
      create={MgaPetPlanCreate}
    />,

    <Resource key={RoutePaths.notes} name={RoutePaths.notes} create={NoteCreate} />,
    <Resource key={RoutePaths.policies} name={RoutePaths.policies} />,
    <Resource key={RoutePaths.denialReason} name={RoutePaths.denialReason} />,
    <Resource key={RoutePaths.vets} name={RoutePaths.vets} />,
    <Resource key={RoutePaths.quotesUser} name={RoutePaths.quotesUser} />,
    <Resource
      key={RoutePaths.opsUsers}
      name={RoutePaths.opsUsers}
      list={OpsUserList}
      options={{ label: 'Ops-Users' }}
      create={isSuper ? OpsUserCreate : null}
      icon={<FaceIcon style={iconStyle} data-testid='menu-icon-ops-users' />}
    />,
    <Resource
      key={RoutePaths.documentsV2}
      name={RoutePaths.documentsV2}
      create={UploadClaimAttachment}
      edit={EditClaimAttachment}
    />,
    <Resource
      key={RoutePaths.auditLogs}
      name={RoutePaths.auditLogs}
      options={{ label: 'Audit Log' }}
      list={AuditLogsList}
      icon={<TrendingUpIcon style={iconStyle} data-testid='menu-icon-audit-log' />}
    />,
    <Resource
      key={RoutePaths.organizations}
      name={RoutePaths.organizations}
      options={{ label: 'Discounts' }}
      list={OrganizationsList}
      icon={<MoneyOffIcon style={iconStyle} data-testid='menu-icon-discounts' />}
      create={OrganizationsCreate}
      edit={OrganizationsEdit}
    />,
    <Resource
      key={`${RoutePaths.organizations}/discounts`}
      name={`${RoutePaths.organizations}/discounts`}
      options={{ label: 'Orgs/Discounts/Rates' }}
    />,

    <Resource
      key={RoutePaths.cancellations}
      name={RoutePaths.cancellations}
      create={CancellationCreate}
    />,

    <Resource
      name={RoutePaths.medicalRecordsRequests}
      key={RoutePaths.medicalRecordsRequests}
    />,

    <Resource
      key={RoutePaths.wellness}
      name={RoutePaths.wellness}
      create={WellnessCreate}
    />,

    <Resource
      key={RoutePaths.wellnessQuote}
      name={RoutePaths.wellnessQuote}
      create={WellnessQuoteCreate}
    />,

    <Resource
      key={RoutePaths.insurance}
      name={RoutePaths.insurance}
      create={InsuranceCreate}
    />,
  ];
}
