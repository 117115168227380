import { Form, SelectInput, useRecordContext } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  APPROVED_PENDING_REIMBURSEMENT,
  APPROVED_PENDING_VET_REIMBURSEMENT,
  AUTHORITY_REQUEST,
  claimMGAStatusesFilter,
  DENIED,
  ESTIMATE_COMPLETE,
  MGA_APPROVED,
  MGA_IN_REVIEW,
  PENDING_DENIAL,
  REIMBURSEMENT_REVIEW,
  UNVERIFIED,
  VERIFIED,
} from '@pumpkincare/claims';

import { updateClaimStatusModal } from './update-claim-status-modal-style';

const FILTER_OUT_STATUSES = [
  APPROVED_PENDING_VET_REIMBURSEMENT,
  UNVERIFIED,
  VERIFIED,
  MGA_IN_REVIEW,
  AUTHORITY_REQUEST,
  REIMBURSEMENT_REVIEW,
  APPROVED_PENDING_REIMBURSEMENT,
  ESTIMATE_COMPLETE,
  MGA_APPROVED,
  DENIED,
  PENDING_DENIAL,
];

const claimStatuses = claimMGAStatusesFilter.filter(
  status =>
    !FILTER_OUT_STATUSES.some(filterOutStatus => filterOutStatus === status.id)
);

export default function UpdateClaimStatusModal(props) {
  const record = useRecordContext();
  const { onClose, open, onStatusUpdate, disableButton } = props;
  const classes = updateClaimStatusModal();

  function handleSubmit(props) {
    onStatusUpdate(record, props.status_updated);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography className={classes.modalTitle}>Update Status</Typography>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <SelectInput
            variant='outlined'
            fullWidth
            source='status_updated'
            choices={claimStatuses}
            label='Status'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            disabled={disableButton}
            onClick={onClose}
            className={classes.cancelButton}
          >
            CANCEL
          </Button>
          <Button
            disabled={disableButton}
            type='submit'
            className={classes.updateButton}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
