import { useState } from 'react';
import Draggable from 'react-draggable';

import UtilizationMga from '../../../../shared/components/utilization-mga';

export default function UtilizationModal(props) {
  const { onClose, open, petPlanUtilizationData } = props;

  const [activeDrags, setActiveDrags] = useState(0);

  function onStart() {
    setActiveDrags(activeDrags + 1);
  }

  function onStop() {
    setActiveDrags(activeDrags - 1);
  }

  return (
    <Draggable onStart={onStart} onStop={onStop}>
      <div className='box' style={{ display: open ? 'block' : 'none' }}>
        {petPlanUtilizationData ? (
          <UtilizationMga
            onClose={onClose}
            petPlanData={petPlanUtilizationData}
            style={{ border: '1px solid' }}
          />
        ) : null}
      </div>
    </Draggable>
  );
}
