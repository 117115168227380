import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import {
  ENVIRONMENT,
  GOOGLE_TAG_MANAGER_ID,
  LAUNCH_DARKLY_KEY,
  loadGoogleTagManager,
  loadTimeoutScripts,
  SENTRY_DSN,
  SENTRY_RELEASE,
  STRIPE_API_KEY,
  PK_SENTRY_SDK_ENABLE_TRACING,
  PK_SENTRY_SDK_ENABLE_PROFILING,
  PK_SENTRY_SDK_ENABLE_REPLAYS,
  PK_SENTRY_SDK_TRACES_SAMPLE_RATE,
  PK_SENTRY_SDK_PROFILES_SAMPLE_RATE,
  PK_SENTRY_SDK_REPLAYS_SAMPLE_RATE,
} from '@pumpkincare/config';

import { App } from './App';
import { getCurrentUser } from './auth';

const SENTRY_DSN_FALLBACK = 'https://10444b4918018cf161af2cbc99107a0f@sentry.pumpkin.engineering/8';

try {
  Sentry.init({
    environment: ENVIRONMENT,
    dsn: (SENTRY_DSN || SENTRY_DSN_FALLBACK),
    release: SENTRY_RELEASE,
    enableTracing: (PK_SENTRY_SDK_ENABLE_TRACING == 'true' ? true : false),
    tracesSampleRate: (parseFloat(PK_SENTRY_SDK_TRACES_SAMPLE_RATE) || 0),
    profilesSampleRate: (parseFloat(PK_SENTRY_SDK_PROFILES_SAMPLE_RATE) || 0),
    replaysSessionSampleRate: (parseFloat(PK_SENTRY_SDK_REPLAYS_SAMPLE_RATE) || 0),
    replaysOnErrorSampleRate: 1.0,
    // tracePropagationTargets: [
    //   '.pumpkin.',
    //   '.pum.ai',
    //   'localhost',
    // ],
    integrations: function () {
      let intg = [];

      if (PK_SENTRY_SDK_ENABLE_TRACING == 'true') {
        intg.push(Sentry.browserTracingIntegration());
      }
      if (PK_SENTRY_SDK_ENABLE_PROFILING == 'true') {
        intg.push(Sentry.browserProfilingIntegration());
      }
      if (PK_SENTRY_SDK_ENABLE_REPLAYS == 'true') {
        intg.push(Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }));
      }

      return intg;
    }(),
    initialScope: {
      user: function () {
        const loggedInUser = getCurrentUser();
        let email = `shared.services+unauthenticated@pumpkin.care`;  // fallback value

        if (loggedInUser) {
          email = loggedInUser.email;
        }

        return {
          email: email,
        };
      }(),
    },
  });
} catch (e) {
  console.warn('Sentry initialization failed', e)
}

function initStripe() {
  return loadStripe(STRIPE_API_KEY).catch(error => {
    if (ENVIRONMENT === 'production') {
      Sentry.captureException(error);
    } else {
      console.error(error);
    }
  });
}

(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  const loggedInUser = getCurrentUser();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_KEY,
    context: {
      kind: 'user',
      key: loggedInUser ? loggedInUser.email : `shared.services@pumpkin.care`,
    },
  });

  loadGoogleTagManager(GOOGLE_TAG_MANAGER_ID);
  loadTimeoutScripts();

  root.render(
    <Elements
      stripe={initStripe()}
      options={{
        fonts: [
          {
            // load custom font to Stripe iFrame
            cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
          },
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
          },
        ],
      }}
    >
      <LDProvider>
        <App />
      </LDProvider>
    </Elements>
  );
})();
