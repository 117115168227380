import React, { Fragment, useState } from 'react';
import { Button, Container, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import { setProgramOptionsItems } from '@pumpkincare/wellness';

import { formatCurrency } from '../../../../shared/utils';
import { wellnessOptionsStyle } from './wellness-plans-options-style';

const SPECIES_MAP = {
  dog: 'Puppy/Dog',
  cat: 'Kitten/Cat',
};

const WELLNESS_MAP = [
  { key: 'wellnessCare', title: 'Wellness Care' },
  { key: 'diseasePrevention', title: 'Disease Prevention' },
  { key: 'diagnostics', title: 'Diagnostic Screenings' },
  { key: 'preventiveProcedures', title: 'Preventive Procedures' },
  { key: 'support', title: 'Support' },
];

function WellnessPlansOptions({
  wellnessPlans,
  onChange,
  onRemove,
  isLoading,
  programOptions,
  species,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const classes = wellnessOptionsStyle();

  function handleSelectOption(option) {
    onChange(option);
    setSelectedOption(option);
  }

  function handleOnRemove() {
    onRemove();
    setSelectedOption({});
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      gap={'24px'}
      alignItems='stretch'
      className={classes.innerGrid}
      spacing={2}
    >
      {Object.keys(programOptions).map(key => {
        const option = programOptions[key];
        const description = `${SPECIES_MAP[species]} ${key.replace(/^\w/, c =>
          c.toUpperCase()
        )} Plan`;
        const { options } = wellnessPlans || {};
        const rates = options?.[`${key}-${species}`];
        const price = rates?.price;
        const yearlySavings = rates?.yearly_savings;

        const wellnessCare = setProgramOptionsItems(option, 'wellnessCare');

        const diseasePrevention = setProgramOptionsItems(
          option,
          'diseasePrevention'
        );
        const diagnostics = setProgramOptionsItems(option, 'diagnostics');

        const preventiveProcedures = setProgramOptionsItems(
          option,
          'preventiveProcedures'
        );
        const support = setProgramOptionsItems(option, 'support');

        const currentOption = {
          price: rates?.price,
          category: description,
          tier: key,
          wellnessCare,
          diseasePrevention,
          diagnostics,
          preventiveProcedures,
          support,
        };

        const isActive = selectedOption?.category === currentOption.category;
        return (
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              border: isActive ? '2px solid #5368F5' : '1px solid #0000001F',
              borderRadius: '8px',
              padding: `${isActive ? '15px 23px' : '16px 24px'} !important`,
              flexGrow: '1',
              minWidth: '346px',
              minHeight: '360px',
              justifyContent: 'space-between',
              background: 'white',
            }}
            key={`${species}-${key}`}
          >
            <Container sx={{ padding: '0 !important' }}>
              <Typography
                variant='body2'
                sx={{
                  margin: '0 0 16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'capitalize',
                }}
              >
                {description}
              </Typography>

              {Object.keys(wellnessPlans).length > 0 ? (
                <div>
                  <Container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      marginTop: '8px',
                      padding: '0 !important',
                    }}
                  >
                    <div className={classes.priceItem}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        {formatCurrency(price, { areCents: false })}
                      </Typography>

                      <Typography variant='overline' lineHeight='inherit'>
                        Monthly
                      </Typography>
                    </div>

                    <div className={classes.priceItem}>
                      <Typography
                        variant='body2'
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        {formatCurrency(price * 11, {
                          areCents: false,
                        })}
                      </Typography>

                      <Typography variant='overline' lineHeight='inherit'>
                        Annually
                      </Typography>
                    </div>
                  </Container>
                  <Divider sx={{ margin: '16px 0' }} />
                  <Container
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      marginTop: '8px',
                      padding: '0 !important',
                    }}
                  >
                    <div className={classes.priceItem}>
                      <Typography variant='body2' fontWeight='bold'>
                        {formatCurrency(yearlySavings, {
                          hideZero: true,
                          areCents: false,
                        })}
                        {species === 'dog' ? '+' : null}
                      </Typography>

                      <Typography variant='overline' lineHeight='inherit'>
                        Yearly savings
                      </Typography>
                    </div>
                  </Container>
                  <Divider sx={{ margin: '16px 0' }} />
                </div>
              ) : null}

              <div>
                {WELLNESS_MAP.filter(({ key }) => currentOption[key].length).map(
                  ({ key, title }) => (
                    <Fragment key={key}>
                      {' '}
                      <Typography
                        variant='overline'
                        sx={{
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '8px',
                          color: '#00000099',
                        }}
                      >
                        {title}
                      </Typography>
                      {currentOption[key].map(item => {
                        return (
                          <div className={classes.planItem} key={item.label}>
                            <Typography variant='caption'>{item.label}</Typography>

                            <Typography variant='caption'>{item.value}</Typography>
                          </div>
                        );
                      })}
                    </Fragment>
                  )
                )}
              </div>
            </Container>

            <Button
              sx={{
                width: '100%',
                marginTop: '16px',
                height: '40px',
              }}
              data-testid='select-plan-btn'
              variant={isActive ? 'contained' : 'outlined'}
              disabled={isLoading}
              onClick={() =>
                isActive ? handleOnRemove() : handleSelectOption(currentOption)
              }
            >
              {isActive ? 'Selected' : 'Choose Plan'}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
}

WellnessPlansOptions.defaultProps = {
  wellnessPlans: {},
  programOptions: {},
  onChange: () => {},
  onRemove: () => {},
  isLoading: false,
  species: '',
};

WellnessPlansOptions.propTypes = {
  wellnessPlans: PropTypes.shape({
    options: PropTypes.object,
  }),
  programOptions: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  isLoading: PropTypes.bool,
  species: PropTypes.oneOf(['dog', 'cat']),
};
export default WellnessPlansOptions;
