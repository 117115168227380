import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  TextField,
  TopToolbar,
  useListContext,
  useNotify,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import EditButton from '../../../components/EditButton';
import RoutePaths from '../../../routes';
import { OrganizationsFilter } from '../organizations-filter';
import { OrganizationsListStyles } from './organizations-list.styles';

function CustomActions({ basePath, data }) {
  const styles = OrganizationsListStyles();
  return (
    <Box width='100%'>
      <TopToolbar style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
        <OrganizationsFilter />
        <CreateButton
          className={styles.root}
          data={data}
          variant='contained'
          size='small'
          label='Create Discount'
        />
      </TopToolbar>
    </Box>
  );
}

function DatagridWrapper(props) {
  const notify = useNotify();
  const { isLoading } = useListContext();

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props}>
      <DateField label='Date' source='created_at' />
      <TextField label='Name' source='name' />
      <TextField label='Type' source='org_type' sortable={false} />
      <FunctionField
        label='Discount'
        render={(record = {}) =>
          `${record.discount?.code} (${record.discount?.percentage_off}%)`
        }
      />
      <FunctionField
        label='Pass code'
        render={(record = {}) => {
          return (
            <CopyToClipboard
              text={record.pass_code}
              onCopy={() => {
                notify(`${record.pass_code} copied`, {});
              }}
            >
              <span>{record.pass_code}</span>
            </CopyToClipboard>
          );
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      />
      <TextField label='Association' source='association' sortable={false} />
      <TextField label='Is active?' source='is_active' sortable={false} />
      <FunctionField
        label='Edit'
        render={(record = {}) => {
          return (
            <EditButton
              basePath={`/${RoutePaths.organizations}`}
              record={record}
              state={{ ...record }}
            />
          );
        }}
      />
    </Datagrid>
  );
}

export function OrganizationsList() {
  return (
    <List
      exporter={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
      actions={<CustomActions />}
    >
      <DatagridWrapper />
    </List>
  );
}
