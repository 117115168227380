import { useState } from 'react';
import {
  Button,
  Edit,
  FormDataConsumer,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';

import useBooleanInput from '../../hooks/useBooleanInput';
import { updateCustomer } from '../../shared/services/netServices';
import { getRemainingBalance } from './service/customerService';
import CustomerEditConfirmDialog from './CustomerEditConfirmDialog';

const useStyles = makeStyles(
  {
    customerFormWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    customerFormRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    customerFormItem: {
      minWidth: '220px',
      marginRight: '27px',
    },
  },
  { name: 'customer-edit' }
);

function CustomerTitle() {
  const record = useRecordContext();
  return (
    <span>
      Update Customer:{' '}
      {record
        ? `${record.first_name} ${record.last_name} - PKN${record.external_id}`
        : ''}
    </span>
  );
}

export default function CustomerEdit() {
  const classes = useStyles();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const [openConfirm, setOpenConfirm] = useBooleanInput(false);
  const [remainingBalanceByPets, setRemainingBalanceByPets] = useState([]);

  function handleUpdate(formData, record) {
    const { is_charged_annually } = formData;
    if (is_charged_annually && !record.is_charged_annually) {
      getRemainingBalance(formData.id).then(response => {
        const { data } = response;
        setRemainingBalanceByPets(data);
        setOpenConfirm(true);
      });
    } else {
      handleUpdateCustomer(formData);
    }
  }

  function handleUpdateCustomer(formData) {
    update(
      'users',
      {
        data: formData,
        id: formData.id,
      },
      {
        onSuccess: () => {
          setOpenConfirm(false);
          notify('Customer updated', {});
          refresh();
        },
        onError: error => {
          notify(`Customer update error: ${error.message}`, { type: 'error' });
        },
      }
    );
  }

  function CustomToolbarV2(props) {
    const record = useRecordContext();

    return (
      <Toolbar {...props}>
        <FormDataConsumer>
          {formDataProps => (
            <Button
              label='UPDATE CUSTOMER DETAILS'
              variant='contained'
              color='primary'
              onClick={() => handleUpdate(formDataProps.formData, record)}
            />
          )}
        </FormDataConsumer>
      </Toolbar>
    );
  }

  function CustomerForm() {
    return (
      <div className={classes.customerFormWrapper}>
        <CustomerFormRow>
          <CustomerFormItem>
            <TextInput label='First Name' source='first_name' />
          </CustomerFormItem>
          <CustomerFormItem>
            <TextInput label='Last Name' source='last_name' />
          </CustomerFormItem>
        </CustomerFormRow>
        <CustomerFormRow>
          <CustomerFormItem>
            <TextInput label='Phone Number' source='phone' />
          </CustomerFormItem>
          <CustomerFormItem>
            <TextInput label='Email' source='email' required />
          </CustomerFormItem>
        </CustomerFormRow>
        <CustomerFormRow>
          <CustomerFormItem>
            <BillingFrequencyDropdown />
          </CustomerFormItem>
        </CustomerFormRow>
      </div>
    );
  }

  function CustomerFormRow(props) {
    const { children } = props;
    return (
      <div className={classes.customerFormRow} {...props}>
        {children}
      </div>
    );
  }

  function CustomerFormItem(props) {
    const { children } = props;
    return (
      <div className={classes.customerFormItem} {...props}>
        {children}
      </div>
    );
  }

  function BillingFrequencyDropdown() {
    const record = useRecordContext();
    return (
      <SelectInput
        className={classes.customerFormItem}
        label='Billing Frequency'
        source='is_charged_annually'
        disabled={record?.is_charged_annually}
        choices={[
          { id: false, name: 'Monthly' },
          { id: true, name: 'Annual' },
        ]}
      />
    );
  }

  return (
    <Edit title={<CustomerTitle />}>
      <SimpleForm toolbar={<CustomToolbarV2 />}>
        <CustomerForm />
        <FormDataConsumer>
          {formDataProps => (
            <CustomerEditConfirmDialog
              open={openConfirm}
              setOpen={setOpenConfirm}
              formData={formDataProps.formData}
              remainingBalanceByPets={remainingBalanceByPets}
              confirmUpdate={handleUpdateCustomer}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}
