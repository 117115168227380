import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { checkInputStyles } from './check-input-style';

function CheckInput(props) {
  const { label, onChange } = props;

  const classes = checkInputStyles();

  const [error, setError] = useState(null);

  function handleChange(event) {
    const checkId = event.target.value.trim();

    if (/^chk_[a-zA-Z0-9]+$/.test(checkId)) {
      onChange(true, checkId);
      setError(null);
    } else {
      onChange(false, null);
      setError('Invalid check ID');
    }
  }

  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <input
        type='text'
        className={classes.input}
        onChange={handleChange}
        data-testid='check-id-input'
      />
      <Typography className={classes.error}>{error}</Typography>
      <Typography className={classes.helper}>
        Unique identifier prefixed with chk_{' '}
        <span>Example: chk_534f10783683daa0</span>
      </Typography>
    </div>
  );
}

CheckInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

CheckInput.defaultProps = {
  label: 'Check ID',
  onChange: () => {},
};

export default CheckInput;
