import { GET_ONE, UPDATE } from 'react-admin';

import { BILLING } from '../../constants/AddressType';
import { dataProvider } from '../../lib/dataProvider/provider';
import RoutePaths from '../../routes';

export function getVet(petId) {
  return dataProvider(GET_ONE, RoutePaths.pets, {
    id: petId,
  }).then(response => {
    const billingAddress = response.data.user.addresses.find(
      address => address.type === BILLING
    );
    return {
      vet: response.data.latest_vet,
      customerZipcode: billingAddress.zipcode,
    };
  });
}

export function linkVet(petId, vetId) {
  return dataProvider(UPDATE, RoutePaths.pets, {
    id: `${petId}/vets/${vetId}`,
    returnJson: 'RAW',
  });
}
