import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { messageModalStyle } from './message-modal-style';

function MessageModal(props) {
  const {
    onClose = () => {},
    onClick = () => {},
    isOpen = false,
    title = 'Utilization Update',
    message = '',
    buttonTitle = 'CLOSE',
    type = 'alert',
  } = props;

  const classes = messageModalStyle();

  function renderIcon() {
    switch (type) {
      case 'error':
        return <ErrorOutlineIcon title='error-icon' className={classes.errorIcon} />;
      case 'alert':
        return (
          <NotificationsNoneIcon
            className={classes.notificationIcon}
            title='notification-icon'
          />
        );
      default:
        return (
          <NotificationsNoneIcon
            className={classes.notificationIcon}
            title='notification-icon'
          />
        );
    }
  }

  return (
    <Dialog maxWidth='sm' open={isOpen} onClose={onClose}>
      <DialogContent dividers>
        <div className={classes.container}>
          {renderIcon(type)}
          {title ? <Typography className={classes.title}>{title}</Typography> : null}
          <Typography className={classes.message}>{message}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            onClick={onClick}
            className={classnames({
              [classes.closeButton]: type === 'error',
              [classes.notificationButton]: type === 'alert',
            })}
          >
            {buttonTitle}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

MessageModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  buttonTitle: PropTypes.string,
};

export default MessageModal;
