import { runQueuedTracks } from '@pumpkincare/analytics';
import { SEGMENT_KEY, SEGMENT_SRC_BASE } from './env';

export function loadTimeoutScripts() {
  const onSegmentReady = () => {
    runQueuedTracks();
  };

  setTimeout(() => {
    loadSegment(onSegmentReady);
  }, 200);
}

export function loadSegment(onReady) {
  const analytics = (window.analytics = window.analytics || []);

  if (!analytics.initialize) {
    if (analytics.invoked) {
      window?.console?.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments);
          e.unshift(t);

          analytics.push(e);

          return analytics;
        };
      };

      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t];

        analytics[e] = analytics.factory(e);
      }

      analytics.load = function (t, e) {
        const n = document.createElement('script');
        // Onetrust script will convert this to text/javascript based on block/allow for the user
        n.type = 'text/javascript';
        n.className = 'optanon-category-C0003';
        n.async = !0;
        n.src = `${SEGMENT_SRC_BASE}/analytics.js/v1/${t}/analytics.min.js`;

        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
      analytics.load(SEGMENT_KEY);

      const callback = function () {
        onReady();
        window.analytics.page();
      };

      window.analytics.ready(callback);
    }
  }
}
