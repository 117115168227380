export const CLAIM_FORM = 'Claim Form';
export const INVOICE = 'Invoice';
export const MEDICAL_RECORD = 'Medical Record';

export const claimAttachmentDocumentChoices = [
  { id: 'claim_form', name: CLAIM_FORM },
  { id: 'invoice', name: INVOICE },
  { id: 'medical_record', name: MEDICAL_RECORD },
];

export const MGA_DOCUMENT_TYPES = {
  medical_record: 'Medical Record',
  claim_invoice: 'Claim Invoice',
  claim_adjustment_letter: 'Claim Adjustment Letter',
  claim_appeals_letter: 'Claim Appeals Letter',
  claim_summary: 'Claim Summary',
  claim_form: 'Claim Form',
  application: 'Application',
  new_policy_cover_page: 'New Policy Cover Page',
  non_renewal_notice: 'Non-Renewal Notice',
  renewal_cover_page: 'Renewal Cover Page',
  jacket: 'Jacket',
  declarations_page: 'Declarations Page',
  illness_coverage: 'Illness Coverage',
  amendment_endorsements: 'Amendment Endorsements',
  prevent_customer_agreement: 'Prevent Customer Agreement',
  cancellation_notice: 'Cancellation Notice',
  insurance_policy: 'Insurance Policy',
  generic: 'Generic',
};

export const MGA_CLAIM_ATTACHMENT_TYPES = Object.keys(MGA_DOCUMENT_TYPES).map(
  key => {
    return { id: key, name: MGA_DOCUMENT_TYPES[key] };
  }
);

export const claimAttachmentTypes = {
  claim_form: CLAIM_FORM,
  invoice: INVOICE,
  medical_record: MEDICAL_RECORD,
  claim_invoice: INVOICE,
};
