import React, { useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../shared/utils';
import CheckInput from '../check-input';
import { claimVetReimbursementModalStyles } from './claim-vet-reimbursement-modal-style';

function ClaimVetReimbursementModal(props) {
  const { onClose, open, onReimburseClick, disabledButton } = props;
  const record = useRecordContext();

  const classes = claimVetReimbursementModalStyles();

  const insuranceId = record.subclaims.find(
    subclaim => subclaim.type === 'insurance'
  )?.id;

  const preventSubclaim = record.subclaims.find(
    subclaim => subclaim.type === 'prevent'
  );
  const wellnessSubclaim = record.subclaims.find(
    subclaim => subclaim.type === 'wellness'
  );
  const preventId = preventSubclaim?.id || wellnessSubclaim?.id;

  const programNamespace = record?.policy_program_namespace;

  const insuranceLineItems =
    record.subclaims.find(sub => sub.type === 'insurance')?.line_items_decision ||
    [];
  const preventLineItems =
    record.subclaims.find(sub => sub.type === 'prevent' || sub.type === 'wellness')
      ?.line_items_decision || [];

  const {
    insuranceTotalClaimed,
    insuranceTotalAppliedToDeductible,
    insuranceTotalPaid,
    preventTotalClaimed,
    preventTotalPaid,
  } = useMemo(
    () => ({
      insuranceTotalClaimed: insuranceLineItems.reduce(
        (sum, item) => sum + item.claimed_amount,
        0
      ),
      insuranceTotalAppliedToDeductible: insuranceLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.deductible_amount) ? 0 : item.deductible_amount),
        0
      ),
      insuranceTotalPaid: insuranceLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
      preventTotalClaimed: preventLineItems.reduce(
        (sum, item) => sum + item.claimed_amount,
        0
      ),
      preventTotalPaid: preventLineItems.reduce(
        (sum, item) =>
          sum + (isNaN(item.reimbursible_amount) ? 0 : item.reimbursible_amount),
        0
      ),
    }),
    [insuranceLineItems, preventLineItems]
  );

  const [checkIDs, setCheckIDs] = useState({
    insurance: { isValid: false, value: null },
    prevent: { isValid: false, value: null },
  });

  function areCheckIDsEqual() {
    const insurance = insuranceId ? checkIDs.insurance.value : null;
    const prevent = preventId ? checkIDs.prevent.value : null;
    return insurance === prevent && insurance !== null;
  }

  function isValid() {
    const bothValid =
      (insuranceId ? checkIDs.insurance.isValid : true) &&
      (preventId ? checkIDs.prevent.isValid : true);

    return bothValid && !areCheckIDsEqual();
  }

  function getPayload() {
    const check_ids = [];
    if (insuranceId) {
      check_ids.push({ subclaim: insuranceId, check_id: checkIDs.insurance.value });
    }
    if (preventId) {
      check_ids.push({ subclaim: preventId, check_id: checkIDs.prevent.value });
    }
    return { check_ids };
  }

  function getBankAccountName() {
    let backAccountName;
    switch (programNamespace) {
      case 'cnf':
        backAccountName = 'Pumpkin Insurance Funding Account';
        break;
      case 'ipg':
        backAccountName = 'Pumpkin IAIC Insurance Funding Account';
        break;
      default:
        backAccountName = 'N/A';
    }
    return backAccountName;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant='h4' className={classes.modalTitle}>
        Reimburse
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <Typography variant='h5'>Reimbursement Method</Typography>
        <Typography className={classes.directDeposit}>
          Manual Reimbursement to Vet - Physical Check
        </Typography>

        <Typography variant='h5'>Confirm Reimbursement Details</Typography>

        <div
          style={{
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          {insuranceId ? (
            <div data-testid='insurance'>
              <Typography className={classes.content} variant='h6'>
                {insuranceId}
              </Typography>

              <div className={classes.bankAccount}>
                <div className={classes.bankAccountColLeft}>
                  <Typography className={classes.bankAccountHeader}>
                    Program
                  </Typography>
                  <Typography className={classes.bankAccountTitle}>
                    {programNamespace ? programNamespace : 'N/A'}
                  </Typography>
                </div>

                <div className={classes.bankAccountColRight}>
                  <Typography className={classes.bankAccountHeader}>
                    Bank Account
                  </Typography>

                  <Typography className={classes.bankAccountTitle}>
                    {getBankAccountName()}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  marginTop: '16px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography className={classes.amountTitle}>
                    CLAIMED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalClaimed, { areCents: true })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPROVED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalPaid, { areCents: true })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPLIED TO DEDUCTIBLE
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(insuranceTotalAppliedToDeductible, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
              </div>

              <CheckInput
                label={'Insurance Check ID'}
                onChange={(isValid, value) =>
                  setCheckIDs({ ...checkIDs, insurance: { isValid, value } })
                }
              />
            </div>
          ) : null}

          {preventId ? (
            <div data-testid='prevent'>
              <Typography className={classes.content} variant='h6'>
                {preventId}
              </Typography>

              <div className={classes.bankAccount}>
                <div>
                  <Typography className={classes.bankAccountHeader}>
                    Bank Account
                  </Typography>
                  <Typography className={classes.bankAccountTitle}>
                    Pumpkin Operating Account
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  marginTop: '16px',
                  width: '285px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography className={classes.amountTitle}>
                    CLAIMED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(preventTotalClaimed, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.amountTitle}>
                    APPROVED AMOUNT
                  </Typography>
                  <Typography className={classes.amountValue}>
                    {formatCurrency(preventTotalPaid, {
                      areCents: true,
                    })}
                  </Typography>
                </div>
              </div>

              <CheckInput
                label={
                  wellnessSubclaim?.id
                    ? 'Wellness Check ID'
                    : 'Preventive Essentials Check ID'
                }
                onChange={(isValid, value) =>
                  setCheckIDs({ ...checkIDs, prevent: { isValid, value } })
                }
              />
            </div>
          ) : null}

          {areCheckIDsEqual() ? (
            <Typography className={classes.error}>
              Check IDs must be unique for each subclaim
            </Typography>
          ) : null}
        </div>
      </DialogContent>

      <DialogActions className={classes.modalActions}>
        <Button
          disabled={disabledButton}
          onClick={onClose}
          className={classes.cancelButton}
          variant='outlined'
        >
          CANCEL
        </Button>
        <Button
          onClick={() => onReimburseClick(record, getPayload())}
          className={classes.reimburseButton}
          disabled={disabledButton || !isValid()}
          variant='contained'
        >
          CONFIRM REIMBURSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ClaimVetReimbursementModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onReimburseClick: PropTypes.func,
  disabledButton: PropTypes.bool,
};

ClaimVetReimbursementModal.defaultProps = {
  open: false,
  onClose: () => {},
  onReimburseClick: () => {},
  disabledButton: false,
};

export default ClaimVetReimbursementModal;
