import { useRecordContext } from 'react-admin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  DownloadClaimDocumentSummaryLink,
  ProcessingStep,
} from '@pumpkincare/shared';

export default function StepResolver(props) {
  const {
    handleNextButtonclick,
    handleBackButtonClick,
    classes,
    toggleReimbursementModal,
    disableButton,
  } = props;
  const record = useRecordContext();
  const { fer4153BackButtonAfterRunTheRules } = useFlags();

  const step = record.step || 1;

  const buttonLabel = step <= 1 ? 'NEXT' : 'RUN RULES';

  return step >= 3 ? (
    step === 3 ? (
      <>
        {fer4153BackButtonAfterRunTheRules ? (
          <>
            <Button
              onClick={() => handleBackButtonClick(record)}
              className={classes.backButton}
              color='primary'
              size='medium'
              startIcon={<ChevronLeftIcon />}
            >
              BACK
            </Button>
            <ProcessingStep
              currentStep={step}
              steps={['Items Review', 'Adjudication']}
              style={{ width: '270px' }}
            />
          </>
        ) : null}
        <Button
          onClick={() => handleNextButtonclick({ ...record, step: 2 })}
          className={classes.nextButton}
          disabled={disableButton}
          variant='contained'
        >
          {buttonLabel}
        </Button>
        <Button
          disabled={disableButton}
          onClick={toggleReimbursementModal}
          className={classes.nextButton}
          variant='contained'
        >
          REIMBURSE
        </Button>
      </>
    ) : step === 5 ? (
      <>
        <CheckCircleIcon fontSize='large' className={classes.checkCircle} />
        <DownloadClaimDocumentSummaryLink
          preventClaimId={record.id}
          linkLabel='Claim Summary Document'
          {...props}
        />
      </>
    ) : (
      <Button
        disabled={step >= 4 || disableButton}
        onClick={toggleReimbursementModal}
        className={classes.nextButton}
        variant='contained'
      >
        REIMBURSE
      </Button>
    )
  ) : (
    <>
      <Button
        onClick={() => handleBackButtonClick(record)}
        className={classes.backButton}
        color='primary'
        size='medium'
        disabled={step !== 2}
        startIcon={<ChevronLeftIcon />}
      >
        BACK
      </Button>
      <ProcessingStep
        currentStep={step}
        steps={['Items Review', 'Adjudication']}
        style={{ width: '270px' }}
      />

      <Button
        onClick={() => handleNextButtonclick(record)}
        className={step !== 2 ? classes.newNextButton : classes.nextButton}
        disabled={disableButton}
        variant='contained'
      >
        {buttonLabel}
      </Button>
    </>
  );
}
