import React from 'react';
import { TextInput } from 'react-admin';
import InputAdornment from '@mui/material/InputAdornment';

const PhoneInput = ({ prefix = '', variant = 'outlined', ...props }) => {
  const startAdornment = prefix ? (
    <InputAdornment position='start'>{prefix}</InputAdornment>
  ) : null;

  const resolve = value => {
    let pattern = '[0-9]*';

    const match = String(value).match(new RegExp(pattern, 'g'));
    const result = match ? match[0] : '';
    return result;
  };

  const parse = value => {
    return resolve(value);
  };

  const format = value => {
    return resolve(value);
  };

  return (
    <TextInput
      {...props}
      parse={parse}
      format={format}
      variant={variant}
      InputProps={{
        startAdornment: startAdornment,
      }}
    />
  );
};

export default PhoneInput;
