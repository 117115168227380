import { CreateButton, useRedirect } from 'react-admin';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';

import RoutePaths from '../../../routes';
import { getProductType, petHasValidPlan } from '../../../shared/utils/petUtils';
import { getCurrentPolicy } from '../../../shared/utils/productUtils';
import { useStyles } from './existing-customer-detail-modal-style';

const AVATAR_PLACEHOLDERS = {
  dog: '/assets/images/dog_placeholder.svg',
  cat: '/assets/images/cat_placeholder.svg',
};

function ExistingCustomerDetailModal({ open, user, onAddInsurance, onAddWellness }) {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <Dialog
      open={open}
      aria-label='Show Customer Detail'
      classes={{ paper: classes.paper }}
    >
      <div className={classes.container}>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.customer}>
            <Typography className={classes.title}>
              This customer already exists.
            </Typography>
            <div className={classes.customerInfo}>
              <Typography
                className={classes.details}
              >{`${user.first_name} ${user.last_name}`}</Typography>
              <Typography className={classes.details}>{user.pumpkin_id}</Typography>
              <Typography className={classes.details}>{user.email}</Typography>
            </div>

            <Typography>
              <Link to={`/customers/${user.id}/show`} className={classes.link}>
                VIEW DETAILS
              </Link>
            </Typography>
          </div>

          <div className={classes.addAPet}>
            <Typography className={classes.title}>
              Add a pet for this customer:
            </Typography>
            <div className={classes.addAPetButtons}>
              <Button variant='outlined' color='primary' onClick={onAddInsurance}>
                INSURANCE
              </Button>
              <Button
                variant='outlined'
                color='primary'
                onClick={onAddWellness}
                component={Link}
                to={{
                  pathname: `/${RoutePaths.wellnessQuote}/create`,
                }}
                state={{ is_add_a_pet: true, user }}
              >
                WELLNESS
              </Button>
            </div>
          </div>

          <div className={classes.pets}>
            <Typography className={classes.subtitle}>Modify their pets:</Typography>
            {user.pets
              ? user.pets.map(pet => {
                  if (
                    ['CANCEL_PENDING', 'CANCELLED', 'EXPIRED'].includes(
                      getCurrentPolicy(pet.policies)?.status.toUpperCase()
                    ) &&
                    !petHasValidPlan(pet)
                  ) {
                    return null;
                  }
                  let species = null;
                  if (pet.species.toLowerCase() === 'cat') {
                    species = 'Cat';
                  } else if (pet.species.toLowerCase() === 'dog') {
                    species = 'Dog';
                  }
                  let gender = null;
                  if (pet?.gender === 'M') {
                    gender = 'Male';
                  } else if (pet?.gender === 'F') {
                    gender = 'Female';
                  }
                  const product = getProductType(pet);
                  let age = pet.age;
                  if (!isNaN(age)) {
                    age = age > 1 ? `${age} years old` : `${age} year old`;
                  }
                  return (
                    <div className={classes.pet} key={pet.id}>
                      <Avatar
                        sx={{
                          width: '100px',
                          height: '100px',
                        }}
                        alt={pet?.pet_photo?.location.split('/')[-1]}
                        src={
                          pet?.pet_photo?.location ||
                          AVATAR_PLACEHOLDERS[pet.species]
                        }
                      />
                      <div className={classes.petInfo}>
                        <Typography
                          style={{ marginBottom: '2px' }}
                          className={classes.title}
                        >
                          {pet.name}
                        </Typography>
                        <Typography
                          style={{ marginBottom: '16px' }}
                          className={classes.details}
                        >
                          {pet.pumpkin_id} | {species} | {gender} | {pet?.breed_name}{' '}
                          | {age}
                        </Typography>
                        <div className={classes.products}>
                          <Typography className={classes.details}>
                            Product: {product}
                          </Typography>
                          {product === 'Insurance' ? (
                            <>
                              <CreateButton
                                label='ADD WELLNESS'
                                variant={'outlined'}
                                resource={RoutePaths.wellness}
                                state={{
                                  pet: pet,
                                  user: user,
                                }}
                              />
                              <CreateButton
                                label='ADD PREVENT'
                                variant={'outlined'}
                                resource={RoutePaths.petPlans}
                                state={{
                                  pet: pet,
                                  user: user,
                                }}
                              />
                            </>
                          ) : null}
                          {product === 'Wellness' ? (
                            <CreateButton
                              label='ADD INSURANCE'
                              variant={'outlined'}
                              resource={RoutePaths.insurance}
                              state={{
                                pet: pet,
                                user: user,
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant='text'
            onClick={() => redirect(`/${RoutePaths.allQuotes}`)}
            color='primary'
          >
            CANCEL
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ExistingCustomerDetailModal.defaultProps = {
  onAddInsurance: () => {},
  onAddWellness: () => {},
};

ExistingCustomerDetailModal.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pumpkin_id: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    pets: PropTypes.array,
  }),
  onAddInsurance: PropTypes.func,
  onAddWellness: PropTypes.func,
};

export default ExistingCustomerDetailModal;
