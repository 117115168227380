import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

import { formatCurrency } from '../../../shared/utils/currencyUtils';

export default function ClaimedLineItemHeader({
  petInsuranceUtilizationData,
  classes,
}) {
  const record = useRecordContext();
  const step = record.step || 1;
  return step < 3 ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography className={classes.title}>
        {step === 1 ? null : 'Claimed Line Items'}
      </Typography>
    </div>
  ) : petInsuranceUtilizationData ? (
    <div style={{ alignItems: 'center', width: '300px', marginBottom: '42px' }}>
      <Typography style={{ marginBottom: '14px' }} className={classes.title}>
        Insurance Utilization
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography className={classes.subtitle}>DEDUCTIBLE</Typography>
          <Typography className={classes.description}>{`${formatCurrency(
            petInsuranceUtilizationData.deductible_utilization.utilized,
            { areCents: true }
          )} of ${formatCurrency(
            petInsuranceUtilizationData.deductible_utilization.available +
              petInsuranceUtilizationData.deductible_utilization.utilized,
            { areCents: true }
          )}`}</Typography>
        </div>
        <div>
          <Typography className={classes.subtitle}>ANNUAL LIMIT</Typography>
          <Typography className={classes.description}>{`${formatCurrency(
            petInsuranceUtilizationData.annual_limit_utilization.utilized,
            { areCents: true }
          )} of ${
            petInsuranceUtilizationData.annual_limit_utilization.available >= 0
              ? formatCurrency(
                  petInsuranceUtilizationData.annual_limit_utilization.available +
                    petInsuranceUtilizationData.annual_limit_utilization.utilized,
                  { areCents: true }
                )
              : 'Unlimited'
          }`}</Typography>
        </div>
      </div>
    </div>
  ) : null;
}
