import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PetDetailHeader from '../pet-detail-header/pet-detail-header';
import { createDocsModalStyle } from './create-docs-modal-style';

function CreateDocsModal(props) {
  const { onClose, isOpen, onConfirm, isSaving, petData = {} } = props;

  const classes = createDocsModalStyle();

  function RenderContentHeader({ title }) {
    return (
      <div className={classes.headerContainer}>
        <Typography className={classes.headerTitle}>{title}</Typography>
      </div>
    );
  }

  return (
    <Dialog maxWidth='md' open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography className={classes.modalTitle}>Document Creation</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classnames(classes.modalContentDetail)}>
          <RenderContentHeader title={petData.name} />
          <PetDetailHeader petData={petData} showLine={true} />
          <RenderContentHeader title={'Document to Create:'} />
          <Typography className={classes.documentToGenerate}>
            Full Policy Packet
          </Typography>
          <div>
            <InfoOutlinedIcon className={classes.warnIcon} />
            <Typography className={classes.docGenerationWarning}>
              This document generation is only for changes that do not impact rates.
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            type='submit'
            className={classes.saveButton}
            onClick={onConfirm}
            disabled={isSaving}
          >
            CREATE DOCS
          </Button>
          <Button
            fullWidth
            onClick={onClose}
            className={classes.cancelButton}
            disabled={isSaving}
          >
            CANCEL
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

CreateDocsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  petData: PropTypes.object.isRequired,
  isSaving: PropTypes.bool,
};

export default CreateDocsModal;
