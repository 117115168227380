import { useEffect, useState } from 'react';
import { Button } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { formatCurrency, parseAndFormatDate } from '../../shared/utils';
import { customerDialogStyle } from './CustomerEditConfirmDialogStyle';

export default function CustomerEditConfirmDialog({
  open,
  setOpen,
  formData,
  remainingBalanceByPets,
  confirmUpdate,
}) {
  function handleDialogClose() {
    setOpen(false);
  }

  function getBalanceToDisplay(formData, balanceItem) {
    return formData.is_charged_annually ? balanceItem.annual : balanceItem.monthly;
  }

  function getInsuranceFormatted(formData, balanceItem) {
    const balanceToDisplay = getBalanceToDisplay(formData, balanceItem);
    return formatCurrency(balanceToDisplay.insurance?.amount_due);
  }

  function getPreventFormatted(formData, balanceItem) {
    const balanceToDisplay = getBalanceToDisplay(formData, balanceItem);
    return formatCurrency(balanceToDisplay.prevent?.amount_due);
  }

  function getTotalFormatted(formData, balanceItem) {
    const balanceToDisplay = getBalanceToDisplay(formData, balanceItem);
    return formatCurrency(balanceToDisplay.total_due);
  }

  function getNextChargeDateFormatted(balanceItem) {
    return balanceItem.next_charge_date
      ? parseAndFormatDate(balanceItem.next_charge_date)
      : '';
  }

  const classes = customerDialogStyle();
  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth={'md'}>
      <DialogTitle id='update-dialog'>
        Looks like you’ve made an update to this customer. Confirm the following
        changes to this customer and their pet(s):
      </DialogTitle>
      <DialogContent>
        <div className={classes.customerInfo}>
          <div className={classes.element}>
            <Typography className={classes.title}>Customer Info</Typography>
            <Typography
              className={classes.subTitle}
            >{`${formData.first_name} ${formData.last_name}`}</Typography>
            <Typography className={classes.fontStyle}>{formData.email}</Typography>
            <Typography className={classes.fontStyle}>{formData.phone}</Typography>
          </div>
          <div className={classes.element}>
            <Typography className={classes.title}>Billing Frequency</Typography>
            <Typography className={classes.subTitle}>
              {formData.is_charged_annually ? 'Annual' : 'Monthly'}
            </Typography>
          </div>
        </div>
        <Typography className={classes.petUpdatesLabel}>Pet Updates:</Typography>
        <div className={classes.petUpdatesValue}>
          {remainingBalanceByPets.map((item, i) => {
            return (
              <div key={i} className={classes.balanceItem}>
                <div className={classes.balanceItemContent}>
                  <div
                    className={classes.specieGender}
                  >{`${item.species} (${item?.gender})`}</div>
                  <div className={classes.petName}>{item.name}</div>
                  <div className={classes.label}>{item?.breed_name}</div>
                  <div className={classes.label}>{`Age ${item?.age}`}</div>
                  <div
                    className={classes.nextChargeDate}
                  >{`Next Charge Date: ${getNextChargeDateFormatted(item)}`}</div>
                  <div className={classes.financialSummary}>
                    <div className={classes.financialSummaryLabel}>
                      <div>Insurance</div>
                      <div>Preventive Essentials</div>
                    </div>
                    <div className={classes.financialSummaryValue}>
                      <div>{getInsuranceFormatted(formData, item)}</div>
                      <div>{getPreventFormatted(formData, item)}</div>
                    </div>
                  </div>
                  <div className={classes.financialSummary}>
                    <div className={classes.financialSummaryLabel}>
                      <div>Yearly Total:</div>
                    </div>
                    <div className={classes.financialSummaryValue}>
                      <div>{getTotalFormatted(formData, item)}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button label='Cancel' onClick={handleDialogClose} color='primary' />
        <Button
          label='Update'
          onClick={() => confirmUpdate(formData)}
          variant='contained'
          style={{
            color: 'white',
            backgroundColor: '#3F51B5',
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
