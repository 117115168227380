import { Fragment } from 'react';
import { Confirm } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { calculateTotalPerInvoice } from '@pumpkincare/claims';

import { usePet } from '../../../lib/users';
import ClaimBulkEditDeleteSection from '../claim-bulk-edit-delete-section';
import DeleteClaimAttachmentButton from '../ClaimShow/view/DeleteClaimAttachmentButton';
import {
  Alerts,
  AutomationStatus,
  createClaimLineItemAutomationColumns,
  toggleButtonStyle,
} from './claim-line-item-automation-utils';

function ClaimLineItemAutomation({
  onEditInvoiceClick,
  expandAll,
  collapseAll,
  invoiceData,
  expandedList = [],
  handleExpandAccordion,
  handleAddLineItem,
  commitCellChanges,
  checkedRows,
  setCheckedRows,
  isConfirmDeletionOpen,
  isDeleting,
  handleDeleteLineItem,
  toggleConfirmDeletionOpen,
  renderMoneyInput,
  onLineItemDeleteClick,
  onAddInvoiceClick,
  onDownloadInvoiceClick,
  resetBulkEditSelection,
  handleBulkEditClick,
  setBulkChanges,
  bulkChanges,
  onBulkDeleteClick,
  apiRefs,
  handleCellClick,
  currentOpsUserData,
  claimRecord,
  handleCellEditStart,
  handleSendToOcr,
  progressInvoiceStatus,
}) {
  const {
    fer2939AutomationConfidenceColors,
    fer4255ShowLineItemAutomationStatus,
    fer4411LineItemAutomationForAdminClaims,
    fer4411SettingsForSendingClaimInvoiceForOcr,
  } = useFlags();

  const { data: petData } = usePet(claimRecord?.pet_id);
  const toggleButtonClasses = toggleButtonStyle();

  const lineItemColumns = createClaimLineItemAutomationColumns(
    fer2939AutomationConfidenceColors,
    toggleButtonClasses,
    renderMoneyInput,
    onLineItemDeleteClick
  );

  function renderAutomationAlerts(invoice) {
    if (fer4255ShowLineItemAutomationStatus) {
      return invoice?.sent_to_ai ? <AutomationStatus invoice={invoice} /> : null;
    }
    return invoice?.alerts?.length ? <Alerts data={invoice?.alerts} /> : null;
  }

  function renderCircularProgressStatus(status) {
    const byOCR =
      fer4411SettingsForSendingClaimInvoiceForOcr?.send_to_orc?.show_progress &&
      progressInvoiceStatus.by_ocr &&
      status === 'new';

    const byObserve =
      fer4411SettingsForSendingClaimInvoiceForOcr?.observer?.show_progress &&
      progressInvoiceStatus.by_observe &&
      (status === 'processing' || status === 'ready');

    if (byOCR || byObserve) {
      return <CircularProgress size={10} />;
    }

    return null;
  }

  const hasMultipleSelections =
    Object.values(checkedRows).reduce(
      (acc, invoice) => acc + (invoice.ids?.length || 0),
      0
    ) > 1;

  return (
    <div style={{ marginBottom: '40px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              color: '#5368F5',
              marginRight: '16px',
            }}
          >
            Invoices
          </Typography>
          <Button
            variant='contained'
            color='inherit'
            endIcon={<AddIcon />}
            onClick={onAddInvoiceClick}
            sx={{
              backgroundColor: '#FEC600',
              '&:hover': {
                backgroundColor: '#FFF9CC',
              },
              height: '30px',
              lineHeight: '22px',
              lineSpacing: '0.46px',
              fontSize: '13px',
              padding: '4px 10px',
            }}
          >
            NEW INVOICE
          </Button>
        </div>
        <div>
          <ButtonGroup
            color='primary'
            orientation='horizontal'
            variant='text'
            sx={{ '& .MuiButtonGroup-firstButton': { borderColor: '#5368F5' } }}
          >
            <Button onClick={() => expandAll()} sx={{ color: '#5368F5' }}>
              EXPAND ALL
            </Button>

            <Button onClick={() => collapseAll()} sx={{ color: '#5368F5' }}>
              COLLAPSE ALL
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {hasMultipleSelections ? (
        <ClaimBulkEditDeleteSection
          onBulkEditCancelClick={resetBulkEditSelection}
          updateBulkChanges={setBulkChanges}
          onBulkEditUpdateClick={handleBulkEditClick}
          onBulkDeleteClick={onBulkDeleteClick}
          bulkChanges={bulkChanges}
        />
      ) : null}
      {invoiceData?.invoices?.length
        ? invoiceData.invoices.map((invoice, index) => {
            return (
              <Fragment key={invoice.id}>
                <Accordion
                  key={`${invoice.id}-${index}`}
                  expanded={expandedList.some(id => id === invoice.id)}
                  onChange={(event, isExpanded) =>
                    handleExpandAccordion(isExpanded, invoice.id)
                  }
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#F8F7F3',
                    '&:before': {
                      height: '0px',
                      borderBottom: '1px',
                    },
                    '&.Mui-expanded': {
                      margin: '0px',
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      flexDirection: 'row-reverse',
                      height: '40px',
                      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                      '&.Mui-expanded': {
                        border: '0px',
                      },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        marginLeft: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <Typography sx={{ color: '#373F51' }} variant='body1'>
                          {invoice.source_file_name}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            color: '#373F51',
                            padding: '0 10px',
                          }}
                          variant='overline'
                        >{`${invoice.line_items.length} ITEM${
                          invoice.line_items.length === 1 ? '' : 'S'
                        } (${calculateTotalPerInvoice(
                          invoice.line_items
                        )})`}</Typography>

                        {fer4411LineItemAutomationForAdminClaims ? (
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              handleSendToOcr(invoice.id);
                            }}
                            disabled={invoice.status !== 'new'}
                          >
                            {renderCircularProgressStatus(invoice.status)}
                            <Typography
                              sx={{
                                color:
                                  invoice.status === 'new' ? '#5368F5' : '#BDBDBD',
                                fontSize: '13px',
                                lineHeight: '22px',
                                fontWeight: '500',
                                letterSpacing: '0.46px',
                                padding: '4px 10px',
                              }}
                            >
                              SEND TO OCR
                            </Typography>
                          </Button>
                        ) : null}

                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            handleAddLineItem(invoice.id, invoice.line_items);
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#5368F5',
                              fontSize: '13px',
                              lineHeight: '22px',
                              fontWeight: '500',
                              letterSpacing: '0.46px',
                              padding: '4px 10px',
                            }}
                          >
                            ADD ITEM
                          </Typography>
                        </Button>
                        <IconButton
                          style={{ padding: '6px' }}
                          onClick={e => onDownloadInvoiceClick(invoice, e)}
                        >
                          <GetAppIcon
                            style={{ width: '20px' }}
                            color='rgba(0, 0, 0, 0.56)'
                          />
                        </IconButton>
                        <IconButton
                          style={{ padding: '6px' }}
                          onClick={e => {
                            e.stopPropagation();
                            onEditInvoiceClick({
                              ...invoice,
                              id: invoice.document_id,
                              type: 'claim_invoice',
                            });
                          }}
                        >
                          <CreateIcon
                            color='rgba(0, 0, 0, 0.56)'
                            style={{ width: '20px' }}
                          />
                        </IconButton>
                        <DeleteClaimAttachmentButton
                          invoiceRecord={{ ...invoice, id: invoice.document_id }}
                          currentOpsUserData={currentOpsUserData}
                          claimRecord={claimRecord}
                          color={'rgba(0, 0, 0, 0.56)'}
                        />
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ maxHeight: '610px', overflowY: 'auto' }}>
                    {renderAutomationAlerts(invoice)}

                    <DataGrid
                      apiRef={
                        apiRefs?.find(ref => ref.invoiceId === invoice.id).apiRef
                      }
                      rows={invoice.line_items.map(lineItem => ({
                        ...lineItem,
                        canFileIns: Boolean(petData?.latest_pet_policy),
                        canFilePep: Boolean(petData?.latest_pet_plan),
                        canFileWell: Boolean(petData?.wellness),
                        line_item_type:
                          petData?.wellness && !petData?.latest_pet_policy
                            ? 'wellness'
                            : lineItem.line_item_type,
                      }))}
                      sortingMode='server'
                      disableColumnMenu
                      sx={{
                        '& .MuiDataGrid-columnHeader': {
                          backgroundColor: '#6B717E',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                          outline: '0px',
                        },

                        '& .MuiDataGrid-columnHeaderTitle': {
                          color: 'white',
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '22px',
                          letterSpacing: '0.1px',
                        },
                        '& .MuiDataGrid-columnSeparator': {
                          display: 'none',
                        },
                        '& .MuiDataGrid-row': {
                          backgroundColor: 'white',
                        },
                        '& .MuiDataGrid-row:hover': {
                          backgroundColor: '#5368F50A',
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                          backgroundColor: '#5368F514',
                        },
                        '& .MuiDataGrid-columnHeaderTitleContainerContent > .MuiCheckbox-root':
                          { color: 'white' },
                        '& .MuiDataGrid-cellCheckbox > .MuiButtonBase-root.Mui-checked':
                          { color: '#5368F5' },
                        '& .filledRed': {
                          backgroundColor: '#EF5350',
                        },
                        '& .filledYellow': {
                          backgroundColor: '#FF9800',
                        },
                      }}
                      ColumnSortedAscendingIcon={null}
                      checkboxSelection={true}
                      onRowSelectionModelChange={checkedIds => {
                        setCheckedRows(prev => ({
                          ...prev,
                          [invoice.id]: { ids: checkedIds },
                        }));
                      }}
                      rowSelectionModel={checkedRows[invoice.id]?.ids || []}
                      disableColumnFilter
                      disableColumnSelector
                      disableRowSelectionOnClick
                      disableDensitySelector
                      processRowUpdate={(updatedRow, originalRow) =>
                        commitCellChanges(originalRow, updatedRow)
                      }
                      columns={lineItemColumns}
                      autoHeight={true}
                      hideFooter={true}
                      onCellClick={handleCellClick}
                      onCellEditStart={handleCellEditStart}
                    />
                  </AccordionDetails>
                </Accordion>
                <Confirm
                  isOpen={isConfirmDeletionOpen}
                  title='Delete line item'
                  content='Are you sure you want to delete this line item?'
                  loading={isDeleting}
                  onConfirm={handleDeleteLineItem}
                  onClose={toggleConfirmDeletionOpen}
                />
              </Fragment>
            );
          })
        : null}
    </div>
  );
}

ClaimLineItemAutomation.propTypes = {
  onEditInvoiceClick: PropTypes.func,
  expandAll: PropTypes.func,
  collapseAll: PropTypes.func,
  invoiceData: PropTypes.object,
  expandedList: PropTypes.array,
  handleExpandAccordion: PropTypes.func,
  handleAddLineItem: PropTypes.func,
  commitCellChanges: PropTypes.func,
  checkedRows: PropTypes.object,
  setCheckedRows: PropTypes.func,
  isConfirmDeletionOpen: PropTypes.bool,
  isDeleting: PropTypes.bool,
  handleDeleteLineItem: PropTypes.func,
  toggleConfirmDeletionOpen: PropTypes.func,
  renderMoneyInput: PropTypes.func,
  onLineItemDeleteClick: PropTypes.func,
  onAddInvoiceClick: PropTypes.func,
  onDownloadInvoiceClick: PropTypes.func,
  resetBulkEditSelection: PropTypes.func,
  handleBulkEditClick: PropTypes.func,
  setBulkChanges: PropTypes.func,
  bulkChanges: PropTypes.object,
  onBulkDeleteClick: PropTypes.func,
  apiRefs: PropTypes.any,
  handleCellClick: PropTypes.func,
  currentOpsUserData: PropTypes.any,
  claimRecord: PropTypes.any,
  handleCellEditStart: PropTypes.func,
  handleSendToOcr: PropTypes.func,
  progressInvoiceStatus: PropTypes.bool,
};

export default ClaimLineItemAutomation;
