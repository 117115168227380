import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { formatCurrency } from '../../../../shared/utils';
import { calcQuoteTotalPriceWithoutMonthlyFee } from '../utils/quoteUtils';
import PetAccordion from './PetAccordion';

function PlanDetailModal(props) {
  const { quote, showDialog, setShowDialog } = props;

  const isAnnualBilling = quote?.converted_to_annual_policy === true;

  const convertedDiv = quote?.converted ? (
    <div style={{ borderRadius: '16px', border: '1px solid rgba(2, 142, 16, 0.8)' }}>
      <Typography style={{ color: 'rgba(2, 142, 16, 0.8)', padding: '6px' }}>
        CONVERTED
      </Typography>
    </div>
  ) : (
    <div style={{ borderRadius: '16px', border: '1px solid #B53F3F' }}>
      <Typography style={{ color: '#B53F3F', padding: '6px' }}>
        NOT CONVERTED
      </Typography>
    </div>
  );

  const quoteDetailDiv =
    quote && quote.converted ? (
      <>
        <Typography>Billing Frequency:</Typography>
        <Typography style={{ textAlign: 'right' }}>
          {isAnnualBilling ? 'Annual' : 'Monthly'}
        </Typography>
      </>
    ) : quote?.non_conversion_reason ? (
      <>
        <Typography>REASON:</Typography>
        <Typography>{quote.non_conversion_reason}</Typography>
      </>
    ) : null;

  const createdOn = quote ? moment(quote.created_at).format('MMMM DD, YYYY') : null;

  const quoteTotalPrice = calcQuoteTotalPriceWithoutMonthlyFee(quote);

  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      aria-label='Show Customer Quote'
    >
      <div
        style={{
          width: '500px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '24px 24px 0px 24px',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '600',
            color: 'black',
          }}
        >
          QUOTE SUMMARY
        </Typography>
        <IconButton
          style={{
            color: 'black',
          }}
          onClick={() => setShowDialog(false)}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>

      {quote ? (
        <>
          <Typography
            style={{ paddingLeft: '24px' }}
          >{`Created on ${createdOn}`}</Typography>
          <Typography style={{ paddingLeft: '24px' }}>
            {`By ${quote.created_by ? `AGENT ${quote.created_by}` : 'Owner'}`}
          </Typography>
          <Divider style={{ margin: '12px 0px' }} />
          <DialogContent style={{ display: 'inline-grid' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Owner</Typography>
              {convertedDiv}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography>
                  {quote.first_name && quote.last_name
                    ? `${quote.first_name} ${quote.last_name}`
                    : null}
                </Typography>
                <Typography>{quote.email}</Typography>
                <Typography>
                  {quote.policy_state && quote.policy_zipcode
                    ? `${quote.policy_state}, ${quote.policy_zipcode}`
                    : null}
                </Typography>
              </div>
              <div>{quoteDetailDiv}</div>
            </div>

            <Typography style={{ padding: '12px 0px' }}>Notes:</Typography>
            <Typography>{quote.comment}</Typography>
            <Divider style={{ margin: '12px 0px' }} />
            <Typography
              style={{ paddingBottom: '8px' }}
            >{`Pets (${quote.quote_pets.length})`}</Typography>
            {quote.quote_pets.map(quotePet => {
              return (
                <PetAccordion
                  key={quotePet.id}
                  quotePet={quotePet}
                  isAnnualBilling={isAnnualBilling}
                />
              );
            })}
            <Divider style={{ margin: '12px 0px' }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '0px',
              }}
            >
              <Typography
                style={{
                  fontSize: '12px',
                }}
              >
                Monthly Transaction Fee
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                }}
              >
                {quote.transaction_fee
                  ? formatCurrency(isAnnualBilling ? 0 : quote.transaction_fee)
                  : '$0.0'}
              </Typography>
            </div>
            <Divider style={{ margin: '12px 0px' }} />
            <div style={{ textAlign: 'right' }}>
              <Typography style={{ fontSize: '12px' }}>{`Monthly Rate`}</Typography>
              <Typography
                style={{
                  fontSize: '24px',
                  fontWeight: '600',
                  paddingBottom: '16px',
                }}
              >
                {`${
                  isAnnualBilling
                    ? formatCurrency(quoteTotalPrice * 12)
                    : formatCurrency(quoteTotalPrice + quote.transaction_fee)
                }`}
              </Typography>
            </div>
          </DialogContent>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}

export default PlanDetailModal;
