import { useState } from 'react';
import {
  Button,
  FileField,
  FileInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  useGetList,
  useNotify,
} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  DOCUMENT_ALLOWED_TYPES,
  DOCUMENT_MAX_SIZE,
  DOCUMENT_TYPES_REQUIRE_PET_FIELD,
  MGA_DOCUMENT_TYPES_UPLOAD_CHOICES,
} from '../../../../constants/documents';
import RoutePaths from '../../../../routes';
import { addDocument, uploadMGAFile } from '../../service/customerService';
import useStyles from './document-upload-v2.styles';
function DocumentUploadV2(props) {
  const { open, toggleDialog, userId, onSuccess } = props;
  const classes = useStyles();
  const notify = useNotify();

  const [requirePetInput, setRequirePetInput] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isWaitingPeriodHealthAssessment, setWaitingPeriodHealthAssessment] =
    useState(false);

  const [waivesIllnessLeavePeriod, setWaivesIllnessLeavePeriod] = useState(false);

  const { data, isLoading: isPetLoading } = useGetList('pets', {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'created_at', order: 'ASC' },
    filter: { user_id: userId },
  });

  if (isPetLoading) return <>Loading...</>;

  const petList = data.map(pet => ({ id: pet.id, name: pet.name }));

  function handleOnDocumentTypeChange({ target: { value } }) {
    setRequirePetInput(DOCUMENT_TYPES_REQUIRE_PET_FIELD.includes(value));

    setWaitingPeriodHealthAssessment(value === 'waiting_period_health_assessment');
  }

  function handleSubmit({ formData }) {
    const errors = [];

    if (!formData.file) {
      errors.push('file is required');
    }

    if (!formData.document_type) {
      errors.push('document type is required');
    }

    if (requirePetInput && !formData.pet) {
      errors.push('pet is required');
    }

    if (isWaitingPeriodHealthAssessment && !formData.pet) {
      errors.push('pet is required');
    }

    if (errors.length > 0) {
      notify(`The form contains errors: ${errors.join(', ')}.`, 'warning');
      return;
    }

    const file = formData.file.rawFile;
    const documentType = formData.document_type;
    const pet = formData.pet;

    setLoading(true);

    uploadMGAFile(userId, file, documentType)
      .then(s3Url => {
        const document = {
          location: s3Url,
          type: documentType,
          user_id: userId,
        };

        if (pet) {
          document['pet_id'] = pet;
        }

        if (documentType === 'waiting_period_health_assessment') {
          document['waives_illness_leave_period'] = waivesIllnessLeavePeriod;
        }

        addDocument(document, RoutePaths.documentsV2)
          .then(response => {
            return onSuccess(response);
          })
          .catch(error => {
            notify(`ERROR: ${error.message}`, 'error');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        notify(
          'There was an error when uploading file - please try again later.',
          'error'
        );
        setLoading(false);
      });
  }

  function onFileRejected() {
    notify(
      'Error: Attachment rejected. Make sure it is the proper size and type.',
      'warning'
    );
  }

  return (
    <SimpleForm submitOnEnter={false} toolbar={null} onSubmit={null}>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-label='Upload document'
        fullWidth={true}
        maxWidth={'md'}
        classes={{ container: classes.container }}
      >
        <DialogTitle>
          <IconButton className={classes.close} onClick={toggleDialog} size='large'>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>New Document Upload</Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <FileInput
            validate={required()}
            source='file'
            maxSize={DOCUMENT_MAX_SIZE}
            options={{
              onDropRejected: onFileRejected,
            }}
            multiple={false}
            accept={DOCUMENT_ALLOWED_TYPES.join()}
            placeholder={'Drop file here or click to find.'}
            className={classes.input}
            classes={{ dropZone: classes.fileDropZone }}
          >
            <FileField target='_blank' source='src' title='title' />
          </FileInput>

          <SelectInput
            label='Document Type'
            source='document_type'
            choices={MGA_DOCUMENT_TYPES_UPLOAD_CHOICES}
            onChange={handleOnDocumentTypeChange}
            fullWidth={true}
            className={classes.input}
            validate={required()}
          />

          <SelectInput
            label='Pet'
            source='pet'
            choices={petList}
            fullWidth={true}
            className={classes.input}
            validate={requirePetInput ? required() : []}
            allowEmpty={requirePetInput === false}
            key={requirePetInput ? 1 : 0}
          />

          {isWaitingPeriodHealthAssessment ? (
            <FormControl component='fieldset'>
              <FormLabel component='legend' classes={{ root: classes.question }}>
                Does this form meet the criteria to waive the illness waiting period
                for the pet selected? <span>*</span>
              </FormLabel>
              <RadioGroup
                onChange={e => {
                  setWaivesIllnessLeavePeriod(e.target.value);
                }}
                value={waivesIllnessLeavePeriod}
                name='response-type'
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color='primary' />}
                  label='Yes'
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                  style={{ marginRight: '60px' }}
                />

                <FormControlLabel
                  value={false}
                  control={<Radio color='primary' />}
                  label='No'
                  classes={{ root: classes.labelRoot, label: classes.labelText }}
                />
              </RadioGroup>
            </FormControl>
          ) : null}
        </DialogContent>

        <DialogActions className={classes.actions}>
          <FormDataConsumer>
            {formDataProps => (
              <Button
                variant='contained'
                color='primary'
                label={isLoading ? 'Saving...' : 'Save'}
                onClick={() => handleSubmit(formDataProps)}
                className={classes.saveButton}
                classes={{ label: classes.saveLabel }}
                disabled={isLoading}
              />
            )}
          </FormDataConsumer>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
}

DocumentUploadV2.propTypes = {
  userId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

DocumentUploadV2.defaultProps = {
  onSuccess: document => {},
};

export default DocumentUploadV2;
