import {
  // Billing
  PERMISSION_START_REFUND,

  // Claims
  PERMISSION_CREATE_CLAIM,
  PERMISSION_CREATE_INSURANCE_CLAIM,
  PERMISSION_CREATE_PREVENT_CLAIM,
  PERMISSION_DELETE_CLAIM_ATTACHMENT,
  PERMISSION_EDIT_CLAIM_ATTACHMENT,
  PERMISSION_EDIT_CLAIM,
  PERMISSION_REIMBURSE_CLAIM,
  PERMISSION_EDIT_INSURANCE_CLAIM,
  PERMISSION_EDIT_PREVENT_CLAIM,
  PERMISSION_EDIT_PREVENT_CLAIM_LINE_ITEM,
  PERMISSION_SUBMIT_CLAIM_TO_INSURANCE_PROVIDER,
  PERMISSION_UPLOAD_CLAIM_ATTACHMENT,

  // Quotes
  PERMISSION_CREATE_QUOTE,
  PERMISSION_EDIT_QUOTE,

  // Invoices
  PERMISSION_CREATE_CUSTOMER_INVOICE,

  // Shipments
  PERMISSION_CREATE_SHIPMENT,

  // Policies
  PERMISSION_EDIT_POLICY,
} from '../permissions';

const AdminUserPoolGroupPermissions = {
  // Billing
  [PERMISSION_START_REFUND]: true,

  // Claims
  [PERMISSION_CREATE_CLAIM]: true,
  [PERMISSION_CREATE_INSURANCE_CLAIM]: true,
  [PERMISSION_CREATE_PREVENT_CLAIM]: true,
  [PERMISSION_DELETE_CLAIM_ATTACHMENT]: true,
  [PERMISSION_EDIT_CLAIM_ATTACHMENT]: true,
  [PERMISSION_EDIT_CLAIM]: true,
  [PERMISSION_EDIT_INSURANCE_CLAIM]: true,
  [PERMISSION_EDIT_PREVENT_CLAIM]: true,
  [PERMISSION_EDIT_PREVENT_CLAIM_LINE_ITEM]: true,
  [PERMISSION_SUBMIT_CLAIM_TO_INSURANCE_PROVIDER]: true,
  [PERMISSION_UPLOAD_CLAIM_ATTACHMENT]: true,
  [PERMISSION_REIMBURSE_CLAIM]: true,

  // Invoices
  [PERMISSION_CREATE_CUSTOMER_INVOICE]: true,

  // Shipments
  [PERMISSION_CREATE_SHIPMENT]: true,

  // Quotes
  [PERMISSION_CREATE_QUOTE]: true,
  [PERMISSION_EDIT_QUOTE]: true,

  // Policies
  [PERMISSION_EDIT_POLICY]: true,
};

export default AdminUserPoolGroupPermissions;
