import React from 'react';
import { Divider as CoreDivider } from '@mui/material';

// because basePath and other possible props are causing React warnings
function Divider({ absolute, component, light, orientation, variant }) {
  return (
    <CoreDivider
      absolute={absolute}
      component={component}
      light={light}
      orientation={orientation}
      variant={variant}
    />
  );
}

export default Divider;

// for more info go to https://v4-0-2.material-ui.com/api/divider/
Divider.defaultProps = {
  absolute: false,
  component: 'hr',
  light: false,
  orientation: 'horizontal',
  variant: 'fullWidth',
};
