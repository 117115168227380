import { DateInput, TextInput, useListContext } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button } from '@mui/material';

export default function AuditLogsFilter(props) {
  const { filterValues, setFilters } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  function onSubmit(values) {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      setFilters({}, []);
    }
  }

  function onClearAll() {
    form.reset();
    setFilters({}, []);
  }

  return (
    <div style={{ marginTop: '8px' }}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box
            display='flex'
            mb={1}
            style={{ display: 'inline-block', maxWidth: '50%' }}
          >
            <Box component='span' mr={1}>
              <DateInput
                style={{ width: '231px' }}
                helperText={false}
                source='created_at'
                label='Date'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Context' source='context' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                helperText={false}
                label='Description'
                source='description'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                helperText={false}
                label='Modified by'
                source='modified_by'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='User ID' source='user_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Pet ID' source='pet_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Policy ID' source='policy_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                helperText={false}
                label='Pet Plan ID'
                source='pet_plan_id'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput
                helperText={false}
                label='Wellness ID'
                source='wellness_id'
              />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Claim ID' source='claim_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Invoice ID' source='invoice_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Quote ID' source='quote_id' />
            </Box>
            <Box component='span' mr={1}>
              <TextInput helperText={false} label='Function' source='function' />
            </Box>

            <Box component='span' mr={2}>
              <Button
                variant='outlined'
                color='primary'
                type='submit'
                style={{ borderRadius: '4px', width: '120px', marginTop: '15px' }}
              >
                Filter
              </Button>
            </Box>
            <Box component='span' mr={2}>
              <Button
                variant='outlined'
                color='primary'
                type='button'
                onClick={() => onClearAll()}
                style={{ borderRadius: '4px', width: '120px', marginTop: '15px' }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </div>
  );
}
