import makeStyles from '@mui/styles/makeStyles';

const renewalChangeConfirmationModalStyle = makeStyles({
  modalTitle: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    color: '#2196F3',
  },

  modalSubTitle: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#000000',
  },

  modalContentDetail: {
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
    padding: '16px',
  },

  petTitle: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#007DFF',
  },

  changesMadeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  changesMade: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.54);',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    paddingLeft: '0px',
  },

  changesMadeIcon: {
    marginLeft: '4px',
    marginRight: '4px',
  },

  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  saveButton: {
    color: 'white',
    backgroundColor: '#007DFF',
    '&:hover': {
      backgroundColor: '#007DFF',
    },
    maxWidth: '94%',
    margin: '16px 0px',
  },
  cancelButton: {
    color: 'white',
    backgroundColor: '#D4424E',
    '&:hover': {
      backgroundColor: '#D4424E',
    },
    maxWidth: '94%',
  },
},
{
  name: 'renewal-change-confirmation-modal',
});

export { renewalChangeConfirmationModalStyle };
