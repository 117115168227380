import { CREATE, GET_LIST, GET_ONE } from 'react-admin';

import { transformClaimsSummaries } from '@pumpkincare/claims';

import { dataProvider } from '../../../lib/dataProvider/provider';
import RoutePaths from '../../../routes';
import { parseAndFormatDate } from '../../../shared/utils';

function createSignedUrl(
  userId,
  filename,
  documentType,
  route = RoutePaths.documentsV2,
  typeKey = 'document_type'
) {
  return dataProvider(CREATE, `${route}/signed-url`, {
    data: {
      user_id: userId,
      file_name: filename,
      [typeKey]: documentType,
    },
    returnJson: 'RAW',
  }).then(response => response.data);
}

function uploadFileToS3(file, signedUrls) {
  const formData = new FormData();
  const signedUrlParams = signedUrls[file.name];

  file.key = signedUrlParams.fields.key;

  Object.keys(signedUrlParams.fields).forEach(key => {
    formData.append(key, signedUrlParams.fields[key]);
  });

  // Actual file has to be appended last.
  formData.append('file', file);

  return fetch(signedUrlParams.url, {
    method: 'POST',
    body: formData,
  }).then(res => {
    const bucket = new URL(signedUrlParams.url).hostname.split('.')[0];
    return `s3://${bucket}/${file.key}`;
  });
}

export function uploadMGAFile(userId, file, documentType) {
  return createSignedUrl(
    userId,
    file.name,
    documentType,
    RoutePaths.documentsV2,
    'type'
  ).then(signedUrls => {
    return uploadFileToS3(file, signedUrls);
  });
}

export function addDocument(data, route = RoutePaths.documentsV2) {
  return dataProvider(CREATE, route, {
    data: data,
    returnJson: 'DATA',
  }).then(response => response.data);
}

export function getPets(userId) {
  return dataProvider(GET_LIST, RoutePaths.pets, {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'created_at', order: 'asc' },
    filter: { user_id: userId },
  }).then(response => {
    return response.data.map(pet => {
      return {
        id: pet.id,
        name: pet.name,
        policies: pet.policies.map(policy => {
          const startDate = parseAndFormatDate(policy.policy_effective_date);
          const endDate = parseAndFormatDate(policy.policy_end_date);
          return {
            id: policy.id,
            status: policy.status,
            dateRange: `${startDate} - ${endDate}`,
          };
        }),
      };
    });
  });
}

export function getClaimsSummaries(userId) {
  return dataProvider(GET_ONE, RoutePaths.customers, {
    id: `${userId}/claims/summaries-v2`,
  }).then(response => transformClaimsSummaries(response.data));
}

export function getRemainingBalance(userId) {
  return dataProvider('GET', `users/${userId}/remaining-balance`, {});
}
