import { Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../shared/utils';
import { getFullPercentage } from '../../../shared/utils/petUtils';
import { policyChangeRadioButtonStyle } from './policy-change-radio-button-label-style';

function PolicyChangeRadioButtonLabel(props) {
  const classes = policyChangeRadioButtonStyle();
  const { fer4782CoinsuranceRatePicker } = useFlags();

  const {
    raw_rate = 0,
    annual_limit = '0',
    current = false,
    deductible = 0,
    capped_rate = null,
    coinsurance = 0,
  } = props;

  const annualLimit = formatCurrency(parseInt(annual_limit) / 1000, {
    areCents: false,
    hideZero: true,
    ifNaN: 'Unlimited',
  });

  const deductibleFormatted = formatCurrency(deductible, {
    areCents: false,
    hideZero: true,
  });

  const reimbursementRate = getFullPercentage(coinsurance);

  return (
    <div className={classes.radioButtonLabelContainer}>
      <div className={classes.rawRateContainer}>
        <Typography className={classes.rawRate}>
          {formatCurrency(raw_rate, { areCents: false })}
        </Typography>

        {capped_rate ? (
          <span
            className={classes.cappedRate}
            title={`Capped Rate: ${formatCurrency(capped_rate)}`}
            data-testid='capped-rate-asterisk'
          >
            *
          </span>
        ) : null}

        {current ? (
          <span className={classes.currentPolicyChip}>Current Policy</span>
        ) : null}
      </div>

      {fer4782CoinsuranceRatePicker ? (
        <Typography className={classes.deductible}>
          {reimbursementRate} Reimbursement Rate
        </Typography>
      ) : null}

      <Typography className={classes.deductible}>
        {deductibleFormatted} Deductible
      </Typography>

      <Typography className={classes.deductible}>
        {`${
          annualLimit === 'Unlimited' ? annualLimit : annualLimit + 'K'
        } Annual Limit`}
      </Typography>
    </div>
  );
}

PolicyChangeRadioButtonLabel.propTypes = {
  raw_rate: PropTypes.number,
  annual_limit: PropTypes.string,
  current: PropTypes.bool,
  deductible: PropTypes.number,
  capped_rate: PropTypes.number,
  coinsurance: PropTypes.number,
};

export default PolicyChangeRadioButtonLabel;
