import { useState } from 'react';
import {
  ArrayField,
  Create,
  Datagrid,
  FunctionField,
  maxLength,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Link as LinkRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MoneyField } from '../../components/MoneyField';
import { invoiceNotesMaxLength } from '../../constants/invoiceNotes';
import RoutePaths from '../../routes';
import { CancellationRefund } from '../../shared/components';
import Tile from '../../shared/components/Tile';
import InvoiceItemsForm from './InvoiceItemsForm';

function InvoiceCreate() {
  const notify = useNotify();
  const redirect = useRedirect();
  const { pk2174ShowPreventRefundInvoicePage } = useFlags();
  const location = useLocation();

  const customer = location.state.record.customer;
  const showCancellationLink = location.state.showCancellationLink;

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceTotal, setInvoiceTotal] = useState(0);

  function calculateInvoiceTotal(items) {
    return items.reduce(
      (invoiceTotal, invoiceItem) => invoiceTotal + invoiceItem.price_per_unit,
      0
    );
  }

  function updateInvoice(items) {
    setInvoiceItems(items);
    setInvoiceTotal(calculateInvoiceTotal(items));
  }

  function handleOnAddItems(itemsToAdd) {
    updateInvoice([...invoiceItems, ...itemsToAdd]);
  }

  function handleOnDeleteItem(itemToDelete) {
    const itemsAfterDelete = invoiceItems.filter(
      item => item.id !== itemToDelete.id
    );
    updateInvoice(itemsAfterDelete);
  }

  function onError() {
    notify(`Page error. Please check if the invoice was created.`, {
      type: 'error',
    });
    redirect(`/${RoutePaths.customers}/${customer.id}/show/billing`);
  }

  function CreateInvoiceToolbar(props) {
    return (
      <Toolbar {...props}>
        <SaveButton label='Create Invoice' alwaysEnable={true} />
        {showCancellationLink ? (
          <Typography style={{ marginLeft: 'auto' }}>
            <LinkRouter
              to={{
                pathname: `/${RoutePaths.cancellationDetails}/${customer.id}/show`,
              }}
              className={{ cursor: 'pointer' }}
              style={{
                textDecoration: 'none',
                paddingRight: '16px',
                fontSize: '1rem',
              }}
            >
              Return to cancellation screen
            </LinkRouter>
          </Typography>
        ) : null}
      </Toolbar>
    );
  }

  function DeleteInvoiceItemButton({ record }) {
    return (
      <Link
        style={{ cursor: 'pointer' }}
        color='primary'
        variant='inherit'
        onClick={() => handleOnDeleteItem(record)}
      >
        Delete
      </Link>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '60%' }}>
        <Create
          redirect={(resource, id, data) => `${resource}/${id}/show`}
          transform={data => ({
            ...data,
            invoice: {
              customer: customer,
              invoice_items: invoiceItems,
              notes: data.notes,
            },
          })}
          mutationOptions={{ onError }}
        >
          <SimpleForm toolbar={<CreateInvoiceToolbar />} onSubmit={null}>
            <Tile
              title={'Customer First Name'}
              content={customer.first_name}
              style={{ width: '472px' }}
            />

            <Tile
              title={'Customer Last Name'}
              content={customer.last_name}
              style={{ width: '472px', marginTop: '16px' }}
            />

            <Tile
              title={'Customer Email'}
              content={customer.email}
              style={{ width: '472px', marginTop: '16px', marginBottom: '24px' }}
            />

            <ArrayField
              label='Items'
              source='invoice_items'
              record={{ invoice_items: invoiceItems }}
            >
              <Datagrid bulkActionButtons={false}>
                <TextField label='Type' source='type' />
                <TextField label='Description' source='description' />
                <TextField label='Effective Date' source='effective_date' />
                <MoneyField source='price_per_unit' />
                <FunctionField
                  render={(record = {}) => (
                    <DeleteInvoiceItemButton record={record} />
                  )}
                ></FunctionField>
              </Datagrid>
            </ArrayField>

            {!invoiceItems.length ? (
              <TextField label='The invoice should have at least one item.' />
            ) : (
              <MoneyField
                label='Total'
                source='invoice_total'
                record={{ invoice_total: invoiceTotal }}
              />
            )}

            <TextInput
              multiline
              label='Internal Notes'
              resettable
              source='notes'
              validate={[maxLength(invoiceNotesMaxLength)]}
              fullWidth
            />
          </SimpleForm>
        </Create>
        {pk2174ShowPreventRefundInvoicePage && showCancellationLink ? (
          <CancellationRefund userId={customer.id} />
        ) : null}
      </div>

      <div style={{ width: '40%' }}>
        <InvoiceItemsForm onAddItems={handleOnAddItems} customerId={customer?.id} />
      </div>
    </div>
  );
}

export default InvoiceCreate;
