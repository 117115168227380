import { Datagrid, FunctionField, ReferenceManyField, TextField } from 'react-admin';
import CreateIcon from '@mui/icons-material/Create';
import GetAppIcon from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';
import { formatClaimAttachmentType } from '@pumpkincare/claims';

import { MGA_DOCUMENT_TYPES } from '../../../constants/claimAttachmentDocumentTypes';
import { getDateAsFormattedUTC } from '../../../shared/utils';
import DeleteClaimAttachmentButton from '../ClaimShow/view/DeleteClaimAttachmentButton';
import { claimAttachmentTableStyle } from './claim-attachment-table-style';

function ClaimAttachmentTable(props) {
  const classes = claimAttachmentTableStyle();

  const {
    onEditClick,
    showDownloadButton,
    showEditButton,
    showDeleteButton,
    currentOpsUserData,
    ...rest
  } = props;

  function handleDownloadAttachment(record) {
    if (
      record.type &&
      MGA_DOCUMENT_TYPES[record.type] === MGA_DOCUMENT_TYPES.claim_invoice
    ) {
      analyticsTrack({
        event: 'clicked_download_invoice_claims',
        label: 'claims invoice download',
        category: 'claims admin actions',
        claim_id: record?.claim_id,
        ops_user_id: currentOpsUserData?.id,
        file_name: record?.source_file_name,
      });
    }

    window.open(record.document_signed_url);
  }

  return (
    <ReferenceManyField
      {...rest}
      reference={`claims/${props.claimId}/documents`}
      target='claim_id'
      label=''
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        classes={{
          headerCell: classes.headerCell,
          table: classes.table,
          rowCell: classes.rowCell,
        }}
      >
        <FunctionField
          label='DATE'
          source='created_at'
          render={(record = {}) =>
            getDateAsFormattedUTC(record.created_at, 'MM/DD/YYYY')
          }
          className={classes.fontStyle}
        />

        <TextField
          label='FILE NAME'
          source='source_file_name'
          className={classes.fontStyle}
        />

        <FunctionField
          label='FILE TYPE'
          source='type'
          render={(record = {}) => formatClaimAttachmentType(record.type)}
          className={classes.fontStyle}
        />

        <FunctionField
          label=''
          render={(record = {}) =>
            showDownloadButton ? (
              <IconButton
                style={{ padding: '0px' }}
                onClick={() => handleDownloadAttachment(record)}
                size='large'
              >
                <GetAppIcon color='primary' />
              </IconButton>
            ) : null
          }
          className={classes.fontStyle}
        />

        {showEditButton ? (
          <FunctionField
            label=''
            render={(record = {}) => (
              <IconButton
                style={{ padding: '0px' }}
                onClick={() => onEditClick(record)}
                size='large'
              >
                <CreateIcon style={{ color: '#3EC28F' }} />
              </IconButton>
            )}
            className={classes.fontStyle}
          />
        ) : null}

        {showDeleteButton ? (
          <DeleteClaimAttachmentButton currentOpsUserData={currentOpsUserData} />
        ) : null}
      </Datagrid>
    </ReferenceManyField>
  );
}

ClaimAttachmentTable.defaultProps = {
  showDownloadButton: true,
  showEditButton: false,
  showDeleteButton: false,
  onEditClick: () => {},
};

ClaimAttachmentTable.propTypes = {
  onEditClick: PropTypes.func,
  showDownloadButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  currentOpsUserData: PropTypes.shape({
    id: PropTypes.string,
  }),
  claimId: PropTypes.string,
};

export default ClaimAttachmentTable;
