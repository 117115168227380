import React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid';

import { useAutocompleteSelectInputStyles } from './autocomplete-select-input.styles';

export function AutocompleteSelectInput(props) {
  const {
    id,
    value,
    api,
    field,
    options,
    optionID = '',
    optionGrouper = '',
    optionLabel = 'name',
    isLoading = false,
    filterOptionsLimit = 20,
    fullWidth = false,
  } = props;
  const classes = useAutocompleteSelectInputStyles();
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  const dataGridApi = useGridApiContext();
  const [inputValue, setInputValue] = React.useState(value?.name || value);

  const handleChange = (event, newOption) => {
    dataGridApi.current.setEditCellValue({ id, field, value: { ...newOption } });
    dataGridApi.current.stopCellEditMode({ id: id, field: field });
  };

  function handleInputChange(e, newInputValue) {
    setInputValue(newInputValue);
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: filterOptionsLimit,
  });

  function renderInput(params) {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
        autoFocus={true}
        label={params?.label}
      />
    );
  }

  function groupBy(option) {
    return String(option[optionGrouper]);
  }

  function getOptionSelected(option, value) {
    return option[optionID] === value[optionID];
  }

  function getOptionLabel(option) {
    return option[optionLabel] || option;
  }

  return (
    <Autocomplete
      fullWidth={fullWidth}
      disableClearable
      id='asynchronous-select-input-component'
      loading={loading || isLoading}
      options={options}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={filterOptions}
      isOptionEqualToValue={optionID ? getOptionSelected : undefined}
      groupBy={optionGrouper ? groupBy : undefined}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      className={classes.wrapper}
      renderInput={renderInput}
    />
  );
}
