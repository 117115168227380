import * as React from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import { OrganizationsConfirmModalStyles } from './organizations-confirm-modal.styles';

export function OrganizationsConfirmModal({
  isOpen,
  loading,
  handleOnClose,
  handleOnConfirm,
  formData,
}) {
  const classes = OrganizationsConfirmModalStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      aria-labelledby='alert-dialog-title'
      maxWidth='xs'
      scroll='body'
      className={classes.dialogParent}
    >
      <DialogTitle id='alert-dialog-title'>Review Discount Details</DialogTitle>
      <DialogContent>
        <>
          <Grid container spacing={4} fullWidth>
            {Boolean(formData?.org_type) ? (
              <Grid item xs={12} className={classes.pb0}>
                <Typography component='small' className={classes.label}>
                  DISCOUNT TYPE
                </Typography>
                <Typography
                  variant='body1'
                  component='p'
                  className={classes.capitalize}
                >
                  {formData.org_type}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.name) ? (
              <Grid item xs={12} md={5} className={classes.pb0}>
                <Typography component='small' className={classes.label}>
                  ORGANIZATION NAME
                </Typography>

                <Typography variant='body1' component='p'>
                  {formData.name}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.website) ? (
              <Grid item xs={12} md={5} className={classes.pb0}>
                <Typography component='small' className={classes.label}>
                  ORGANIZATION WEBSITE
                </Typography>
                <Typography variant='body1' component='p'>
                  {formData.website}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.num_of_employees) ? (
              <Grid item xs={12} className={classes.pb0}>
                <Typography component='small' className={classes.label}>
                  # OF EMPLOYEES
                </Typography>
                <Typography variant='body1' component='p'>
                  {formData?.num_of_employees}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.salesforceid) ? (
              <Grid item xs={12} className={classes.pb0}>
                <Typography
                  component='small'
                  className={classnames(classes.label, classes.upper)}
                >
                  Salesforce ID
                </Typography>
                <Typography variant='body1' component='p'>
                  {formData?.salesforceid}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.address_line_1) ? (
              <Grid item xs={12}>
                <Typography component='small' className={classes.label}>
                  ORGANIZATION ADDRESS
                </Typography>
                <Typography variant='body1' component='p'>
                  {formData.address_line_1}
                  {formData?.address_line_2 ? (
                    <>, {formData?.address_line_2}, </>
                  ) : (
                    ', '
                  )}
                  {formData.state} {formData.zip}
                </Typography>
              </Grid>
            ) : null}
            {Boolean(formData?.association) ? (
              <Grid item xs={12}>
                <Typography
                  component='small'
                  className={classnames(classes.label, classes.upper)}
                >
                  Vet Association
                </Typography>
                <Typography variant='body1' component='p'>
                  {formData.association}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} className={classes.pb0}>
              <Button
                disabled={loading}
                onClick={handleOnConfirm}
                autoFocus
                fullWidth
                className={classes.submitBtn}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.cancelBtn}>
              <Button
                disabled={loading}
                onClick={handleOnClose}
                variant='outlined'
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      </DialogContent>
    </Dialog>
  );
}
