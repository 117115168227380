import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { PlanPolicyDetailCard } from '@pumpkincare/shared';

import { parseAndFormatDate } from '../../../shared/utils';
import PolicyChangeRadioButtonLabel from '../policy-change-radio-button-label';
import { renewalChangeRequestStyle } from './renewal-change-request-style';

function RenewalChangeRequest({
  renewalChangeData = [],
  petData = {},
  onRatesChange,
  renewalChangeRatesValue = '',
}) {
  const classes = renewalChangeRequestStyle();
  const policyStartDate = petData.renewal_pending_policy?.policy_effective_date
    ? parseAndFormatDate(petData.renewal_pending_policy?.policy_effective_date)
    : 'N/A';
  const policyEndDate = petData.renewal_pending_policy?.policy_end_date
    ? parseAndFormatDate(petData.renewal_pending_policy?.policy_end_date)
    : 'N/A';
  const policyDates = `Policy Effective Dates: ${policyStartDate} - ${policyEndDate}`;

  const planStartDate = petData.renewal_pending_pet_plan?.plan_effective_date
    ? parseAndFormatDate(petData.renewal_pending_pet_plan.plan_effective_date)
    : 'N/A';
  const planEndDate = petData.renewal_pending_pet_plan?.plan_end_date
    ? parseAndFormatDate(petData.renewal_pending_pet_plan.plan_end_date)
    : 'N/A';
  const planDates = `Plan Effective Dates: ${planStartDate} - ${planEndDate}`;
  return (
    <div>
      <Typography className={classes.ratesLabel}>Policy & Plan Term:</Typography>
      <div style={{ display: 'flex', marginBottom: '24px' }}>
        <PlanPolicyDetailCard
          title={`INSURANCE ${petData.state} (V${petData.renewal_pending_policy?.version})`}
          status={petData.renewal_pending_policy?.status}
          effectiveDatePeriod={policyDates}
          enrollmentDate={
            petData.enrollment_date
              ? parseAndFormatDate(petData.enrollment_date)
              : 'N/A'
          }
          coverageStartDate={
            petData.coverage_start_date
              ? parseAndFormatDate(petData.coverage_start_date)
              : 'N/A'
          }
        />
        {petData.renewal_pending_pet_plan ? (
          <PlanPolicyDetailCard
            title={`PREVENTIVE ESSENTIALS (${petData.renewal_pending_pet_plan.plan.version_number || ''})`}
            status={petData.renewal_pending_pet_plan.status}
            effectiveDatePeriod={planDates}
            enrollmentDate={
              petData.pet_plan_enrollment_date
                ? parseAndFormatDate(petData.pet_plan_enrollment_date)
                : 'N/A'
            }
            coverageStartDate={
              petData.pet_plan_coverage_start_date
                ? parseAndFormatDate(petData.pet_plan_coverage_start_date)
                : 'N/A'
            }
          />
        ) : null}
      </div>

      <Typography className={classes.ratesLabel}>Rates</Typography>
      <FormControl component='fieldset'>
        <Typography className={classes.ratesDescription}>
          Disabled rate options are not available due to the change type and policy options.
        </Typography>
        <RadioGroup
          className={classes.ratesRadioGroupButton}
          name='rates'
          value={renewalChangeRatesValue}
          onChange={event => {
            onRatesChange(
              renewalChangeData.find(
                renewalChange => event.target.value === renewalChange.id
              )
            );
          }}
        >
          {renewalChangeData.map(renewalChangeOption => {
            return (
              <FormControlLabel
                key={renewalChangeOption.id}
                className={classes.radioButton}
                value={renewalChangeOption.id}
                control={<Radio />}
                disabled={!renewalChangeOption.allowed}
                label={<PolicyChangeRadioButtonLabel {...renewalChangeOption} />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RenewalChangeRequest;
