import { useState } from 'react';
import { useRefresh } from 'react-admin';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import PetPhotoModal from '../pet-photo-modal/pet-photo-modal';
import petShowStyle from '../pet-show-style';

const PLACEHOLDERS = {
  dog: '/assets/images/dog_placeholder.svg',
  cat: '/assets/images/cat_placeholder.svg',
};
function PetPhoto({ source, species, showActionButtons = true }) {
  const refresh = useRefresh();
  const classes = petShowStyle();

  const [uploadedImage, setUploadedImage] = useState(source?.location);
  const [isModalOpen, toggleModal] = useBooleanInput();
  const [isDeleting, setIsDeleting] = useBooleanInput(false);

  function handleOnModalSave(file) {
    const { document_signed_url } = file;
    setUploadedImage(document_signed_url);

    toggleModal();
    setIsDeleting(false);
    refresh();
  }

  function handleRemovePhoto() {
    setIsDeleting(true);
    toggleModal();
  }

  function handleOnDelete() {
    setUploadedImage('');
    toggleModal();
    setIsDeleting(false);
    refresh();
  }

  function handleOnclose() {
    toggleModal();
    setIsDeleting(false);
  }

  return (
    <>
      <PetPhotoModal
        open={isModalOpen}
        onClose={handleOnclose}
        onSave={handleOnModalSave}
        onDelete={handleOnDelete}
        source={source || {}}
        isDeleting={isDeleting}
      />
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '183px',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          sx={{ position: 'relative', overflow: 'hidden', height: '183px' }}
        >
          <img
            src={uploadedImage || PLACEHOLDERS[species]}
            alt=''
            className={classes.petPhoto}
          />
        </Grid>

        {uploadedImage && showActionButtons ? (
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              columnGap: '8px',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={toggleModal}
              sx={{
                '& .MuiButton-startIcon': { marginRight: '0' },
                borderRadius: '4px',
                marginTop: '19px',
                width: '100%',
                textAlign: 'center',
                padding: '0',
              }}
            >
              Upload
            </Button>
            <Button
              variant='outlined'
              color='primary'
              size='large'
              onClick={handleRemovePhoto}
              sx={{
                '& .MuiButton-startIcon': { marginRight: '0' },
                borderRadius: '4px',
                marginTop: '19px',
                width: '100%',
                textAlign: 'center',
                padding: '0',
              }}
            >
              REMOVE
            </Button>
          </Grid>
        ) : showActionButtons ? (
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={toggleModal}
              sx={{
                '& .MuiButton-startIcon': { marginRight: '0' },
                borderRadius: '4px',
                marginTop: '19px',
                width: '100%',
                textAlign: 'center',
                padding: '0',
              }}
            >
              Upload
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

PetPhoto.prototype = {
  source: PropTypes.object,
  species: PropTypes.string,
};

PetPhoto.defaultProps = {
  source: {},
  species: '',
};

export default PetPhoto;
