import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';

import CircularProgress from '../../../../shared/components/SanitizedComponents/CircularProgress';
import { getClaimsSummaries } from '../../service/customerService';
import Summary from './Summary';

export default function CustomerClaim(props) {
  const { id: userId } = props;
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [summaries, setSummaries] = useState([]);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);

      getClaimsSummaries(userId)
        .then(response => {
          setSummaries(response);
        })
        .catch(error => {
          setSummaries([]);
          notify(error.message, 'error');
        })
        .finally(() => setIsLoading(false));
    }
  }, [userId, setSummaries, setIsLoading, notify]);

  return isLoading ? (
    <div style={{ margin: '24px' }}>
      <CircularProgress />
    </div>
  ) : summaries.length > 0 ? (
    <div style={{ margin: '24px 0' }}>
      {summaries.map((summary, index) => (
        <Summary key={index} userId={userId} {...summary} />
      ))}
    </div>
  ) : (
    <p>No claims to show</p>
  );
}
