import { useState } from 'react';
import { List, useListController } from 'react-admin';

import { UNVERIFIED } from '@pumpkincare/claims';
import { SimpleSelectList } from '@pumpkincare/shared';
import { useOpsList } from '@pumpkincare/users';

import { orderUsersByEmail } from '../../../../../shared/utils/orderUsersByEmail';
import ClaimListActions from '../ClaimListActions';
import ClaimPrimarySession from '../ClaimPrimarySession/ClaimPrimarySession';
import ClaimSecondarySession from '../ClaimSecondarySession';
import ClaimTertiarySession from '../ClaimTertiarySession';

function ClaimList() {
  const { data, isLoading } = useOpsList();
  const [selectedIds, setSelectedIds] = useState([]);

  function unselectIds() {
    setSelectedIds([]);
  }

  const { data: claims } = useListController();
  return !isLoading ? (
    <List
      sort={{ field: 'status_last_updated', order: 'DESC' }}
      exporter={false}
      hasCreate={false}
      filterDefaultValues={{ assigned_id: 'unassigned', status: UNVERIFIED }}
      actions={
        <ClaimListActions
          opsList={orderUsersByEmail(data || [])}
          filterClaimsOn={['status_last_updated']}
        />
      }
    >
      <SimpleSelectList
        selectedIds={selectedIds}
        unselectIds={unselectIds}
        bulkActionButtons={true}
        onToggleItem={id => {
          const filteredSelectedIds = selectedIds.filter(item => item !== id);
          if (filteredSelectedIds.length !== selectedIds.length) {
            setSelectedIds(filteredSelectedIds);
          } else {
            const updatedSelectedIds = [...selectedIds, id];
            setSelectedIds(updatedSelectedIds);
          }
        }}
        primaryText={record => <ClaimPrimarySession record={record} />}
        secondaryText={record => <ClaimSecondarySession record={record} />}
        tertiaryText={record => <ClaimTertiarySession record={record} />}
        style={{ backgroundColor: 'rgb(0, 125, 255, 0.08)' }}
      />
    </List>
  ) : null;
}

export default ClaimList;
