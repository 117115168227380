import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { searchVets } from '../../services/vetServices';

const useStyles = makeStyles(
  {
    endAdornment: {
      right: '8px',
      top: '-8px',
    },
    searchAdornment: {
      position: 'absolute',
      right: '8px',
      top: 'calc(50% - 8px)',
    },
    root: {
      paddingTop: '4px',
      transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.13)',
      },
    },
    inputRoot: {
      paddingLeft: '12px',
    },
    labelRoot: {
      marginLeft: '12px',
      transform: 'translate(0, 14px) scale(1)',
    },
    labelShrink: {
      transform: 'translate(0, 4px) scale(0.75)',
    },
    option: {
      display: 'flex',
      cursor: 'pointer',
      flexDirection: 'column',
    },
    optionName: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    optionAddress: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    pep4Container: {
      marginTop: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    pep4: {
      marginLeft: '4px',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#2196F3',
    },
  },
  { name: 'vet-input' }
);

function VetInput(props) {
  const { formData, label, source, onChange, disabled } = props;
  const { setValue } = useFormContext();

  const classes = useStyles();

  const [value, setVetValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!value && formData[source]) {
      setVetValue(formData[source]);
    }
  }, [formData, source, value]);

  useEffect(() => {
    let active = true;

    const zipcode = props.zipcode ? props.zipcode : formData[props.zipcodeSource];

    // if input is empty but zipcode is known, call the api and it will return some
    // vets from the region. but if we dont have both info, it does not make sense
    // to search.
    if (inputValue === '' && !zipcode) {
      setOptions(value ? [value] : []);
      setLoading(false);
      return;
    }

    // when we select an option, inputValue will change, triggering this code
    // if inputValue (which is vet_name) is equal to current value.vet_name, then
    // we do not have to search again.
    if (value && inputValue === value.vet_name) {
      setOptions(value ? [value] : []);
      setLoading(false);
      return;
    }

    setLoading(true);
    setOptions([]);

    const timer = setTimeout(() => {
      searchVets(inputValue, zipcode)
        .then(results => {
          if (active) {
            setOptions(results);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);

    return () => {
      active = false;
      clearTimeout(timer);
    };
  }, [formData, inputValue, props, value]);

  function handleOnChange(event, newVet) {
    setVetValue(newVet);
    formData[source] = newVet;
    setValue(source, newVet);

    onChange(newVet);
  }

  function handleOnInputChange(event, newInputValue) {
    setInputValue(newInputValue);
  }

  function handleClearButtonClick() {
    setVetValue(null);
    setInputValue('');
    formData[source] = null;
    onChange(null);
  }

  function highlight(vetName) {
    if (!inputValue) {
      return vetName;
    }

    const startIndex = vetName.search(new RegExp(inputValue, 'i'));
    if (startIndex === -1) {
      return vetName;
    }

    const endIndex = startIndex + inputValue.length;

    return (
      <>
        {startIndex > 0 ? vetName.substring(0, startIndex) : null}
        <b>{vetName.substring(startIndex, endIndex)}</b>
        {endIndex < vetName.length ? vetName.substring(endIndex) : null}
      </>
    );
  }

  return (
    <>
      <Autocomplete
        options={options}
        autoComplete
        value={value}
        inputValue={inputValue}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.vet_name}
        noOptionsText={
          inputValue === '' ? 'Start typing to search' : 'No matching vet found'
        }
        loadingText={'Searching...'}
        loading={isLoading}
        fullWidth
        popupIcon={null}
        clearOnBlur={false}
        classes={{
          root: classes.root,
          input: classes.input,
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
        }}
        renderOption={(props, option, state, ownerState) => (
          <div {...props} key={option.id} className={classes.option}>
            <Typography className={classes.optionName}>
              {highlight(option.vet_name)}
            </Typography>
            <Typography className={classes.optionAddress}>
              {option.normalized_addr}
            </Typography>
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            required={props.required}
            InputLabelProps={{
              ...props.InputLabelProps,
              classes: {
                ...props.InputLabelProps.classes,
                root: classes.labelRoot,
                shrink: classes.labelShrink,
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position='end' className={classes.searchAdornment}>
                  {value || inputValue ? (
                    <IconButton
                      style={{ padding: '0px', color: 'rgba(0, 0, 0, 0.6)' }}
                      onClick={handleClearButtonClick}
                      size='large'
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        PopperComponent={params => (
          <Popper
            {...params}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
          />
        )}
        disabled={disabled}
      />

      {value && value.has_pep4 === true ? (
        <div className={classes.pep4Container}>
          <FlagOutlined style={{ color: '#2196F3' }} />
          <Typography className={classes.pep4}>
            Prevent 4.0: Vet Plan Available
          </Typography>
        </div>
      ) : null}
    </>
  );
}

function zipcodePropType(props, propName, componentName) {
  return (
    !props.hasOwnProperty('zipcode') &&
    !props.hasOwnProperty('zipcodeSource') &&
    new Error(`Either "zipcode" or "zipcodeSource" is required`)
  );
}

VetInput.propTypes = {
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  zipcode: zipcodePropType,
  zipcodeSource: zipcodePropType,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

VetInput.defaultProps = {
  label: 'Search Vet Clinics',
  onChange: () => {},
  disabled: false,
  InputLabelProps: {},
};

export default VetInput;
