import { useState } from 'react';
import { useCreate, useNotify, useRecordContext } from 'react-admin';
import IconAdd from '@mui/icons-material/Add';
import IconClear from '@mui/icons-material/Clear';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';

import {
  ALL_MEDICAL_RECORDS_REQUEST,
  CLAIM_INVOICE_REQUEST,
  MEDICAL_RECORD_REQUEST,
} from '@pumpkincare/medical-records';
import { useCustomer, usePet } from '@pumpkincare/users';

import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import { parseAndFormatDate, validateDateYYYYMMDD } from '../../../../shared/utils';
import { validateEmail } from '../../../../shared/utils/utils';
import CancelRequestRecordsModal from '../cancel-request-records-modal';
import RequestRecordsPreview from '../request-records-preview';
import RecordsDocumentsSelector from './records-documents-selector';
import RecordsVetSelector from './records-vet-selector';
import { requestRecordsModalStyle } from './request-records-modal-style';

function formatDocuments(allMedicalRecords, specificRecords, invoice) {
  return [allMedicalRecords, specificRecords, invoice]
    .filter(record => (Array.isArray(record) ? record.length : record.type))
    .flat();
}

export function validateDocumentSelection(
  allMedicalRecords,
  specificRecords,
  invoice
) {
  return formatDocuments(allMedicalRecords, specificRecords, invoice).reduce(
    (isValid, record, idx) => {
      if (idx === 0) isValid = true; // set to true if filtering returns at least one record
      if (!isValid) return isValid; // if any record is invalid, break out of the loop

      // date is optional for All Medical Records, but if it's provided, it must be valid
      // both date and description are required for Specific Record
      // date is required for Invoice
      if (
        record.type === ALL_MEDICAL_RECORDS_REQUEST &&
        record.date &&
        !validateDateYYYYMMDD(record.date)
      )
        isValid = false;
      if (
        record.type === MEDICAL_RECORD_REQUEST &&
        (!validateDateYYYYMMDD(record.date) || !record.description)
      )
        isValid = false;
      if (
        record.type === CLAIM_INVOICE_REQUEST &&
        !validateDateYYYYMMDD(record.date)
      )
        isValid = false;

      return isValid;
    },
    false
  );
}

function RequestRecordsModal({ onClose, onSuccess }) {
  const classes = requestRecordsModalStyle();
  const record = useRecordContext();
  const notify = useNotify();
  const [create, { isLoading: isSubmitting }] = useCreate();

  const { data: customerData } = useCustomer(record?.customer_id);
  const { data: petData } = usePet(record?.pet_id);

  const [isPreview, togglePreview] = useBooleanInput(false);
  const [isConfirmCancelModal, toggleIsConfirmCancelModal] = useBooleanInput(false);
  const [isManualOrg, toggleIsOrg] = useBooleanInput(false);
  const [email, setEmail] = useState('');
  const [vet, setVet] = useState({});
  const [manualOrg, setManualOrg] = useState('');
  const [allMedicalRecords, setAllMedicalRecords] = useState({});
  const [specificRecords, setSpecificRecords] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [hasAdditionalOwner, toggleHasAdditionalOwner] = useBooleanInput(false);
  const [additionalOwnerFirst, setAdditionalOwnerFirst] = useState('');
  const [additionalOwnerLast, setAdditionalOwnerLast] = useState('');
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);

  function validateInputs() {
    const isVetValid = isManualOrg ? !!manualOrg : !!vet;
    const isEmailValid = validateEmail(email);

    const isDocumentsValid = validateDocumentSelection(
      allMedicalRecords,
      specificRecords,
      invoice
    );

    const isAdditionalOwnerValid =
      !hasAdditionalOwner || (!!additionalOwnerFirst && !!additionalOwnerLast);

    return isVetValid && isEmailValid && isDocumentsValid && isAdditionalOwnerValid;
  }

  function handleSubmitToPreview() {
    const isValid = validateInputs();

    if (!isValid) {
      notify('Error: One or more fields are missing or invalid', { type: 'error' });
      setHasSubmittedForm(true);
      return;
    }
    togglePreview();
  }

  function handleSubmitRequest() {
    const payload = {
      recipient_org_name: vet?.vet_name || manualOrg,
      recipient_email: email,
      claim_submission_id: record.id,
      pet_id: petData.id,
      records: formatDocuments(allMedicalRecords, specificRecords, invoice).map(
        doc => {
          const value = doc;
          if (doc.date) doc.start_date = doc.date;
          return value;
        }
      ),
    };
    if (additionalOwnerFirst)
      payload.additional_owner = `${additionalOwnerFirst} ${additionalOwnerLast}`;
    if (additionalNotes) payload.additional_notes = additionalNotes;
    if (vet?.id) payload.vet_practice_id = vet.id;

    // useCreate seems to empty out return of GET ALL endpoint
    create(
      RoutePaths.medicalRecordsRequests,
      { data: payload },
      {
        onSuccess: () => {
          notify('Record request sent', { type: 'success' });
          onSuccess();
        },
        onError: ({ message }) => {
          notify(
            message ||
              'There was an error while submitting this medical record request',
            'error'
          );
        },
      }
    );
  }

  // this handles the case of user clicking X to deselect a clinic
  function handleVetChange(event) {
    setVet(event || {});
  }

  return (
    <Dialog
      open
      className={classes.root}
      classes={{ paper: classes.paper }}
      onClose={toggleIsConfirmCancelModal}
    >
      {!isPreview ? (
        <>
          <DialogTitle className={classes.modalTitle}>
            <Typography className={classes.title}>Request Records</Typography>
          </DialogTitle>

          <DialogContent className={classes.modalContent}>
            <div className={classes.leftSection}>
              <Typography className={classes.sectionTitle}>
                Clinic Details
              </Typography>

              <RecordsVetSelector
                classes={{
                  textLabel: classes.textLabel,
                  marginBottom: classes.marginBottom,
                  formHelperText: classes.formHelperText,
                }}
                isManualOrg={isManualOrg}
                onEmail={setEmail}
                onManualOrg={setManualOrg}
                hasSubmittedForm={hasSubmittedForm}
                toggleIsOrg={toggleIsOrg}
                manualOrg={manualOrg}
                email={email}
                vet={vet}
                onVet={handleVetChange}
                zipcode={customerData?.rating_address?.zipcode || ''}
              />

              <Typography className={classes.sectionTitle}>
                Documents to Request
              </Typography>
              {hasSubmittedForm &&
              !(allMedicalRecords.type || specificRecords.length || invoice.type) ? (
                <FormHelperText error className={classes.documentsFormHelperText}>
                  Select at least one document to request
                </FormHelperText>
              ) : null}

              <RecordsDocumentsSelector
                classes={{
                  textLabel: classes.textLabel,
                  marginBottom: classes.marginBottom,
                  formHelperText: classes.formHelperText,
                }}
                hasSubmittedForm={hasSubmittedForm}
                allMedicalRecords={allMedicalRecords}
                onAllMedicalRecords={setAllMedicalRecords}
                specificRecords={specificRecords}
                onSpecificRecords={setSpecificRecords}
                invoice={invoice}
                onInvoice={setInvoice}
              />

              <Typography className={classes.sectionTitle}>
                Additional Notes
              </Typography>
              <FormControlLabel
                classes={{ root: classes.textLabel }}
                labelPlacement='top'
                label='Reason for Request or Notes'
                control={
                  <TextField
                    id='reason-for-request'
                    value={additionalNotes}
                    onChange={e => setAdditionalNotes(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    minRows={2}
                    multiline
                    fullWidth
                  />
                }
              />
            </div>

            <div className={classes.rightSection}>
              <Typography className={classes.sectionTitle}>
                Pet & Owner Info
              </Typography>

              <Typography variant='h6'>{petData?.name}</Typography>

              <div className={classes.grid}>
                <Typography variant='body1'>Date of Birth:</Typography>
                <Typography variant='body1'>
                  {parseAndFormatDate(petData?.birth_date)}
                </Typography>

                <Typography variant='body1'>Enrollment Date:</Typography>
                <Typography variant='body1'>
                  {parseAndFormatDate(petData?.enrollment_date)}
                </Typography>

                <Typography variant='body1'>Policyholder:</Typography>
                <div>
                  <Typography variant='body1'>
                    {customerData?.first_name} {customerData?.last_name}
                  </Typography>
                  <Typography variant='body1'>{customerData?.phone}</Typography>
                </div>
              </div>

              {hasAdditionalOwner ? (
                <div className={classes.additionalOwner}>
                  <div className={classes.additionalOwnerHeader}>
                    <Typography variant='body1'>Additional Owner</Typography>
                    <Button onClick={toggleHasAdditionalOwner}>
                      <IconClear />
                      <Typography variant='body1'>Remove</Typography>
                    </Button>
                  </div>

                  <FormControlLabel
                    classes={{
                      root: classes.textLabel,
                    }}
                    labelPlacement='top'
                    label='First Name'
                    control={
                      <TextField
                        value={additionalOwnerFirst}
                        onChange={e => setAdditionalOwnerFirst(e.target.value)}
                        className={classes.marginBottom}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        FormHelperTextProps={{
                          classes: { root: classes.formHelperText },
                        }}
                        error={hasSubmittedForm && !additionalOwnerFirst}
                        helperText={
                          hasSubmittedForm && !additionalOwnerFirst
                            ? "Enter additional owner's first name"
                            : ''
                        }
                        variant='outlined'
                        size='medium'
                        fullWidth
                        required
                      />
                    }
                  />

                  <FormControlLabel
                    classes={{ root: classes.textLabel }}
                    labelPlacement='top'
                    label='Last Name'
                    control={
                      <TextField
                        value={additionalOwnerLast}
                        onChange={e => setAdditionalOwnerLast(e.target.value)}
                        className={classes.marginBottom}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        FormHelperTextProps={{
                          classes: { root: classes.formHelperText },
                        }}
                        error={hasSubmittedForm && !additionalOwnerLast}
                        helperText={
                          hasSubmittedForm && !additionalOwnerLast
                            ? "Enter additional owner's last name"
                            : ''
                        }
                        variant='outlined'
                        size='medium'
                        fullWidth
                        required
                      />
                    }
                  />
                </div>
              ) : (
                <div className={classes.addAdditionalOwner}>
                  <Typography variant='body1'>
                    Different owner listed on invoice?
                  </Typography>
                  <Button onClick={toggleHasAdditionalOwner}>
                    <IconAdd />
                    <Typography variant='body1'>Add Additional Owner</Typography>
                  </Button>
                </div>
              )}
            </div>
          </DialogContent>

          <DialogActions className={classes.modalActions}>
            <Button
              className={classes.cancelButton}
              onClick={toggleIsConfirmCancelModal}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              className={classes.confirmButton}
              onClick={handleSubmitToPreview}
              variant='contained'
              color='primary'
            >
              Preview & Send
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle className={classes.modalTitle}>
            <Typography className={classes.title}>Request Records</Typography>
          </DialogTitle>

          <DialogContent className={classes.modalContent}>
            <RequestRecordsPreview
              vetName={vet?.vet_name || manualOrg}
              petName={petData.name}
              firstName={customerData.first_name}
              lastName={customerData.last_name}
              phone={customerData.phone}
              additionalOwner={`${additionalOwnerFirst} ${additionalOwnerLast}`}
              documents={formatDocuments(
                allMedicalRecords,
                specificRecords,
                invoice
              )}
              claimId={record.id}
              additionalNotes={additionalNotes}
            />
          </DialogContent>

          <DialogActions className={classes.modalActions}>
            <Button
              className={classes.returnButton}
              onClick={togglePreview}
              variant='outlined'
            >
              Edit Request
            </Button>
            <Button
              className={classes.confirmButton}
              onClick={handleSubmitRequest}
              disabled={isSubmitting}
              variant='contained'
              color='primary'
            >
              {isSubmitting ? (
                <CircularProgress size={16} color='inherit' thickness={4} />
              ) : (
                '' + 'Send Request'
              )}
            </Button>
          </DialogActions>
        </>
      )}

      {isConfirmCancelModal ? (
        <CancelRequestRecordsModal
          onClose={onClose}
          onReturn={toggleIsConfirmCancelModal}
        />
      ) : null}
    </Dialog>
  );
}

export default RequestRecordsModal;
