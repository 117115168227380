import { Typography } from '@mui/material';

import {
  getCurrentPolicy,
  getLatestPlan,
} from '../../../../shared/utils/productUtils';
import { customerDetailStyle } from '../customer-detail/customer-detail-styles';

export default function PlanStatusField({ petRecord }) {
  const classes = customerDetailStyle();

  const policy = getCurrentPolicy(petRecord?.policies);
  const plan = getLatestPlan(policy, petRecord);

  return plan ? (
    <Typography
      variant='subtitle2'
      sx={{ color: '#000000', textTransform: 'capitalize' }}
      className={plan?.status === 'cancelled' ? classes.cancelledStatus : null}
    >
      {plan.status}
    </Typography>
  ) : (
    ''
  );
}
