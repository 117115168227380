import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import RoutePaths from '../../../../routes';

function CheckoutSuccessModal(props) {
  const { userId, showDialog } = props;

  return (
    <Dialog open={showDialog} aria-label='Checkout Success'>
      <DialogContent style={{ display: 'inline-grid' }}>
        <div style={{ textAlign: 'center' }}>
          <CheckCircleIcon fontSize='large' style={{ color: 'green' }} />
          <Typography style={{ fontWeight: '800', margin: '18px' }}>
            Payment Successful!
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a
              style={{
                textDecoration: 'none',
                border: '1px solid',
                margin: 'auto',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
              href='/quotes/create'
            >
              <AddIcon />
              START A NEW QUOTE
            </a>

            <Button
              color='primary'
              variant='contained'
              style={{ textTransform: 'none', marginLeft: '10px' }}
              component={Link}
              to={{
                pathname: `/${RoutePaths.customers}/${userId}/show`,
              }}
            >
              VIEW CUSTOMER DETAILS
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CheckoutSuccessModal;
