import { Typography } from '@mui/material';

import { formatCurrency } from '../../../../shared/utils';
import {
  getCurrentPolicy,
  getLatestPlan,
} from '../../../../shared/utils/productUtils';

export default function PlanCostField({ petRecord }) {
  const policy = getCurrentPolicy(petRecord?.policies);
  const plan = getLatestPlan(policy, petRecord);

  return plan ? (
    <Typography variant='subtitle2' sx={{ color: '#000000' }}>
      {formatCurrency(plan.cost, { areCents: false })}
    </Typography>
  ) : (
    ''
  );
}
