import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

export const useBadgeStyles = makeStyles(
  {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  { name: 'custom-badge' }
);

export const StyledBadge = withStyles(
  () => (
    {
      root: {
        border: ({ borderColor = 'transparent' }) => `2px solid ${borderColor}`,
        padding: '.15rem .5rem',
        borderRadius: '.25rem',
        background: ({ bg = 'grey' }) => bg,
        color: ({ color = 'black' }) => color,
        marginLeft: '.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        letterSpacing: '-0.02em',
        visibility: ({ show }) => (show === 'true' ? 'visible' : 'hidden'),
        position: ({ show }) => (show === 'true' ? 'static' : 'absolute'),
        top: ({ show }) => (show === 'true' ? 'initial' : '-9999'),
        left: ({ show }) => (show === 'true' ? 'initial' : '-9999'),
        height: ({ show }) => (show === 'true' ? 'auto' : '0'),
      },
    },
    { name: 'styled-badge' }
  )
)(Box);
