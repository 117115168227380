import { useEffect, useState } from 'react';
import {
  useCreate,
  useDeleteMany,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { analyticsTrack } from '@pumpkincare/analytics';

import useBooleanInput from '../../../hooks/useBooleanInput';
import { providerV2 } from '../../../lib/dataProvider/provider-v2';
import RoutePaths from '../../../routes';
import { isDateWithinRange } from '../../../shared/utils';
import ClaimLineItemAutomation from './claim-line-item-automation';

function ClaimLineItemAutomationController({
  onEditInvoiceClick,
  lineItemsByInvoiceData,
  onCommitLineItemChanges,
  renderMoneyInput,
  petData,
  policyData,
  wellnessData,
  currentOpsUserData,
  toggleOutsidePolicyPeriod,
}) {
  const {
    fer4114StoreLiaTableValuesInTheSession,
    fer4411LineItemAutomationForAdminClaims,
    fer4411SettingsForSendingClaimInvoiceForOcr,
  } = useFlags();

  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();

  const [deleteMany, { isLoading: isDeleting }] = useDeleteMany();
  const [checkedRows, setCheckedRows] = useState({});
  const [newLineItem, setNewLineItem] = useState({
    id: null,
    field: null,
    claim_invoice_id: null,
  });

  const [lastEditCell, setLastEditCell] = useState({
    id: null,
    field: null,
    claimInvoiceId: null,
  });

  const isCachedInvoiceData = fer4114StoreLiaTableValuesInTheSession
    ? sessionStorage.getItem('cachedInvoiceData') !== 'undefined'
    : false;

  const cachedInvoiceData = isCachedInvoiceData
    ? JSON.parse(sessionStorage.getItem('cachedInvoiceData'))
    : null;

  const [invoiceData, setInvoiceData] = useState(() => {
    if (fer4114StoreLiaTableValuesInTheSession) {
      return mergeCacheApiInvoiceData();
    }
    return lineItemsByInvoiceData;
  });

  const [expandedList, setExpandedList] = useState(
    invoiceData?.invoices?.map(invoice => invoice.id)
  );

  const [lineItemsToBeRemoved, setLineItemsToBeRemoved] = useState([]);
  const [isConfirmDeletionOpen, toggleConfirmDeletionOpen] = useBooleanInput();
  const [bulkChanges, setBulkChanges] = useState({
    loss_date: '',
    description: '',
    total_amount: '',
    line_item_type: '',
    quantity: '',
  });

  const [checkClaimInvoiceStatusOCR, setCheckClaimInvoiceStatusOCR] = useState(
    invoiceData?.invoices?.some(
      invoice => invoice.status == 'processing' || invoice.status == 'ready'
    )
  );

  const [progressInvoiceStatus, setProgressInvoiceStatus] = useState({
    by_ocr: false,
    by_observe: false,
  });

  const { mutateAsync: mutateCheckClaimInvoiceStatus } = useMutation(claimId =>
    checkClaimInvoiceStatus(claimId)
  );

  function checkClaimInvoiceStatus(claimId) {
    providerV2
      .getOne('claims', {
        id: `${claimId}/invoices/status`,
      })
      .then(({ data }) => {
        const isProcessing = data?.invoices?.some(
          invoice => invoice.status == 'processing' || invoice.status == 'ready'
        );

        if (!isProcessing) {
          refresh();
        }
      });
  }

  const observerInterval =
    fer4411SettingsForSendingClaimInvoiceForOcr?.observer?.interval || 30000;

  useEffect(() => {
    const interval = setInterval(() => {
      // If the Feature Flag is disabled, stop the observer
      if (!fer4411LineItemAutomationForAdminClaims) {
        setCheckClaimInvoiceStatusOCR(false);
        clearInterval(interval);
      }

      if (checkClaimInvoiceStatusOCR) {
        setProgressInvoiceStatus({ ...progressInvoiceStatus, by_observe: true });
        mutateCheckClaimInvoiceStatus(record.id);
      } else {
        setProgressInvoiceStatus({ ...progressInvoiceStatus, by_observe: false });
        setCheckClaimInvoiceStatusOCR(false);
        clearInterval(interval);
      }
    }, observerInterval);

    return () => clearInterval(interval);
  }, [observerInterval, mutateCheckClaimInvoiceStatus, checkClaimInvoiceStatusOCR]);

  function handleSendToOcr(claim_invoice_id) {
    setProgressInvoiceStatus({ ...progressInvoiceStatus, by_ocr: true });
    create(
      `${RoutePaths.claims}/invoices/${claim_invoice_id}/send-to-ocr`,
      {
        data: {},
      },
      {
        onSuccess: data => {
          const update = data?.invoices.find(
            updateItem => updateItem.id === claim_invoice_id
          );

          const updatedInvoices = invoiceData.invoices.map(item => {
            if (update && item.id === update.id) {
              return {
                ...item,
                status: update.status,
                sent_to_ai: true,
              };
            }
            return item;
          });

          setInvoiceData({ ...invoiceData, invoices: updatedInvoices });
          onCommitLineItemChanges({ ...invoiceData, invoices: updatedInvoices });

          setCheckClaimInvoiceStatusOCR(true);
          setProgressInvoiceStatus({ ...progressInvoiceStatus, by_ocr: false });
        },
        onError: error => {
          setProgressInvoiceStatus({ ...progressInvoiceStatus, by_ocr: false });
          notify(
            `There was an error while send claim invoice to OCR: ${error.message}`,
            {
              type: 'warning',
            }
          );
        },
      }
    );
  }

  useEffect(() => {
    if (fer4114StoreLiaTableValuesInTheSession && invoiceData) {
      sessionStorage.setItem('cachedInvoiceData', JSON.stringify(invoiceData));
    }

    if (invoiceData) {
      let plan;
      if (policyData && !policyData.addedFakeId) {
        plan = policyData;
      } else if (wellnessData) {
        plan = wellnessData;
      }
      if (plan) {
        invoiceData.invoices?.forEach(invoice => {
          invoice?.line_items?.forEach(item => {
            item.has_incorrect_policy_period = item.loss_date
              ? !isDateWithinRange(item.loss_date, plan.start_date, plan.end_date)
              : false;
          });
        });

        const hasIncorrectPolicyPeriod = invoiceData.invoices.some(invoice =>
          invoice?.line_items.some(
            line_item => line_item.has_incorrect_policy_period
          )
        );
        toggleOutsidePolicyPeriod(hasIncorrectPolicyPeriod);
      }
    }
  }, [invoiceData, wellnessData, policyData]);

  const navigate = useNavigate();

  const claimRecord = {
    claim_id: record.id,
    user_id: record.customer_id,
    pet_id: record.pet_id,
    vet_id: record.vet_id,
  };

  const apiRefs = invoiceData?.invoices?.map(invoice => {
    return { invoiceId: invoice.id, apiRef: useGridApiRef() };
  });

  function mergeCacheApiInvoiceData() {
    /*
      This function is meant to go through the incoming invoice data (lineItemsByInvoiceData) and merge the line items
      for each invoice with the existing cached invoice data (cachedInvoiceData) if the claim_submission_id matches.

      If the cached claim_submission_id does not match the incoming id, do not merge, and return incoming data
     */
    if (
      cachedInvoiceData &&
      cachedInvoiceData.response.id === lineItemsByInvoiceData.response.id
    ) {
      const updatedData = {
        response: lineItemsByInvoiceData.response,
        invoices: lineItemsByInvoiceData.invoices.map(invoice => {
          const cachedInvoice = cachedInvoiceData.invoices.find(
            cachedInvoice => cachedInvoice.id === invoice.id
          );
          if (cachedInvoice) {
            return {
              ...invoice,
              /*
                The below logic concatenates the line items from the incoming data with the cached data, excluding any
                duplicates.

                Edits completed on the FE will be prioritized over matching BE data.
               */
              line_items: cachedInvoice.line_items.concat(
                invoice.line_items.filter(
                  lineItem =>
                    !cachedInvoice.line_items.some(item => item.id === lineItem.id)
                )
              ),
            };
          }
          return invoice;
        }),
      };
      onCommitLineItemChanges({ ...updatedData, invoices: updatedData.invoices });

      return updatedData;
    }
    return lineItemsByInvoiceData;
  }

  function onAddInvoiceClick() {
    navigate(`/${RoutePaths.documentsV2}/create`, {
      state: { record: claimRecord },
    });
  }

  function onDownloadInvoiceClick(invoice, e) {
    e.stopPropagation();

    analyticsTrack({
      event: 'clicked_download_invoice_claims',
      label: 'claims invoice download',
      category: 'claims admin actions',
      claim_id: claimRecord.claim_id,
      ops_user_id: currentOpsUserData?.id,
      file_name: invoice?.source_file_name,
    });

    window.open(invoice.document_signed_url);
  }

  function resetBulkEditSelection() {
    setBulkChanges({
      loss_date: '',
      description: '',
      total_amount: '',
      line_item_type: '',
      quantity: '',
    });
    setCheckedRows({ ids: [] });
  }

  function updateLineItem(lineItem, bulkChanges, policyData) {
    const { loss_date, description, total_amount, line_item_type, quantity } =
      bulkChanges;
    if (loss_date) {
      lineItem.has_incorrect_policy_period = !isDateWithinRange(
        loss_date,
        policyData?.start_date,
        policyData?.end_date
      );
      lineItem.loss_date = loss_date;
    }
    if (description) lineItem.description = description;
    if (total_amount) lineItem.total_amount = parseFloat(total_amount);
    if (line_item_type) lineItem.line_item_type = line_item_type;
    if (quantity) lineItem.quantity = parseFloat(quantity);
    return lineItem;
  }

  function shouldUpdateLineItem(lineItem, checkedRows) {
    return Object.values(checkedRows).some(invoice =>
      invoice.ids?.includes(lineItem.id)
    );
  }

  function updateInvoiceLineItems(inv, bulkChanges, policyData, checkedRows) {
    return inv.line_items.map(lineItem => {
      if (shouldUpdateLineItem(lineItem, checkedRows)) {
        return updateLineItem(lineItem, bulkChanges, policyData);
      }
      return lineItem;
    });
  }

  function handleBulkEditClick() {
    const updatedInvoices = invoiceData.invoices.map(inv => ({
      ...inv,
      line_items: updateInvoiceLineItems(inv, bulkChanges, policyData, checkedRows),
    }));

    onCommitLineItemChanges({ ...invoiceData, invoices: updatedInvoices });
    setInvoiceData({ ...invoiceData, invoices: updatedInvoices });
    resetBulkEditSelection();
  }

  function handleAddLineItem(invoiceId, lineItems = []) {
    analyticsTrack({
      event: 'clicked_add_line_item_claims',
      label: 'claims add line item',
      category: 'claims admin actions',
      claim_id: claimRecord.claim_id,
      ops_user_id: currentOpsUserData?.id,
      invoice_id: invoiceId,
    });

    // Retain data for the last edited cell
    const apiRef = apiRefs.find(ref => ref.invoiceId === invoiceId).apiRef;

    if (lastEditCell.id) {
      const invoice = invoiceData.invoices.find(
        invoice => invoice.id === lastEditCell.claimInvoiceId
      );
      const lineItem = invoice?.line_items.find(
        lineItem => lineItem.id === lastEditCell.id
      );
      if (lineItem && invoice.id === invoiceId) {
        lineItem[lastEditCell.field] = apiRef.current.getCellValue(
          lastEditCell.id,
          lastEditCell.field
        );
      }
    }

    let newLineItemType =
      petData?.wellness && !petData?.latest_pet_policy ? 'wellness' : '';
    if (!newLineItemType)
      newLineItemType = petData?.latest_pet_plan ? 'prevent' : 'insurance';

    // Add new line item to the top of the existing invoice list
    const invoiceList = invoiceData.invoices.map(invoice => {
      if (invoice.id === invoiceId) {
        return {
          ...invoice,
          line_items: [
            {
              // Adding a timestamp to prevent duplicate keys
              id: new Date().getTime().toString(),
              claim_invoice_id: invoiceId,
              loss_date: null,
              ordinal: lineItems.length + 1,
              description: null,
              quantity: null,
              cost_per_unit_amount: null,
              tax_amount: null,
              total_amount: null,
              line_item_type: newLineItemType,
            },
            ...invoice.line_items,
          ],
        };
      }
      return invoice;
    });

    // Save the updated data
    setInvoiceData({ ...invoiceData, invoices: invoiceList });
    onCommitLineItemChanges({ ...invoiceData, invoices: invoiceList });
    setNewLineItem(
      invoiceList.find(invoice => invoice.id === invoiceId).line_items[0]
    );
  }

  function handleCellClick(params) {
    const apiRef = apiRefs.find(
      ref => ref.invoiceId === params.row.claim_invoice_id
    ).apiRef;

    if (
      params.cellMode !== 'edit' &&
      params.field !== '__check__' &&
      params.field !== 'delete'
    ) {
      apiRef.current.startCellEditMode({ id: params.id, field: params.field });
      setLastEditCell({
        id: params.id,
        field: params.field,
        claimInvoiceId: params.row.claim_invoice_id,
      });
    }
  }

  function onLineItemDeleteClick(lineItemId) {
    setLineItemsToBeRemoved([{ line_item_id: lineItemId }]);
    toggleConfirmDeletionOpen();
  }

  function onBulkDeleteClick() {
    const checkedLineItems = Object.keys(checkedRows).flatMap(
      invoiceId => checkedRows[invoiceId].ids
    );

    setLineItemsToBeRemoved(
      checkedLineItems.map(lineItemId => {
        return { line_item_id: lineItemId };
      })
    );
    toggleConfirmDeletionOpen();
  }

  function handleDeleteLineItem() {
    analyticsTrack({
      event: 'clicked_delete_line_item_claims',
      label: 'claims delete line item',
      category: 'claims admin actions',
      claim_id: claimRecord.claim_id,
      ops_user_id: currentOpsUserData?.id,
    });

    const idsToBeSentToServer = [];
    const invoiceList = invoiceData.invoices.map(invoice => {
      return {
        ...invoice,
        line_items: invoice.line_items.filter(lineItem => {
          const lineItemToBeRemoved = lineItemsToBeRemoved.find(
            item => item.line_item_id === lineItem.id
          );
          if (lineItemToBeRemoved && isNaN(lineItemToBeRemoved.line_item_id)) {
            idsToBeSentToServer.push(lineItemToBeRemoved.line_item_id);
          }
          return !lineItemToBeRemoved;
        }),
      };
    });

    if (idsToBeSentToServer.length > 0) {
      deleteMany(
        RoutePaths.claims,
        {
          ids: `${record.id}/invoice-line-items`,
          meta: {
            line_item_ids: idsToBeSentToServer,
          },
        },
        {
          onSuccess: () => {
            setInvoiceData({ ...invoiceData, invoices: invoiceList });
            onCommitLineItemChanges({ ...invoiceData, invoices: invoiceList });

            toggleConfirmDeletionOpen();
            notify('Line item(s) deleted successfully.', { type: 'success' });
          },
          onError: error => {
            notify(`There was an error while deleting line item(s): ${error}`, {
              type: 'error',
            });
          },
        }
      );
    } else {
      setInvoiceData({ ...invoiceData, invoices: invoiceList });
      onCommitLineItemChanges({ ...invoiceData, invoices: invoiceList });

      toggleConfirmDeletionOpen();
      notify('Line item(s) deleted successfully.', { type: 'success' });
    }
  }

  function commitCellChanges(oldRow, newRow) {
    const invoiceList = invoiceData.invoices.map(invoice => ({
      ...invoice,
      line_items: invoice.line_items.map(lineItem => {
        const isTargetItem =
          lineItem.id === newRow.id && invoice.id === newRow.claim_invoice_id;
        const itemToUpdate = isTargetItem ? newRow : lineItem;
        itemToUpdate.has_incorrect_policy_period = !isDateWithinRange(
          itemToUpdate.loss_date,
          policyData?.start_date || wellnessData?.start_date,
          policyData?.end_date || wellnessData?.end_date
        );
        return itemToUpdate;
      }),
    }));

    setInvoiceData({ ...invoiceData, invoices: invoiceList });
    onCommitLineItemChanges({ ...invoiceData, invoices: invoiceList });

    return newRow;
  }

  function handleExpandAccordion(isExpanded, id) {
    if (isExpanded) {
      setExpandedList([...expandedList, id]);
    } else {
      setExpandedList(expandedList.filter(item => item !== id));
    }
  }

  function expandAll() {
    setExpandedList(invoiceData?.invoices?.map(invoice => invoice.id));
  }

  function collapseAll() {
    setExpandedList([]);
  }

  function handleCellEditStart(params) {
    setLastEditCell({
      id: params.id,
      field: params.field,
      claimInvoiceId: params.row.claim_invoice_id,
    });
  }

  useEffect(() => {
    if (newLineItem.id) {
      const apiRef = apiRefs.find(
        ref => ref.invoiceId === newLineItem.claim_invoice_id
      ).apiRef;

      apiRef.current.startCellEditMode({
        id: newLineItem.id,
        field: 'loss_date',
      });
      setLastEditCell({
        id: newLineItem.id,
        field: 'loss_date',
        claimInvoiceId: newLineItem.claim_invoice_id,
      });
    }
  }, [newLineItem]);

  return (
    <ClaimLineItemAutomation
      expandAll={expandAll}
      collapseAll={collapseAll}
      invoiceData={invoiceData}
      expandedList={expandedList}
      onEditInvoiceClick={onEditInvoiceClick}
      handleExpandAccordion={handleExpandAccordion}
      handleAddLineItem={handleAddLineItem}
      setCheckedRows={setCheckedRows}
      checkedRows={checkedRows}
      commitCellChanges={commitCellChanges}
      isConfirmDeletionOpen={isConfirmDeletionOpen}
      isDeleting={isDeleting}
      handleDeleteLineItem={handleDeleteLineItem}
      toggleConfirmDeletionOpen={toggleConfirmDeletionOpen}
      renderMoneyInput={renderMoneyInput}
      onLineItemDeleteClick={onLineItemDeleteClick}
      onAddInvoiceClick={onAddInvoiceClick}
      onDownloadInvoiceClick={onDownloadInvoiceClick}
      resetBulkEditSelection={resetBulkEditSelection}
      handleBulkEditClick={handleBulkEditClick}
      setBulkChanges={setBulkChanges}
      bulkChanges={bulkChanges}
      onBulkDeleteClick={onBulkDeleteClick}
      apiRefs={apiRefs}
      handleCellClick={handleCellClick}
      currentOpsUserData={currentOpsUserData}
      claimRecord={claimRecord}
      handleCellEditStart={handleCellEditStart}
      handleSendToOcr={handleSendToOcr}
      progressInvoiceStatus={progressInvoiceStatus}
      petData={petData}
    />
  );
}

ClaimLineItemAutomationController.propTypes = {
  onEditInvoiceClick: PropTypes.func.isRequired,
  lineItemsByInvoiceData: PropTypes.any.isRequired,
  onCommitLineItemChanges: PropTypes.func.isRequired,
  renderMoneyInput: PropTypes.func.isRequired,
  petData: PropTypes.any,
  policyData: PropTypes.object,
  wellnessData: PropTypes.object,
  currentOpsUserData: PropTypes.any.isRequired,
  toggleOutsidePolicyPeriod: PropTypes.func,
};

export default ClaimLineItemAutomationController;
