import { Button, CreateButton, TopToolbar } from 'react-admin';
import Add from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import QuoteFilter from './quote-filter';

function QuoteListCustomActions({ data, onClick }) {
  const { punks1012SawWork } = useFlags();
  return (
    <Box width='100%'>
      <TopToolbar style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <QuoteFilter />
        {punks1012SawWork ? (
          <Button variant='contained' size='medium' onClick={() => onClick()}>
            <div
              style={{
                display: 'flex',
                gap: '6px',
                alignItems: 'center',
                fontSize: '14px',
              }}
            >
              <Add /> Create
            </div>
          </Button>
        ) : (
          <CreateButton data={data} />
        )}
      </TopToolbar>
    </Box>
  );
}

QuoteListCustomActions.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default QuoteListCustomActions;
