import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import ContentCreate from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { linkToRecord } from 'ra-core';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const EditButton = ({
  basePath = '',
  label = 'ra.action.edit',
  record = {},
  icon = <ContentCreate />,
  state = {},
  cbAddToState = null,
  customPath,
}) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: customPath || linkToRecord(basePath, record.id),
        state: cbAddToState ? cbAddToState(state, record) : state,
      }}
      label={label}
      onClick={stopPropagation}
    >
      {icon}
    </Button>
  );
};

EditButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
  state: PropTypes.object,
  cbAddToState: PropTypes.func,
  customPath: PropTypes.string,
};

export default memo(EditButton, (props, nextProps) => {
  return (
    props.translate !== nextProps.translate ||
    (props.record && nextProps.record && props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
  );
});
