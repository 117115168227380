import { formatCurrency, getNumbersFromString } from './currencyUtils';
import { STATUSES } from './PetPlanUtils';

export function petHasValidPlan(pet) {
  const plans = pet.plans;
  return (
    plans &&
    plans.length > 0 &&
    plans.filter(plan =>
      [STATUSES.ACTIVE, STATUSES.RENEW_PENDING].includes(plan.status)
    ).length > 0
  );
}

export function petHasValidPlanForAddingPrevent(pet) {
  const plans = pet.plans;
  return (
    plans &&
    plans.length > 0 &&
    plans.filter(plan =>
      [
        STATUSES.ACTIVE,
        STATUSES.RENEW_PENDING,
        STATUSES.CANCEL_PENDING,
        STATUSES.PENDING,
      ].includes(plan.status)
    ).length > 0
  );
}

export function shouldShowWellness(pet) {
  const plans = pet.plans;
  const hasValidPlan =
    plans && plans.length > 0 && plans.some(plan => plan.status === STATUSES.ACTIVE);

  return !hasValidPlan;
}

export function petHasValidPolicy(pet) {
  const policy = pet.policies;

  return (
    policy &&
    policy.length > 0 &&
    policy.filter(policy =>
      [STATUSES.ACTIVE, STATUSES.RENEW_PENDING].includes(policy.status)
    ).length > 0
  );
}

export function checkCancellation(array) {
  if (array && array.length > 0) {
    return array.some(item => {
      const statusCheck = [STATUSES.CANCELLED, STATUSES.CANCEL_PENDING].includes(
        item.status
      );
      let changeTypeCheck = true;
      if (item.change_type) {
        changeTypeCheck = item.change_type.toLowerCase() !== 'cancel/rewrite';
      }
      return statusCheck && changeTypeCheck;
    });
  }

  return false;
}

export function checkPolicyCancellation(policies) {
  if (policies && policies.length > 0) {
    return policies.some(item => {
      const statusCheck = [STATUSES.CANCELLED, STATUSES.DECLINED].includes(
        item.status
      );
      return statusCheck;
    });
  }

  return true;
}

export function checkWellnessCancellation(wellness) {
  if (wellness) {
    const statusCheck = [STATUSES.CANCELLED, STATUSES.CANCEL_PENDING].includes(
      wellness.status
    );
    return statusCheck;
  }

  return false;
}

export function petIsWellnessOnly(pet) {
  return pet?.wellness && !(pet?.policies?.length || pet?.latest_pet_policy);
}

export function petIsWellnessAndInsurance(pet) {
  return pet?.wellness && pet?.policies?.length;
}

export function getProductType(pet) {
  let product = null;
  if (pet?.policies?.length) {
    product = 'Insurance';
    if (petHasValidPlan(pet)) {
      product += ' + Prevent';
    } else if (pet?.wellness) {
      product += ' + Wellness';
    }
  } else if (pet?.wellness) {
    product = 'Wellness';
  }
  return product;
}

export function getPetInfo(pet, status) {
  const policy = getObjectByStatus(pet, 'policies', status);
  const plan = getObjectByStatus(pet, 'plans', status);
  return buildSummaryPet(pet, policy, plan);
}

export function getTotal(pets) {
  let total = 0;

  for (let pet in pets) {
    if (pets[pet].subTotal) {
      total += pets[pet].subTotal;
    }
  }

  return total;
}

export function getFullPercentage(value) {
  let calc;
  if (value) {
    if (!value.startsWith('.')) {
      calc = Math.abs((getNumbersFromString(value) / 100) * 100 - 100);
    } else {
      calc = Math.abs(value * 100 - 100);
    }
    return `${calc}%`;
  }
  return null;
}

export function getStringAsCurrency(value) {
  if (value) {
    return formatCurrency(getNumbersFromString(value));
  }
  return null;
}

export function shouldShowButton(pet, status) {
  const obj = getObjectByStatus(pet, 'policies', status);
  return !!obj.id;
}

export function getWebDescription(summaryPet) {
  if (summaryPet?.plan?.plan?.web_description) {
    return JSON.parse(summaryPet.plan.plan.web_description);
  }
  return null;
}

export function getTransactionFee(record) {
  let feeAsDecimal = 2.0;
  if (record?.transaction_fee) {
    feeAsDecimal = record.transaction_fee / 100;
    feeAsDecimal.toFixed(2);
  }
  return feeAsDecimal;
}

export function getToggleStyle(bool) {
  return bool ? 'contained' : 'outlined';
}

export function getSummaryPetToggleStyle(pet, status) {
  return getToggleStyle(pet?.policy?.status === status);
}

function buildSummaryPet(pet, policy, plan) {
  let subTotal = 0;

  if (policy?.premium) {
    if (policy.is_annualized_policy) {
      policy.premiumAnnual = policy.premium * 12;
    }
    subTotal += policy.premiumAnnual || policy.premium;
  }

  if (plan?.plan?.cost) {
    if (plan.is_annualized_pet_plan) {
      plan.plan.costAnnual = plan.plan.cost * 12;
    }
    subTotal += plan.plan.costAnnual || plan.plan.cost;
  }

  return {
    policy,
    plan,
    subTotal,
  };
}

export function getObjectByStatus(obj, target, status) {
  return obj[target].filter(item => item.status === status)[0] || {};
}

export function appendBreedName(pet, key = 'breed_name') {
  if (!pet[key] && pet.breed_code) {
    pet[key] = decodeURIComponent(pet.breed_code.split('~')[2]);
  } else if (!pet[key]) {
    pet[key] = 'N/A';
  }

  return pet;
}
