import { useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../shared/utils';

const sx = {
  container: { padding: '0 0 !important', margin: '16px 32px', width: 'unset' },
  topGrid: { margin: '16px auto 0', gap: '8px', width: '343px' },
  leverGrid: {
    '& > :first-of-type': { borderRadius: '8px 0 0 8px' },
    '& > :not(:first-of-type):not(:last-child)': { borderRadius: '0' },
    '& > :last-child': { borderRadius: '0 8px 8px 0' },
  },
  optionsGrid: { rowGap: '16px', marginTop: '16px' },
  optionGrid: {
    border: '1px solid  #0000001F',
    borderRadius: '8px',
    padding: '16px',
    gap: '40px',
    width: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  subtitle: { fontSize: '12px', marginTop: '8px' },
  button: { minWidth: '92px', height: '42px' },
};

function InsurancePicker(props) {
  const { onChange, onRemove, policiesData } = props;
  const { fer4782CoinsuranceRatePicker } = useFlags();

  // Extract unique policy-lever values
  const uniqueDeductibles = [
    ...new Set(policiesData?.map(option => option.deductible)),
  ];
  const uniqueCoinsurances = [
    ...new Set(policiesData?.map(option => option.coinsurance)),
  ];
  const firstDeductible = String(policiesData[0]?.deductible);

  const [selectedDeductible, setSelectedDeductible] = useState(firstDeductible);
  const [selectedCoinsurance, setSelectedCoinsurance] = useState('10');
  const [selectedOption, setSelectedOption] = useState({});

  const [optionsList, setOptionsList] = useState(
    policiesData?.filter(
      option =>
        option.deductible === firstDeductible &&
        option.coinsurance === selectedCoinsurance
    )
  );

  function handleDeductibleSelection(deductible) {
    const list = policiesData?.filter(
      option =>
        option.deductible === deductible &&
        option.coinsurance === selectedCoinsurance
    );
    setOptionsList(list);
    setSelectedDeductible(deductible);
    setSelectedOption({});
    onRemove();
  }

  function handleCoinsuranceSelection(coinsurance) {
    setOptionsList(
      policiesData?.filter(
        option =>
          option.deductible === selectedDeductible &&
          option.coinsurance === coinsurance
      )
    );
    setSelectedCoinsurance(coinsurance);
    setSelectedOption({});
    onRemove();
  }

  function handleOptionSelect(option) {
    setSelectedOption(option);
    onChange(option);
  }

  function handleOnRemove() {
    setSelectedOption({});
    onRemove();
  }

  return (
    <Container sx={sx.container}>
      {fer4782CoinsuranceRatePicker ? (
        <Grid container direction='row' alignItems='center' sx={sx.topGrid}>
          <Grid item>
            <Typography>Reimbursement Rate</Typography>
          </Grid>

          <Grid item sx={sx.leverGrid}>
            {uniqueCoinsurances?.map(coinsurance => (
              <Button
                variant={
                  selectedCoinsurance === coinsurance ? 'contained' : 'outlined'
                }
                key={coinsurance}
                onClick={() => handleCoinsuranceSelection(coinsurance)}
                data-testid='coinsurance-btn'
              >
                {`${100 - Number(coinsurance)}%`}
              </Button>
            ))}
          </Grid>
        </Grid>
      ) : null}

      <Grid container direction='row' alignItems='center' sx={sx.topGrid}>
        <Grid item>
          <Typography>Deductible</Typography>
        </Grid>

        <Grid item sx={sx.leverGrid}>
          {uniqueDeductibles.map(deductible => (
            <Button
              variant={selectedDeductible === deductible ? 'contained' : 'outlined'}
              key={deductible}
              onClick={() => handleDeductibleSelection(deductible)}
              data-testid='deductible-btn'
            >
              {deductible}
            </Button>
          ))}
        </Grid>
      </Grid>

      <div>
        <Grid
          container={true}
          direction='column'
          justifyContent='flex-start'
          sx={sx.optionsGrid}
        >
          {optionsList?.map(item => {
            const isActive = item?.annual_limit === selectedOption.annual_limit;
            return (
              <Grid
                item
                justifyContent='space-between'
                display='flex'
                data-testid='insurance-option'
                sx={sx.optionGrid}
                key={`${item.annual_limit}-${item.deductible}-${item.coinsurance}`}
              >
                {fer4782CoinsuranceRatePicker ? (
                  <div>
                    <Typography>{`${100 - Number(item.coinsurance)}%`}</Typography>
                    <Typography sx={sx.subtitle}>REIMBURSEMENT RATE</Typography>
                  </div>
                ) : null}

                <div>
                  <Typography>
                    {formatCurrency(item.deductible, { hideZero: true })}
                  </Typography>
                  <Typography sx={sx.subtitle}>DEDUCTIBLE</Typography>
                </div>
                <div>
                  <Typography>
                    {item.annual_limit === 'Unlimited'
                      ? 'Unlimited'
                      : `$${item.annual_limit / 1000}K`}
                  </Typography>
                  <Typography sx={sx.subtitle}>ANNUAL LIMIT</Typography>
                </div>
                <div>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {formatCurrency(item.rate, { hideZero: false })}
                  </Typography>
                  <Typography sx={sx.subtitle}>MONTHLY</Typography>
                </div>
                <div>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {formatCurrency(item.rate * 12, { hideZero: false })}
                  </Typography>
                  <Typography sx={sx.subtitle}>ANNUALLY</Typography>
                </div>

                <Button
                  variant={item === selectedOption ? 'contained' : 'outlined'}
                  data-testid='select-plan-btn'
                  onClick={() =>
                    isActive && selectedOption
                      ? handleOnRemove()
                      : handleOptionSelect(item)
                  }
                  sx={sx.button}
                >
                  {item === selectedOption ? 'Remove' : 'Add'}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
}

InsurancePicker.defaultProps = {
  onChange: () => {},
  onRemove: () => {},
  policiesData: [],
};

InsurancePicker.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  policiesData: PropTypes.arrayOf(
    PropTypes.shape({
      annual_limit: PropTypes.string,
      coinsurance: PropTypes.string,
      deductible: PropTypes.string,
      rate: PropTypes.number,
    })
  ),
};

export default InsurancePicker;
