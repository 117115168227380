import { Typography } from '@mui/material';
import _ from 'lodash';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import { bundleItemListStyle } from './bundle-item-list-style';
export default function BundleItemList({ bundleItems = [], preventProductsData }) {
  const classes = bundleItemListStyle();

  return bundleItems
    .sort((a, b) => a.ordinal - b.ordinal)
    .map(bundle => {
      return (
        <div key={bundle.id} className={classes.bundleItemDiv}>
          <div className={classes.bundleLineItem}>
            <Typography className={classes.bundleLineItemTitle}>
              Prevent Product
            </Typography>
            <Typography className={classes.bundleLineItemValue}>
              {
                preventProductsData.find(
                  product => product.id === bundle.prevent_product_id
                )?.name
              }
            </Typography>
          </div>

          <div className={classes.bundleLineItem}>
            <Typography className={classes.bundleLineItemTitle}>Quantity</Typography>
            <Typography className={classes.bundleLineItemValue}>
              {bundle.quantity}
            </Typography>
          </div>

          <div className={classes.bundleLineItem}>
            <Typography className={classes.bundleLineItemTitle}>
              Approved Amount
            </Typography>
            <Typography className={classes.bundleLineItemValue}>
              {formatCurrency(bundle.approved_amount, { areCents: true })}
            </Typography>
          </div>

          <div>
            <Typography className={classes.bundleLineItemTitle}>
              Included in Prevent Plan
            </Typography>
            <Typography className={classes.bundleLineItemValue}>
              {bundle.is_included ? 'Yes' : 'No'}
            </Typography>
          </div>
        </div>
      );
    });
}
