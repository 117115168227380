import { useState } from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination,
  SingleFieldList,
  TextField,
  useListContext,
  WithListContext,
} from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PERMISSION_EDIT_QUOTE } from '../../../../auth/permissions';
import EditButton from '../../../../components/EditButton';
import RestrictedAction from '../../../../components/security/RestrictedAction';
import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../../../../constants/quoteFlow';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import {
  getPlan,
  getPolicyOptions,
  getQuoteDetail,
} from '../service/quoteFlowService';
import { transformPolicyOptions } from '../utils/transformPolicyOptions';
import CreateQuoteModal from './create-quote-modal';
import PlanDetailModal from './PlanDetailModal';
import QuoteListCustomActions from './quote-list-custom-actions';

function DatagridWrapper({ setRecordData, setShowDialog, ...props }) {
  let { punks1012SawWork } = useFlags();
  const { isLoading } = useListContext();

  function onViewClick(record) {
    setRecordData(null);
    setShowDialog(true);

    getQuoteDetail(record.id).then(response => {
      const quoteCopy = { ...response.data };
      let countRetrieve = 0;
      if (quoteCopy.quote_pets.length > 0) {
        quoteCopy.quote_pets.forEach(quotePet => {
          return getPolicyOptions(quoteCopy.id, quotePet.id)
            .then(policyOptionsResponse => {
              quotePet._options = transformPolicyOptions(policyOptionsResponse);
            })
            .then(() => {
              const coinsurance = quotePet.coinsurance || '10';
              if (quotePet.annual_limit && quotePet.deductible) {
                quotePet.totalPrice =
                  quotePet._options.prices[coinsurance][quotePet.annual_limit][
                    quotePet.deductible
                  ];
              }

              const petAgeInMonths =
                quotePet.age === UP_TO_5_MONTHS
                  ? 5
                  : quotePet.age === UP_TO_11_MONTHS
                    ? 11
                    : parseInt(quotePet.age) * 12;

              getPlan(quotePet.species, petAgeInMonths, quoteCopy.vet).then(
                planResponse => {
                  quotePet.plan = planResponse[0];

                  quotePet.totalPrice = quotePet.has_prevent
                    ? quotePet.totalPrice + planResponse[0].cost
                    : quotePet.totalPrice;

                  quotePet.preventDescription = planResponse[0]
                    ? JSON.parse(
                        planResponse[0].web_description
                      ).pickerModule.planDetails.map(detail => (
                        <Typography style={{ fontSize: '12px' }} key={detail}>
                          {detail}
                        </Typography>
                      ))
                    : [];

                  countRetrieve++;
                  if (countRetrieve === quoteCopy.quote_pets.length) {
                    setRecordData(quoteCopy);
                  }
                }
              );
            });
        });
      } else {
        setRecordData(quoteCopy);
      }
    });
  }

  return isLoading ? (
    <div style={{ textAlign: 'center', paddingTop: '12px' }}>
      <CircularProgress />
    </div>
  ) : (
    <Datagrid bulkActionButtons={false} {...props}>
      <TextField label='FIRST NAME' source='first_name' />
      <TextField label='LAST NAME' source='last_name' />
      <TextField label='EMAIL' source='email' />
      <ArrayField label='PETS' source='quote_pets' sortable={false} link={false}>
        {punks1012SawWork ? (
          <WithListContext
            render={({ data }) => {
              return (
                <ul style={{ padding: 0 }}>
                  {data.map(quote_pet => (
                    <div
                      key={quote_pet.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      <ChipField
                        record={quote_pet}
                        source='name'
                        key={quote_pet.id}
                      />
                      <Typography
                        style={{ fontSize: '12px', alignContent: 'center' }}
                      >
                        {quote_pet.product}
                      </Typography>
                    </div>
                  ))}
                </ul>
              );
            }}
          />
        ) : (
          <SingleFieldList linkType={false}>
            <ChipField source='name' />
          </SingleFieldList>
        )}
      </ArrayField>
      <DateField
        options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        label='CREATED ON'
        source='created_at'
      />
      <FunctionField
        label='CREATED BY'
        render={(record = {}) => {
          return record.created_by ? (
            <>
              <Typography style={{ fontSize: '10px' }}>AGENT</Typography>
              <Typography>{record.created_by}</Typography>
            </>
          ) : (
            <Typography>Owner</Typography>
          );
        }}
      />
      <FunctionField
        label='EDITED BY'
        render={(record = {}) => {
          return record.updated_by ? (
            <>
              <Typography style={{ fontSize: '10px' }}>AGENT</Typography>
              <Typography>{record.updated_by}</Typography>
            </>
          ) : (
            <Typography>Owner</Typography>
          );
        }}
      />
      <FunctionField
        label='CONVERSION'
        render={(record = {}) => {
          return record.converted === true ? (
            <Typography style={{ color: '#028E10', textAlign: 'center' }}>
              YES
            </Typography>
          ) : (
            <Typography style={{ color: '#B53F3F', textAlign: 'center' }}>
              NO
            </Typography>
          );
        }}
      />
      {!punks1012SawWork ? (
        <FunctionField
          label=''
          render={(record = {}) => {
            return (
              <IconButton onClick={() => onViewClick(record)} size='large'>
                <VisibilityIcon />
              </IconButton>
            );
          }}
        />
      ) : null}
      <RestrictedAction requiredPermissions={PERMISSION_EDIT_QUOTE}>
        <FunctionField
          label=''
          render={(record = {}) => {
            return record.product === 'insurance' || !punks1012SawWork ? (
              <EditButton customPath={`/${RoutePaths.quotes}/${record.id}`} />
            ) : null;
          }}
        />
      </RestrictedAction>
    </Datagrid>
  );
}

function QuoteList() {
  const [showDialog, setShowDialog] = useState(false);
  const [recordData, setRecordData] = useState();
  const [isCreateModalOpen, toggleIsCreateModalOpen] = useBooleanInput(false);

  return (
    <List
      actions={<QuoteListCustomActions onClick={toggleIsCreateModalOpen} />}
      exporter={false}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
    >
      <>
        <DatagridWrapper
          setRecordData={setRecordData}
          setShowDialog={setShowDialog}
        />
        <PlanDetailModal
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          quote={recordData}
        />
        <CreateQuoteModal
          open={isCreateModalOpen}
          onClose={toggleIsCreateModalOpen}
        />
      </>
    </List>
  );
}

export default QuoteList;
