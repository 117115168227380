import makeStyles from '@mui/styles/makeStyles';

const quoteTabStyle = makeStyles(
  {
    ownerInfoCheckoutTab: {
      display: 'flex',
      width: '350px',
      justifyContent: 'space-between',
      paddingLeft: '32px',
      paddingTop: '8px',
    },
    flexDiv: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    flexDivCloseButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonsDiv: {
      paddingBottom: '24px',
      paddingLeft: '22px',
      width: '700px',
    },
    totalMonthlyRate: {
      paddingBottom: '24px',
      paddingLeft: '32px',
      width: '720px',
    },
    monthlyTransactionFee: {
      paddingBottom: '0px',
      paddingLeft: '32px',
      width: '720px',
    },
    discountApplied: {
      fontSize: '16px',
      fontWeight: '600',
      paddingLeft: '32px',
      paddingBottom: '16px',
    },
    textFontSize: {
      fontSize: '12px',
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: '600',
    },
    linkText: {
      fontSize: '14px',
    },
  },
  { name: 'quote-tab' }
);

export { quoteTabStyle };
