import makeStyles from '@mui/styles/makeStyles';

const petListStyle = makeStyles(
  {
    fontStyle: {
      fontSize: '14px',
      fontWeight: '700',
    },
    renewalToday: {
      color: '#4863A0',
    },
    renewalUpcoming: {
      color: '#FFA500',
    },
    cancelledStatus: {
      color: '#FF6E6B',
      fontSize: '14px',
      fontWeight: '700',
    },
  },
  { name: 'pet-list' }
);

const sxPetListStyle = {
  headerCell: {
    fontSize: '8px',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0px 16px 0px 0px',
    borderBottom: '0px',
  },
  table: {
    borderBottom: '0px',
  },
  rowCell: {
    borderBottom: '0px',
    padding: '0px 16px 8px 0px',
    fontSize: '14px',
    fontWeight: '700',
  },
  labelCell: {
    color: '#6B717E',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  petDetailHeader: {
    color: '#6B717E',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
};

export { petListStyle, sxPetListStyle };
