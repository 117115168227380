import { SelectInput, SimpleForm, TextInput } from 'react-admin';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import { flattenObj } from '@pumpkincare/shared';

import { MGA_DOCUMENT_TYPES_UPLOAD_CHOICES } from '../../../../constants/documents';

const useStyles = makeStyles(
  {
    inlineBlock: {
      display: 'inline-flex',
      marginRight: '20px',
      width: '180px',
    },
  },
  { name: 'customer-documents-v2-filter' }
);

function CustomerDocumentsV2Filter({ filters, onChange }) {
  const classes = useStyles();

  function buildFilter(name, value) {
    if (value === '') {
      const key = name.includes('.') ? name.substring(0, name.indexOf('.')) : name;
      let { [key]: _, ...rest } = filters;
      onChange(rest);
      return;
    }

    const criteria = _.set({}, name, value);
    const payload = {
      ...filters,
      ...criteria,
    };
    onChange(flattenObj(payload));
  }

  const debounceBuildFilter = _.debounce((name, value) => {
    buildFilter(name, value.trim());
  }, 500);

  return (
    <SimpleForm submitOnEnter={false} toolbar={null} onSubmit={null}>
      <Box display={{ sm: 'flex' }} width='800px' justifyContent='space-between'>
        <Box width='180px'>
          <SelectInput
            label='Document Type'
            source='type'
            allowEmpty={true}
            choices={MGA_DOCUMENT_TYPES_UPLOAD_CHOICES}
            onChange={event => {
              buildFilter(event.target.name, event.target.value);
            }}
          />
        </Box>
        <Box width='180px'>
          <TextInput
            label='Claim Id'
            source='claim_id'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
          />
        </Box>
        <Box width='180px'>
          <TextInput
            label='Date Uploaded'
            source='created_at'
            helperText='yyyy-mm-dd'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
            formClassName={classes.inlineBlock}
          />
        </Box>
        <Box width='180px'>
          <TextInput
            label='Pet Name'
            source='pet_name'
            onChange={event => {
              debounceBuildFilter(event.target.name, event.target.value);
            }}
            formClassName={classes.inlineBlock}
          />
        </Box>
      </Box>
    </SimpleForm>
  );
}

export default CustomerDocumentsV2Filter;
