import React from 'react';
import { Typography } from '@mui/material';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';

function QuoteAddOnCard({ addon }) {
  const descriptionFontSize = { fontSize: '12px' };
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '16px',
        }}
      >
        <Typography style={{ fontWeight: '600', fontSize: '12px' }}>
          {addon.name}
        </Typography>
        <Typography style={{ fontWeight: '600' }}>
          {formatCurrency(addon.cost)}
        </Typography>
      </div>

      {addon.installments > 1 ? (
        <Typography style={{ fontSize: '12px', color: '#2196F3' }}>
          Monthly Payment for 1 year
        </Typography>
      ) : null}

      <Typography style={descriptionFontSize}>
        Screens for 200+ health risks
      </Typography>
      <Typography style={descriptionFontSize}>Screens for 350+ breeds</Typography>
      <Typography style={descriptionFontSize}>
        Backed by sound research from Cornell
      </Typography>
      <Typography style={descriptionFontSize}>
        Actionable results for vets
      </Typography>
    </>
  );
}

export default QuoteAddOnCard;
