import { useRedirect } from 'react-admin';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PetsIcon from '@mui/icons-material/Pets';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { getPetPolicyPlanDetail } from '@pumpkincare/claims';

import { parseAndFormatDate } from '../../../../shared/utils';
import { appendBreedName } from '../../../../shared/utils/petUtils';
import { GENDER_MAP, SPECIES_MAP } from '../../constants/data-maps';
import ClaimDetailCard from '../claim-detail-card';
import PlanPolicyDetailCard from '../plan-policy-detail-card';

export default function ClaimPetInformation({ petData, petPlanData }) {
  const { punks1012SawWork } = useFlags();

  const policyPlanDetail = getPetPolicyPlanDetail(
    petData,
    petPlanData?.version_number
  );

  const redirect = useRedirect();

  const genderDetail = petData.gender ? ` | ${GENDER_MAP[petData.gender]}` : '';
  const detail = `${SPECIES_MAP[petData.species]} | ${
    appendBreedName(petData, 'breed').breed
  } | Age ${petData.age}${genderDetail}`;
  const policy = petData.latest_pet_policy;
  const plan = petData.latest_pet_plan;
  const wellness = petData.wellness;

  const [underwriter, version, state] = policy?.program_id?.split('-') || [];
  const [tier, species] = wellness?.tier.split('.') || [];

  return (
    <div style={{ display: 'flex' }}>
      <ClaimDetailCard
        icon={
          <IconButton
            onClick={() => redirect(`/pets/${petData.id}/show`)}
            style={{ padding: '0px' }}
            size='large'
          >
            <PetsIcon style={{ width: '35px', height: '35px', color: '#007DFF' }} />
          </IconButton>
        }
        title={`${petData.name}`}
        detail={detail}
      />

      {punks1012SawWork && policy ? (
        <PlanPolicyDetailCard
          title={`INSURANCE ${state} (${underwriter?.toUpperCase()}-${version})`}
          effectiveDatePeriod={`Policy Effective Dates: ${parseAndFormatDate(
            policy.policy_effective_date
          )} - ${parseAndFormatDate(policy.policy_end_date)}`}
          enrollmentDate={parseAndFormatDate(policy.coverage_inception_date)}
          coverageStartDate={parseAndFormatDate(
            policy.policy_illness_coverage_start_date
          )}
          accidentStartDate={parseAndFormatDate(
            policy.policy_accident_coverage_start_date
          )}
          isPolicy
        />
      ) : null}

      {punks1012SawWork && plan && petPlanData ? (
        <PlanPolicyDetailCard
          title={`PREVENTIVE ESSENTIALS (${petPlanData.version_number})`}
          effectiveDatePeriod={`Plan Effective Dates: ${parseAndFormatDate(
            plan.plan_effective_date
          )} - ${parseAndFormatDate(plan.plan_end_date)}`}
          enrollmentDate={parseAndFormatDate(petData.pet_plan_enrollment_date)}
          coverageStartDate={parseAndFormatDate(
            petData.pet_plan_coverage_start_date
          )}
          isPolicy={false}
        />
      ) : null}

      {punks1012SawWork && wellness ? (
        <PlanPolicyDetailCard
          title={`PUMPKIN WELLNESS CLUB (${species.toUpperCase()} ${tier.toUpperCase()} PLAN)`}
          effectiveDatePeriod={`Effective Dates: ${parseAndFormatDate(
            wellness.start_date
          )} - ${parseAndFormatDate(wellness.end_date)}`}
          enrollmentDate={parseAndFormatDate(wellness.coverage_start_date)}
          isPolicy={false}
        />
      ) : null}

      {!punks1012SawWork ? (
        <ClaimDetailCard
          icon={
            !punks1012SawWork ? (
              <ListAltIcon
                style={{ width: '35px', height: '35px', color: '#007DFF' }}
              />
            ) : null
          }
          title={policyPlanDetail.status}
          detail={policyPlanDetail.detail}
        />
      ) : null}
    </div>
  );
}

ClaimPetInformation.propTypes = {
  petData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    species: PropTypes.string,
    breed: PropTypes.string,
    age: PropTypes.string,
    gender: PropTypes.string,
    latest_pet_policy: PropTypes.object,
    latest_pet_plan: PropTypes.object,
    wellness: PropTypes.object,
    pet_plan_enrollment_date: PropTypes.string,
    pet_plan_coverage_start_date: PropTypes.string,
  }),
  petPlanData: PropTypes.object,
};
