import {
  ArrayField,
  CreateButton,
  Datagrid,
  DateField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { PERMISSION_START_REFUND } from '../../../auth/permissions';
import InvoiceItems from '../../../components/InvoiceItems';
import { MoneyField } from '../../../components/MoneyField';
import RestrictedAction from '../../../components/security/RestrictedAction';
import RoutePaths from '../../../routes';

const CustomerInvoiceShow = props => {
  const record = useRecordContext();
  const copyProps = { ...props };
  copyProps.record = record;

  const refunds = [...record.refunds];

  const items = [...record.items];

  const showWarning = props.showWarning;

  const itemsArray = [];

  let refundListValue = [];
  let refundDateList = [];

  let totalRefundToDate = 0;

  items.forEach(item => {
    refundListValue = [];
    refundDateList = [];
    totalRefundToDate = 0;

    if (refunds && refunds.length > 0) {
      refunds.forEach((refund, refundIndex) => {
        if (refund.refund_items && refund.refund_items.length > 0) {
          refund.refund_items.forEach(refundItem => {
            if (refundItem.invoice_item_id === item.id) {
              refundListValue.push({
                value: refundItem.refund_amount,
                id: refundItem.invoice_item_id + refundIndex,
              });

              totalRefundToDate += refundItem.refund_amount;

              refundDateList.push({
                date: refund.created_at,
                id: refundItem.invoice_item_id + refundIndex,
              });
            }
          });
        }
      });
      itemsArray.push({
        ...item,
        refund_amount: refundListValue,
        refund_date: refundDateList,
        total_refund_to_date: totalRefundToDate,
      });
    }
  });

  copyProps.record.items = itemsArray.length > 0 ? itemsArray : items;

  return (
    <SimpleShowLayout {...copyProps}>
      <InvoiceItems source='items'>
        <Datagrid
          sx={{ '& .RaDatagrid-tableWrapper': { width: props?.sx || '100%' } }}
          bulkActionButtons={false}
        >
          <TextField source='type' />
          <TextField source='description' />

          <MoneyField source='amount' />

          <ArrayField source='refund_date'>
            <SingleFieldList linkType={null} style={{ display: 'grid' }}>
              <DateField showTime source='date' />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source='refund_amount'>
            <SingleFieldList linkType={null} style={{ display: 'grid' }}>
              <MoneyField style={{ color: 'red' }} source='value' />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </InvoiceItems>

      <div style={{ display: 'flex' }}>
        <RestrictedAction requiredPermissions={PERMISSION_START_REFUND}>
          <div
            style={{
              width: '100%',
              textAlign: 'end',
              paddingTop: '22px',
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            {showWarning ? (
              <div>
                <Typography style={{ paddingLeft: '16px', color: '#FF0000' }}>
                  If this refund is due to a cancellation, check the&nbsp;
                  <Link
                    to={{ pathname: `/${RoutePaths.cancellationsDashboard}` }}
                    className={{ cursor: 'pointer' }}
                    style={{
                      textDecoration: 'none',
                      paddingRight: '16px',
                      fontSize: '1rem',
                    }}
                  >
                    cancellations dashboard.
                  </Link>
                </Typography>
              </div>
            ) : null}

            <CreateButton
              label='Start Refund'
              icon={null}
              resource={RoutePaths.refunds}
              state={{
                ...copyProps,
                refundsRedirect: copyProps.refundsRedirect,
                showCancellationLink: props.showCancellationLink ? true : undefined,
              }}
              sx={{ border: '1px solid' }}
            />
          </div>
        </RestrictedAction>
      </div>
    </SimpleShowLayout>
  );
};

export default CustomerInvoiceShow;
