import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  maxLength,
} from 'react-admin';

import { invoiceNotesMaxLength } from '../../constants/invoiceNotes';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label='Update Invoice Details' />
  </Toolbar>
);

const InvoiceEdit = props => {
  return (
    <Edit title='Update Invoice' {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput
          multiline
          label='Notes'
          source='notes'
          validate={maxLength(invoiceNotesMaxLength)}
        />
      </SimpleForm>
    </Edit>
  );
};

export default InvoiceEdit;
