import { SelectInput, TextInput } from 'react-admin';
import { Grid, InputLabel, Typography } from '@mui/material';
import { stateList } from '@pumpkincare/shared';

import { OrganizationsCreateStyles } from '../../organizations-create/organizations-create.styles';

export function OrganizationAddress({ name }) {
  const classes = OrganizationsCreateStyles();
  return (
    <>
      {name && <Typography className={classes.fieldsetLabel}>{name}</Typography>}
      <Grid container spacing={4} fullWidth>
        <Grid item xs={12} md={5}>
          <InputLabel shrink>Address Line 1</InputLabel>
          <TextInput
            variant='outlined'
            source='address_line_1'
            label={false}
            fullWidth
            className={classes.defaultHeight}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <InputLabel shrink>Address Line 2 (Optional)</InputLabel>
          <TextInput
            variant='outlined'
            source='address_line_2'
            label={false}
            fullWidth
            className={classes.defaultHeight}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} fullWidth>
        <Grid item xs={12} md={4}>
          <InputLabel shrink>City</InputLabel>
          <TextInput
            variant='outlined'
            source='city'
            label={false}
            fullWidth
            className={classes.defaultHeight}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel shrink>State</InputLabel>
          <SelectInput
            source='state'
            variant='outlined'
            choices={stateList}
            label={false}
            fullWidth
            className={classes.defaultHeight}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel shrink>Zipcode</InputLabel>
          <TextInput
            variant='outlined'
            source='zip'
            label={false}
            fullWidth
            className={classes.defaultHeight}
          />
        </Grid>
      </Grid>
    </>
  );
}
