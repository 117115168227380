import makeStyles from '@mui/styles/makeStyles';

const claimVerificationDatagridStyle = makeStyles(
  {
    hideRightSeparator: {
      '& > .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
      '& > .MuiDataGrid-columnHeaderTitleContainer': {
        paddingLeft: '0px',
      },
    },
    verificationDatagrid: {
      '& .MuiDataGrid-viewport': {
        maxHeight: 'fit-content !important',
      },
      '& .MuiDataGrid-cell': {
        maxHeight: 'fit-content !important',
        overflow: 'auto',
        whiteSpace: 'initial !important',
        lineHeight: '16px !important',
        display: 'flex !important',
        alignItems: 'center',
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
      },
      '& .MuiDataGrid-renderingZone': {
        maxHeight: 'fit-content !important',
        '& .MuiDataGrid-row': {
          maxHeight: 'fit-content !important',
          '&:nth-child(2n-1)': {
            backgroundColor: 'rgba(0, 125, 255, 0.08)',
          },
        },
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0px',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
    },
  },
  { name: 'claim-verification-datagrid' }
);

export { claimVerificationDatagridStyle };
