import { useState } from 'react';
import { Create, useCreate, useNotify, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { GENDER_MAP, useProgramResolver } from '@pumpkincare/shared';
import { usePetPolicies } from '@pumpkincare/users';

import { isCreditCardExpDateValid } from '../../../shared/utils';
import PlanSummary from '../../plans/plan-summary';
import InsurancePicker from '../insurance-picker';

function InsuranceCreate() {
  const location = useLocation();
  const { pet, user } = location?.state || {};

  const redirect = useRedirect();
  const notify = useNotify();
  const [create, { isLoading: isCreateLoading }] = useCreate();

  const {
    isLoading: isProgramResolverLoading,
    data: { rating_version: ratingVersion },
  } = useProgramResolver(
    user.billing_address.state_province,
    pet?.species,
    pet?.age
  );

  const [currentInsurance, setCurrentInsurance] = useState({});

  const { breed_name: breedName, age, gender, species, name } = pet || {};

  const { data: petPoliciesData } = usePetPolicies(pet.id);

  function handleAddProduct() {
    if (user.payment_details && !isProgramResolverLoading) {
      if (
        !isCreditCardExpDateValid(
          user.payment_details.exp_month,
          user.payment_details.exp_year
        )
      ) {
        notify('Expired payment method. Please add a new one', 'warning');
        return;
      }

      const payload = {
        deductible: currentInsurance.deductible,
        annual_limit: currentInsurance.annual_limit,
        coinsurance: currentInsurance.coinsurance,
        premium: currentInsurance.premium,
        pet_id: pet.id,
        program_id: ratingVersion,
        billing_information: {
          name_on_card: user.first_name,
          city: user.billing_address.city,
          state: user.billing_address.state_province,
          zipcode: user.billing_address.zipcode,
          street_1: user.billing_address.street_1,
          street_2: user.billing_address.street_2 || '',
          country: user.billing_address.country,
        },
      };

      create(
        `policies/add-insurance`,
        {
          data: payload,
        },
        {
          onSuccess: () => {
            redirect(`/customers/${user.id}/show`);
          },
          onError: error => {
            notify(`Error: ${error?.body?.message}`, 'error');
          },
        }
      );
    } else {
      notify('Please add a payment method', 'warning');
    }
  }

  function handleAddInsurance(insurance) {
    const { rate, ...rest } = insurance;

    setCurrentInsurance({ premium: rate, ...rest });
  }

  return (
    <Create title={'Add Insurance'}>
      <div>
        <IconButton
          onClick={() => redirect(`/customers/${user.id}/show`)}
          size='large'
          sx={{ display: 'flex', color: '#007DFF' }}
        >
          <ArrowBackIosIcon />
          <Typography
            sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
          >
            Add Insurance
          </Typography>
        </IconButton>
      </div>
      <div style={{ width: '100%' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={2}
          sx={{ padding: '16px 24px' }}
        >
          <Grid item>
            <Container
              sx={{
                border: '1px solid #0000001F',
                borderRadius: '8px',
                padding: '24px 16px !important',
                minWidth: '708px',
              }}
            >
              <Typography variant='h5' color='standard' sx={{ marginBottom: '8px' }}>
                {name}
              </Typography>

              <Typography variant='standard' sx={{ textTransform: 'capitalize' }}>
                {` ${species} • ${breedName} • Age ${age} • ${
                  GENDER_MAP[gender] || 'N/A'
                }`}
              </Typography>

              <Divider sx={{ marginTop: '24px' }} />

              {petPoliciesData ? (
                <InsurancePicker
                  onChange={handleAddInsurance}
                  onRemove={() => setCurrentInsurance({})}
                  policiesData={petPoliciesData}
                />
              ) : (
                <CircularProgress
                  size={32}
                  thickness={4}
                  sx={{ marginTop: '24px', display: 'absolute' }}
                />
              )}
            </Container>
          </Grid>

          <Grid item>
            <Typography
              variant='h6'
              sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: '500' }}
            >
              Summary
            </Typography>

            <PlanSummary
              pets={[
                {
                  ...pet,
                  policy: currentInsurance,
                },
              ]}
              hasFeeShown
              isAddingInsurance
              isChargedAnnually={user.is_charged_annually}
            />

            <Button
              sx={{
                width: '100%',
                marginTop: '16px',
                height: '40px',
              }}
              variant={'contained'}
              disabled={!currentInsurance.premium && !isCreateLoading}
              onClick={handleAddProduct}
            >
              Add Product
            </Button>
          </Grid>
        </Grid>
      </div>
    </Create>
  );
}

export default InsuranceCreate;
