import * as React from 'react';
import { Fragment } from 'react';
import Button from '@mui/material/Button';
import CancellationUpdateFormDialog from './CancellationUpdateFormDialog';
import useBooleanInput from '../../hooks/useBooleanInput';

export default function CancellationUpdateButton({ selectedIds }) {
  const [open, setOpen] = useBooleanInput(false);
  function handleClick() {
    setOpen(true);
  }

  return (
    <Fragment>
      <Button
        variant='contained'
        style={{
          color: 'white',
          backgroundColor: '#3F51B5',
        }}
        onClick={handleClick}
      >
        Update
      </Button>
      <CancellationUpdateFormDialog
        selectedIds={selectedIds}
        open={open}
        setOpen={setOpen}
        showCompleted={false}
        showReason={true}
      />
    </Fragment>
  );
}
