import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  {
    paper: {
      width: '665px',
      maxWidth: '100%',
    },
    dialogContent: {
      display: 'inline-grid',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '0',
      paddingBottom: '17px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 32px 24px 32px',
    },
    customer: {
      background: '#F8F7F3',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '16px 40px',
    },
    title: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: '500',
      color: 'black',
      letterSpacing: '0.15px',
    },
    subtitle: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      color: 'black',
      letterSpacing: '0.15px',
    },
    customerInfo: {
      display: 'flex',
      flexDirection: 'row',
      gap: '32px',
    },
    details: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      color: 'black',
      letterSpacing: '0.17px',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '22px',
      letterSpacing: '0.46px',
      color: '#1976d2',
    },
    addAPet: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginTop: '25px',
      paddingBottom: '25px',
      borderBottom: '1px solid #CDCFD3',
    },
    addAPetButtons: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
    },
    pets: {
      display: 'flex',
      flexDirection: 'column',
      gap: '25px',
      marginTop: '25px',
    },
    pet: {
      display: 'flex',
      flexDirection: 'row',
      gap: '15px',
      border: '1px solid #CDCFD3',
      padding: '16px',
      alignItems: 'flex-start',
    },
    petInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    products: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      alignItems: 'center',
    },
  },
  { name: 'existing-customer-detail-modal' }
);
