import React from 'react';
import { Datagrid } from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const LoadingDataGrid = props => {
  const { ids, empty, ...rest } = props;

  if (ids === undefined) return <CircularProgress />;
  if (ids.length === 0) return <div>{empty}</div>;

  return <Datagrid {...rest} ids={ids} />;
};

LoadingDataGrid.defaultProps = {
  empty: 'No data to show',
};

LoadingDataGrid.propTypes = {
  ids: PropTypes.array,
  empty: PropTypes.node,
};

export default LoadingDataGrid;
