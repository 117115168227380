import { useCallback } from 'react';
import {
  BooleanInput,
  Create,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import { stateList } from '../../lib/shared';
import RoutePaths from '../../routes';
import Tile from '../../shared/components/Tile';

export default function AddressCreate() {
  const location = useLocation();
  const { state } = location;
  const [update] = useUpdate();
  const [create, { isLoading }] = useCreate();

  const redirect = useRedirect();
  const notify = useNotify();

  function Title() {
    return (
      <span>
        {state.user_name} ({state.user_pumpkin_id}) - Change {state?.type} address
      </span>
    );
  }

  function CustomToolbar() {
    return (
      <Toolbar>
        <SaveButton disabled={isLoading} label={isLoading ? 'Saving' : 'Save'} />
      </Toolbar>
    );
  }
  const saveCreate = useCallback(
    async values => {
      const validatePayload = {
        street_1: values.street_1,
        street_2: values.street_2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
      };

      const savePayload =
        state?.type === 'mailing'
          ? validatePayload
          : {
              ...validatePayload,
              update_mailing_address: !!values.update_mailing_address,
            };

      try {
        await create(
          `addresses/${state?.type}/validate`,
          {
            data: validatePayload,
          },
          { returnPromise: true }
        ).then(a => {
          update(
            `users/${state?.user_id}/addresses/${state?.type}`,
            {
              data: savePayload,
            },
            {
              onSuccess: () => {
                redirect(`/${RoutePaths.customers}/${state?.user_id}/show`);
                notify('Address added successfully', { type: 'success' });
              },
              onError: error => {
                notify(`${error.message}`, {
                  type: 'error',
                });
              },
            }
          );
        });
      } catch (err) {
        return err?.body?.errors?.json || err?.body?.errors;
      }
    },
    [create]
  );

  let initialValues = {
    street_1: state?.street_1,
    street_2: state?.street_2,
    city: state?.city,
    state: state?.state_province,
    zipcode: state?.zipcode,
  };

  if (state?.type === 'rating') {
    initialValues = {
      ...initialValues,
      update_mailing_address: false,
    };
  }

  return (
    <Create title={<Title />}>
      <SimpleForm
        toolbar={<CustomToolbar />}
        submitOnEnter={false}
        onSubmit={saveCreate}
        sx={{ maxWidth: '512px' }}
        defaultValues={initialValues}
      >
        <Tile
          title={'Address Type'}
          content={state?.type.toUpperCase()}
          style={{ marginBottom: '24px' }}
        />
        <TextInput
          label='Street 1'
          source='street_1'
          validate={required()}
          fullWidth
        />
        <TextInput label='Street 2' source='street_2' fullWidth />
        <TextInput label='City' source='city' validate={required()} fullWidth />
        <SelectInput
          label='State/Province'
          source='state'
          choices={stateList}
          validate={required()}
          fullWidth
        />
        <TextInput
          label='Zip Code'
          type={'number'}
          source='zipcode'
          validate={required()}
          fullWidth
        />
        {state?.type === 'rating' ? (
          <BooleanInput
            label='Switch on to also update mailing address'
            source='update_mailing_address'
            fullWidth
          />
        ) : null}
      </SimpleForm>
    </Create>
  );
}
