import React from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const NumericField = ({
  prefix = '',
  precision = 0,
  allowNegativeValue = false,
  allowDecimalValue = false,
  variant = 'outlined',
  ...props
}) => {
  const startAdornment = prefix ? (
    <InputAdornment position='start'>{prefix}</InputAdornment>
  ) : null;

  const resolve = value => {
    let pattern = '[0-9]*';

    if (allowNegativeValue) {
      pattern = '^[-+]?' + pattern;
    }

    if (allowDecimalValue) {
      const max_precision = precision > 0 ? '{0,' + precision + '}' : '';
      pattern = '(' + pattern + '[.]{0,1}[0-9]' + max_precision + ')';
    }

    const match = String(value).match(new RegExp(pattern, 'g'));
    const result = match ? match[0] : '';
    return Object.is(result, -0) ? 0 : result;
  };

  const handleChange = e => {
    e.target.value = resolve(e.target.value);
    props.onChange && props.onChange(e);
  };

  return (
    <TextField
      {...props}
      variant={variant}
      onChange={handleChange}
      InputProps={{
        startAdornment: startAdornment,
      }}
    />
  );
};

export default NumericField;
