import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import {
  CardActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import useBooleanInput from '../../hooks/useBooleanInput';
import { updateCancellations } from '../../shared/services/netServices';
import CancellationEmailDetailsForm from './CancellationEmailDetailsForm';
import useStyles from './CancellationStyles';

function CancellationEmailDetailsCard({ record }) {
  const { punks1012SawWork } = useFlags();
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const [hasError, setHasError] = useState({});
  const [amounts, setAmounts] = useState({});
  const [updateDisabled, setUpdateDisabled] = useBooleanInput(true);
  const [shouldSendEmail, setShouldSendEmail] = useState(
    record.cancellation_assignments[0].should_send_confirmation_email
  );

  function handleChange(event) {
    setUpdateDisabled(false);
    setHasError({});

    const { value: input, name, id } = event.target;
    let columnName = id.replaceAll('-', '_');

    const decimalPattern = punks1012SawWork
      ? /^-?\d+(\.\d{1,2})?$/
      : '^-?[0-9]+(\\.[0-9]{1,2})?$';

    const valid = punks1012SawWork
      ? decimalPattern.test(input)
      : input.match(decimalPattern);

    const updateAmounts = {
      ...amounts,
      [name]: {
        ...amounts[name],
        [columnName]: input,
        cancellation_assignment_id: name,
        should_send_confirmation_email: shouldSendEmail,
      },
    };

    if (punks1012SawWork) {
      if (
        columnName === 'prevent_refund' ||
        columnName === 'prevent_wellness_refund'
      ) {
        updateAmounts[name]['outstanding_balance'] = '0.00';
      } else if (columnName === 'outstanding_balance') {
        updateAmounts[name]['prevent_refund'] = '0.00';
        updateAmounts[name]['prevent_wellness_refund'] = '0.00';
      }
      setAmounts(updateAmounts);

      setHasError({
        [name]: {
          [id]: !valid,
        },
      });
    } else {
      setAmounts({
        ...amounts,
        [name]: {
          ...amounts[name],
          [columnName]: input,
          ['cancellation_assignment_id']: name,
        },
      });

      setHasError({
        ...hasError,
        [name]: {
          ...hasError[name],
          [id]: !valid,
        },
      });
    }
  }

  function canUpdate() {
    let canUpdate = true;
    Object.keys(hasError).forEach(function (name) {
      Object.keys(hasError[name]).forEach(function (id) {
        if (hasError[name][id]) {
          canUpdate = false;
        }
      });
    });
    return canUpdate;
  }

  function handleUpdate() {
    let payload = punks1012SawWork ? transformAmount(amounts) : amounts;

    if (Object.keys(payload).length === 0) {
      const cancellationAssignmentId = record.cancellation_assignments[0].id;

      payload = {
        ...amounts,
        [cancellationAssignmentId]: {
          ...amounts[cancellationAssignmentId],
          ['cancellation_assignment_id']: cancellationAssignmentId,
          ['should_send_confirmation_email']: shouldSendEmail,
        },
      };
    }

    if (canUpdate()) {
      updateCancellations(Object.values(payload))
        .then(response => {
          refresh();
          setUpdateDisabled(true);
          notify('Cancellation email details updated', {});
        })
        .catch(error => {
          notify('Cancellation email details not updated', 'warning');
        });
    } else {
      notify('Fix fields with error before updating', 'warning');
    }
  }

  function transformAmount(inputObject) {
    let transformedObject = {};

    for (const key in inputObject) {
      if (Object.hasOwn(inputObject, key)) {
        const child = inputObject[key];
        let transformedChild = {
          cancellation_assignment_id: child['cancellation_assignment_id'],
          should_send_confirmation_email: child['should_send_confirmation_email'],
        };

        // Check outstanding_balance and prevent_refund conditions
        if (parseFloat(child['outstanding_balance']) > 0) {
          transformedChild['outstanding_balance'] = child['outstanding_balance'];
        }
        // Check insurance_refund conditions
        if (child['insurance_refund']) {
          transformedChild['insurance_refund'] = child['insurance_refund'];
        }
        // if prevent_refund or prevent_wellness_refund is greater than 0,
        // set outstanding_balance to negative value
        if (parseFloat(child['prevent_refund']) > 0) {
          transformedChild['outstanding_balance'] = `-${child['prevent_refund']}`;
        }

        if (parseFloat(child['prevent_wellness_refund']) > 0) {
          transformedChild['outstanding_balance'] =
            `-${child['prevent_wellness_refund']}`;
        }
        transformedObject[key] = transformedChild;
      }
    }

    return transformedObject;
  }

  function onEmailOptionChange(e) {
    setUpdateDisabled(false);
    setShouldSendEmail(e.target.value === 'true');
  }

  return (
    <div style={{ marginBottom: '32px' }}>
      {punks1012SawWork ? (
        <Typography variant='h5'>3. Send Email</Typography>
      ) : (
        <Typography className={classes.titleStyle}>
          Cancellation Email Details
        </Typography>
      )}

      <Card>
        <CardContent>
          {record.cancellation_assignments.map(function (cancellation) {
            return (
              <CancellationEmailDetailsForm
                key={cancellation.id}
                cancellation={cancellation}
                amounts={amounts[cancellation.id]}
                hasError={hasError}
                handleChange={handleChange}
              />
            );
          })}
        </CardContent>

        {punks1012SawWork && Object.keys(record).length > 0 ? (
          <FormControl component='fieldset' sx={{ marginLeft: '16px' }}>
            <FormLabel component='legend'>Send Email?</FormLabel>

            <RadioGroup
              sx={{ marginLeft: '16px' }}
              value={shouldSendEmail}
              onChange={e => onEmailOptionChange(e)}
            >
              <FormControlLabel
                value={true}
                control={<Radio color='primary' />}
                label='Yes'
              />

              <FormControlLabel
                value={false}
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        <CardActions>
          <Button
            onClick={handleUpdate}
            variant='contained'
            disabled={updateDisabled}
            style={{
              marginLeft: punks1012SawWork ? '8px' : 'auto',
              marginBottom: punks1012SawWork ? '8px' : 'unset',
            }}
          >
            Update
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

CancellationEmailDetailsCard.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    cancellation_assignments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        should_send_confirmation_email: PropTypes.bool,
      })
    ),
  }),
};

CancellationEmailDetailsForm.defaultProps = {
  record: {},
};

export default CancellationEmailDetailsCard;
