import {
  ArrayInput,
  CheckboxGroupInput,
  Create,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { transformVisitsData } from '@pumpkincare/claims';
import { ClaimCustomerInformation, ClaimPetInformation } from '@pumpkincare/shared';
import {
  useCustomer,
  usePet,
  usePlanByPetId,
  usePolicyByPetId,
  useWellnessByPetId,
} from '@pumpkincare/users';

import {
  DOCUMENT_ALLOWED_TYPES,
  DOCUMENT_MAX_SIZE,
} from '../../../constants/documents';
import NumericInput from '../../../lib/shared/ui/numeric-input';
import { parseAndFormatDate } from '../../../shared/utils';
import {
  createSignedUrlPromise,
  uploadMultipleFiles,
} from '../service/claimService';
import { claimSubmissionStyle } from './claim-submission-style';

function CustomToolbar({ label, ...rest }) {
  return (
    <Toolbar
      style={{
        backgroundColor: 'rgb(0, 125, 255, -0.92)',
        justifyContent: 'flex-end',
      }}
      {...rest}
    >
      <SaveButton label={label} />
    </Toolbar>
  );
}

CustomToolbar.propTypes = {
  label: PropTypes.string.isRequired,
};

function formatClaimTypeOptions(pet, isEstimate) {
  const options = [];

  if (pet?.latest_pet_policy) {
    options.push({ id: 'accident', name: 'Insurance (Accident)' });
    options.push({ id: 'illness', name: 'Insurance (Illness)' });
  }

  if (pet?.latest_pet_plan && !isEstimate) {
    options.push({ id: 'prevent', name: 'Preventive Essentials' });
  }

  if (pet?.wellness && !isEstimate) {
    const tierMap = { essential: 'Essential', premium: 'Premium' };
    options.push({
      id: 'wellness',
      name: `Wellness (Pumpkin Wellness Club - ${
        tierMap[pet.wellness.tier.split('.')[0]]
      } Plan)`,
    });
  }

  return options;
}

const transformMapKeys = {
  policy: { start: 'policy_effective_date', end: 'policy_end_date' },
  wellness: { start: 'start_date', end: 'end_date' },
};
function transformResponse(response, type) {
  return {
    ...response,
    data: response.data
      .filter(product => product.status !== 'voided')
      .map(product => ({
        id: product.id,
        name: `${parseAndFormatDate(
          product[transformMapKeys[type].start]
        )} - ${parseAndFormatDate(product[transformMapKeys[type].end])} (${
          product.status
        })`,
      })),
  };
}

function ClaimSubmission() {
  const classes = claimSubmissionStyle();

  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const navigate = useNavigate();
  const { punks1012SawWork } = useFlags();

  const { customerId, petId, isEstimate } = location.state;
  const { data: petData, isLoading: isPetDataLoading } = usePet(petId);
  const { data: petPlanData } = usePlanByPetId(petData?.latest_pet_plan?.plan_id);
  const { data: customerData, isLoading: isCustomerLoading } =
    useCustomer(customerId);
  const { data: policies = [], isLoading: isPolicyDataLoading } = usePolicyByPetId(
    petId,
    { select: response => transformResponse(response, 'policy') }
  );
  const { data: wellnessData = [], isLoading: isWellnessDataLoading } =
    useWellnessByPetId(petId, {
      select: response => transformResponse(response, 'wellness'),
    });

  const claimTypeChoices = formatClaimTypeOptions(
    petData,
    punks1012SawWork && isEstimate
  );

  const redAsterisk = <span style={{ color: 'red' }}>*</span>;

  const clinics =
    !isPetDataLoading && petData.associated_vets
      ? petData.associated_vets.map(vet => ({
          id: vet.id,
          name: vet.vet_name,
        }))
      : [];

  function onFileRejected() {
    notify('Upload rejected. Make sure it is the proper size and type.', 'error');
  }

  function onCreateClaim(data) {
    const invoiceFiles = data.invoice_files || [];
    const medicalRecordFiles = data.medical_record_files || [];

    const invoiceSignedUrlPromises = invoiceFiles.map(file => {
      return createSignedUrlPromise(customerId, file.title, 'claim_invoice');
    });

    const medicalRecordSignedUrlPromises = medicalRecordFiles.map(file => {
      return createSignedUrlPromise(customerId, file.title, 'medical_record');
    });

    return uploadMultipleFiles(invoiceSignedUrlPromises, invoiceFiles).then(
      invoicePromises => {
        return Promise.all(invoicePromises).then(invoiceValues => {
          return uploadMultipleFiles(
            medicalRecordSignedUrlPromises,
            medicalRecordFiles
          ).then(medPromise => {
            return Promise.all(medPromise).then(medicalRecordValues => {
              const isAccident = data.claim_type.some(type => type === 'accident');
              const isIllness = data.claim_type.some(type => type === 'illness');
              const isPrevent = data.claim_type.some(type => type === 'prevent');
              const isWellness = data.claim_type.some(type => type === 'wellness');

              const payload = {
                customer_id: customerId,
                pet_id: petId,
                claimed_amount: data.visits.reduce(
                  (partialSum, a) => partialSum + a.claimed_amount * 100,
                  0
                ),
                vet_id: data.vet_id,
                diagnosis_story: data.diagnosis_story,
                send_reimbursement_to_vet: !!data.send_reimbursement_to_vet,
                is_illness: isIllness,
                is_accident: isAccident,
                is_prevent: isPrevent,
                is_wellness: isWellness,
                is_estimate: data.is_estimate,
                is_multi_vet: data.is_multi_vet,
                loss_date: data.loss_date,
                policy_id: data.policy_id,
                invoice_s3_urls: invoiceValues,
                med_records_s3_urls: medicalRecordValues,
                visits: JSON.stringify(transformVisitsData(data?.visits)),
              };

              if (isIllness || isAccident || isPrevent)
                payload.policy_id = data.policy_id;
              if (isWellness) payload.wellness_id = data.wellness_id;

              return payload;
            });
          });
        });
      }
    );
  }

  function onSuccess(data) {
    navigate(`/claims/${data.id}/show`, {
      state: {
        record: { ...data, customer_id: customerId },
      },
    });
  }

  const createCopy = isEstimate ? 'Create Estimate' : 'Create Claim';

  function renderPolicyDropdown(claimTypes) {
    const acceptedTypes = ['accident', 'illness', 'prevent'];
    if (
      !claimTypes?.some(claimType => acceptedTypes.some(type => type === claimType))
    )
      return null;

    return isPolicyDataLoading ? (
      <CircularProgress />
    ) : (
      <div>
        <Typography className={classes.claimLabel}>
          Policy Period:{redAsterisk}
        </Typography>
        <SelectInput
          variant='outlined'
          label=''
          source='policy_id'
          choices={policies}
          validate={required()}
          sx={{ width: '100%' }}
        />
      </div>
    );
  }

  function renderWellnessDropdown(claimTypes) {
    if (!claimTypes?.some(claimType => claimType === 'wellness')) return null;

    return isWellnessDataLoading ? (
      <CircularProgress />
    ) : (
      <div>
        <Typography className={classes.claimLabel}>
          Wellness Period:{redAsterisk}
        </Typography>
        <SelectInput
          variant='outlined'
          label=''
          source='wellness_id'
          choices={wellnessData}
          validate={required()}
          sx={{ width: '100%' }}
        />
      </div>
    );
  }

  return (
    <Create
      title={createCopy}
      transform={onCreateClaim}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        sx={{ backgroundColor: 'rgb(0, 125, 255, 0.08)' }}
        toolbar={<CustomToolbar label={createCopy} />}
        defaultValues={{
          visits: [{ date_of_visit: '', claimed_amount: '' }],
          is_estimate: isEstimate,
        }}
      >
        <div style={{ width: '100%', marginBottom: '14px' }}>
          <IconButton
            style={{ display: 'flex', color: '#007DFF' }}
            onClick={() => redirect(`/customers/${customerId}/show`)}
            size='large'
          >
            <ArrowBackIosIcon />
            <Typography className={classes.claimTitle}>{createCopy}</Typography>
          </IconButton>

          <div className={classes.customerHeader}>
            {isCustomerLoading ? (
              <CircularProgress />
            ) : (
              <ClaimCustomerInformation customerData={customerData} />
            )}

            {!isPetDataLoading ? (
              <ClaimPetInformation petData={petData} petPlanData={petPlanData} />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
        <div className={classes.claimSubmission}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <div>
              {!punks1012SawWork ? (
                <>
                  <Typography className={classes.claimMainLabel}>
                    Filling a:{redAsterisk}
                  </Typography>

                  <RadioButtonGroupInput
                    source='is_estimate'
                    label={false}
                    validate={required()}
                    style={{ width: '400px' }}
                    choices={[
                      { id: false, name: 'Claim' },
                      ...(petData?.wellness ? [] : [{ id: true, name: 'Estimate' }]),
                    ]}
                  />
                </>
              ) : null}
            </div>

            <div>
              <Typography
                variant='h6'
                sx={{ marginBottom: '24px', fontWeight: '500' }}
              >
                Claim Type:{redAsterisk}
              </Typography>

              <CheckboxGroupInput
                source='claim_type'
                validate={required()}
                label={false}
                choices={claimTypeChoices}
                row={false}
                sx={{ marginTop: 0 }}
              />
            </div>
          </div>

          <Typography sx={{ marginBottom: '24px', fontWeight: '500' }} variant='h6'>
            Details:
          </Typography>
          <FormDataConsumer>
            {({ formData }) =>
              formData.claim_type?.some(
                claimType => claimType === 'accident' || claimType === 'illness'
              ) ? (
                <div style={{ width: '100%' }}>
                  <Typography className={classes.claimLabel}>
                    When did the accident/illness first occur?{redAsterisk}
                  </Typography>

                  <div className={classes.grid}>
                    <DateInput
                      label=''
                      source='loss_date'
                      variant='outlined'
                      validate={required()}
                      sx={{ width: '100%' }}
                    />
                  </div>
                </div>
              ) : null
            }
          </FormDataConsumer>

          <div className={classes.grid}>
            <div>
              <Typography className={classes.claimLabel}>
                Who should get reimbursed?{redAsterisk}
              </Typography>
              <SelectInput
                source='send_reimbursement_to_vet'
                variant='outlined'
                label=''
                id='select-send-reimbursement'
                choices={[
                  { id: false, name: 'Customer' },
                  { id: true, name: 'Vet' },
                ]}
                validate={required()}
                sx={{ width: '100%' }}
              />
            </div>

            <div>
              <Typography className={classes.claimLabel}>
                Which vet clinic did they visit?
              </Typography>
              <SelectInput
                variant='outlined'
                label=''
                source='vet_id'
                choices={[...clinics]}
                sx={{ width: '100%' }}
              />
            </div>
          </div>

          <FormDataConsumer>
            {({ formData }) => (
              <div className={classes.grid}>
                {renderPolicyDropdown(formData.claim_type)}
                {renderWellnessDropdown(formData.claim_type)}
              </div>
            )}
          </FormDataConsumer>

          <ArrayInput
            source='visits'
            label=''
            style={{ marginBottom: '0px', marginTop: '0px', maxWidth: '960px' }}
            fullWidth
          >
            <SimpleFormIterator
              getItemLabel={() => ''}
              disableReordering
              removeButton={
                <IconButton style={{ color: '#EE0004' }} size='large'>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              }
              addButton={
                <IconButton
                  style={{
                    color: '#007DFF',
                    fontSize: '14px',
                    fontWeight: '700',
                    marginLeft: '-12px',
                    borderRadius: '16px',
                  }}
                  size='large'
                >
                  Add More Loss Dates
                  <AddCircleOutlineIcon
                    style={{ color: '#3EC28F', marginLeft: '10px' }}
                  />
                </IconButton>
              }
              sx={{
                '& .RaSimpleFormIterator-line': {
                  width: '100%',
                  '&:not(:first-child)': {
                    paddingTop: '20px',
                  },
                },
                '& .RaSimpleFormIterator-form': {
                  width: '100%',
                },
                '& .RaSimpleFormIterator-buttons': {
                  alignItems: 'center',
                },
              }}
            >
              <FormDataConsumer>
                {({ getSource }) => {
                  return (
                    <div className={classes.grid}>
                      <DateInput
                        label='Date of Visit:'
                        source={getSource('date_of_visit')}
                        variant='outlined'
                        validate={required()}
                      />

                      <NumericInput
                        source={getSource('claimed_amount')}
                        label='Claimed Amount'
                        validate={required()}
                        prefix='$'
                        allowDecimalValue
                        precision={2}
                      />
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: '400',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            Be sure that the additional loss dates are within the same policy period.
          </Typography>

          <div style={{ marginTop: '48px' }}>
            <Typography className={classes.claimLabel}>
              Did they visit more than one vet clinic for this accident/illness?
              {redAsterisk}
            </Typography>
            <RadioButtonGroupInput
              source='is_multi_vet'
              label={''}
              row={false}
              validate={required()}
              choices={[
                { id: true, name: 'Yes' },
                { id: false, name: 'No' },
              ]}
            />
          </div>

          <div style={{ marginTop: '48px', maxWidth: '912px' }}>
            <Typography className={classes.claimLabel}>
              Describe the reason for the vet visit:{redAsterisk}
            </Typography>
            <TextInput
              multiline
              minRows={10}
              fullWidth
              validate={required()}
              variant='outlined'
              label=''
              source='diagnosis_story'
              resettable
              id='input-reason-vet-visit'
            />
          </div>

          <div style={{ marginTop: '48px', maxWidth: '912px' }}>
            <Typography className={classes.claimLabel}>
              Upload Documents: (Invoices)
            </Typography>
            <FileInput
              validate={required()}
              source='invoice_files'
              label=''
              maxSize={DOCUMENT_MAX_SIZE}
              options={{
                onDropRejected: onFileRejected,
              }}
              multiple={true}
              classes={{
                root: classes.fileInput,
                dropZone: classes.fileInputDropzone,
              }}
              accept={DOCUMENT_ALLOWED_TYPES.join()}
              placeholder={
                <>
                  <CloudUploadIcon style={{ color: '#2196F3' }} />
                  <Typography style={{ color: '#2196F3' }}>UPLOAD FILE</Typography>
                </>
              }
            >
              <FileField target='_blank' source='src' title='title' />
            </FileInput>
          </div>

          <div style={{ marginTop: '48px', maxWidth: '912px' }}>
            <Typography className={classes.claimLabel}>
              Upload Documents: (Medical records)
            </Typography>
            <FileInput
              source='medical_record_files'
              label=''
              maxSize={DOCUMENT_MAX_SIZE}
              options={{
                onDropRejected: onFileRejected,
              }}
              multiple={true}
              classes={{
                root: classes.fileInput,
                dropZone: classes.fileInputDropzone,
              }}
              accept={DOCUMENT_ALLOWED_TYPES.join()}
              placeholder={
                <>
                  <CloudUploadIcon style={{ color: '#2196F3' }} />
                  <Typography style={{ color: '#2196F3' }}>UPLOAD FILE</Typography>
                </>
              }
            >
              <FileField target='_blank' source='src' title='title' />
            </FileInput>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
}

export default ClaimSubmission;
