import { useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../shared/utils';
import {
  calcQuotePetDiscount,
  calcQuotePetPrice,
  calcTotalPerInstallmentsPerPet,
  formatDiscountName,
  formatPlanDescription,
  formatPlanShortDescription,
} from '../utils/quoteUtils';
import QuoteAddOnCard from './QuoteAddOnCard';
import { REIMBURSEMENT_RATE_90 } from '../../../../constants/quoteFlow';

function PetAccordion(props) {
  const { quotePet, skusToIgnore, isAnnualBilling = false } = props;

  const price = calcQuotePetPrice(quotePet, isAnnualBilling);

  const addonMonthlyTotal = calcTotalPerInstallmentsPerPet(
    quotePet,
    12,
    skusToIgnore
  );

  const planDescription = formatPlanDescription(quotePet);

  const planSummary = formatPlanShortDescription(quotePet);

  const planDetails = useMemo(
    () =>
      quotePet.plan
        ? JSON.parse(quotePet.plan.web_description).pickerModule.planDetails
        : [],
    [quotePet.plan]
  );

  const discount = calcQuotePetDiscount(quotePet, isAnnualBilling);

  const priceWithDiscount = price - discount;

  const reimbursementRate = quotePet.coinsurance
    ? `${100 - Number(quotePet.coinsurance)}%`
    : `${100 - Number(REIMBURSEMENT_RATE_90)}%`;

  return (
    <Accordion style={{ marginBottom: '12px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div>
          <Typography
            style={{ fontSize: '12px' }}
          >{`${quotePet.species} (${quotePet?.gender})`}</Typography>
          <Typography style={{ fontSize: '20px' }}>{quotePet.name}</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '420px',
            }}
          >
            <Typography>{planSummary}</Typography>
            <Typography>{`${
              isAnnualBilling
                ? formatCurrency(quotePet.totalPrice * 12)
                : formatCurrency(quotePet.totalPrice + addonMonthlyTotal)
            }`}</Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        <Typography
          style={{ padding: '12px 0px', fontWeight: '600', fontSize: '12px' }}
        >
          {planDescription}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography style={{ fontWeight: '600', fontSize: '12px' }}>
            INSURANCE
          </Typography>

          {discount > 0 ? (
            <div>
              <Typography style={{ fontWeight: '600', color: '#D4424E' }}>
                {formatCurrency(priceWithDiscount)}
              </Typography>
              <Typography
                style={{
                  fontWeight: '600',
                  textDecoration: 'line-through',
                }}
              >
                {price !== 0 ? formatCurrency(price) : '--.--'}
              </Typography>
            </div>
          ) : (
            <Typography style={{ fontWeight: '600' }}>
              {price !== 0 ? formatCurrency(price) : '--.--'}
            </Typography>
          )}
        </div>
        <Typography style={{ fontSize: '12px' }}>{`Annual Coverage Limit: ${
          quotePet.annual_limit
            ? quotePet.annual_limit.includes('Unlimited')
              ? 'Unlimited'
              : parseInt(quotePet.annual_limit).toLocaleString('en-US')
            : '--.--'
        }`}</Typography>
        <Typography
          style={{ fontSize: '12px' }}
        >{`Reimbursement Rate: ${reimbursementRate}`}</Typography>
        <Typography style={{ fontSize: '12px' }}>{`Annual Deductible: $${
          quotePet.deductible ? quotePet.deductible : '--.--'
        }`}</Typography>
        <Typography
          style={{ fontSize: '12px' }}
        >{`Coverage Start Date: 14 days after payment`}</Typography>
        <Typography
          style={{ fontSize: '12px' }}
        >{`Policy Start Date: On day of payment`}</Typography>
        {quotePet.discount
          ? quotePet.discount.map((discount, index) => {
              return (
                <Typography
                  style={{ fontSize: '12px', color: '#D4424E' }}
                  key={`discount-${index}`}
                >
                  {formatDiscountName(discount, {
                    partner: quotePet.partner,
                  })}
                </Typography>
              );
            })
          : null}

        {quotePet.has_prevent ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '16px',
              }}
            >
              <Typography style={{ fontWeight: '600', fontSize: '12px' }}>
                PREVENTIVE ESSENTIALS
              </Typography>
              <Typography style={{ fontWeight: '600' }}>{`${
                quotePet.plan
                  ? isAnnualBilling
                    ? formatCurrency(quotePet.plan.cost * 12)
                    : formatCurrency(quotePet.plan.cost)
                  : 0
              }`}</Typography>
            </div>

            {quotePet.preventDescription}

            <Typography
              style={{ fontSize: '12px' }}
            >{`Start Date: On day of payment`}</Typography>
            {planDetails.map((detail, index) => (
              <Typography style={{ fontSize: '12px' }} key={`detail-${index}`}>
                {detail}
              </Typography>
            ))}
          </>
        ) : null}
        {quotePet.addons?.length
          ? quotePet.addons.map(addon => {
              return skusToIgnore.indexOf(addon.addon.sku) > -1 ? null : (
                <QuoteAddOnCard key={addon.id} addon={addon} />
              );
            })
          : null}
      </AccordionDetails>
    </Accordion>
  );
}

PetAccordion.propTypes = {
  quotePet: PropTypes.shape({
    species: PropTypes.string.isRequired,
    gender: PropTypes.string,
    name: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired,
    annual_limit: PropTypes.string,
    coinsurance: PropTypes.string.isRequired,
    deductible: PropTypes.string,
    discount: PropTypes.array,
    has_prevent: PropTypes.bool,
    partner: PropTypes.string,
    preventDescription: PropTypes.string,
    plan: PropTypes.shape({
      web_description: PropTypes.string,
      cost: PropTypes.number,
    }),
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        addon: PropTypes.shape({
          sku: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
  skusToIgnore: PropTypes.array,
  isAnnualBilling: PropTypes.bool,
};

export default PetAccordion;
