import React from 'react';
import { TopToolbar, EditButton } from 'react-admin';

export default function CustomEditAction(props) {
  return (
    <TopToolbar>
      <EditButton {...props} />
    </TopToolbar>
  );
}
