import { Fragment } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { cancellationStatuses } from '../../constants/cancellationStatuses';
import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../../constants/quoteFlow';
import Divider from '../../shared/components/SanitizedComponents/Divider';
import { formatCurrency, formatISODate } from '../../shared/utils';
import { getTransactionFee } from '../../shared/utils/petUtils';
import { CANCELLATION_STATUS_COLORS } from './cancellation-status-color';

function ProductStatusPill({ status }) {
  const currentStatus = cancellationStatuses.find(item => item.name === status)?.id;

  return (
    <Box
      bgcolor={CANCELLATION_STATUS_COLORS[currentStatus]?.color}
      padding='4px 12px'
      flexBasis='35%'
      display='flex'
      borderRadius='100px'
      alignItems='center'
      justifyItems='center'
      lineHeight='initial'
    >
      <Box
        margin='auto'
        color={CANCELLATION_STATUS_COLORS[currentStatus]?.textColor}
      >
        <Typography variant='label' sx={{ fontSize: '13px' }}>
          {CANCELLATION_STATUS_COLORS[currentStatus]?.name}
        </Typography>
      </Box>
    </Box>
  );
}

ProductStatusPill.propTypes = {
  status: PropTypes.string,
};

function ProductCancelInfo({ cancelItem, classes }) {
  return (
    <>
      <Grid className={classes.cardGridStyle}>
        <Grid item>
          <Typography className={classes.cardItemLabelStyle}>Product:</Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.cardItemValueStyle}>
            {cancelItem?.type}
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.cardGridStyle}>
        <Grid item>
          <Typography className={classes.cardItemLabelStyle}>
            request date:
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.cardItemValueStyle}>
            {cancelItem.request_date}
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.cardGridStyle}>
        <Grid item>
          <Typography className={classes.cardItemLabelStyle}>
            Effective date:
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.cardItemValueStyle}>
            {' '}
            {cancelItem.effective_date}
          </Typography>
        </Grid>
      </Grid>

      <Grid className={classes.cardGridStyle}>
        <Grid item>
          <Typography className={classes.cardItemLabelStyle}>End Date:</Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.cardItemValueStyle}>
            {cancelItem.end_date}
          </Typography>
        </Grid>
      </Grid>

      {cancelItem.refund ? (
        <Grid className={classes.cardGridStyle}>
          <Grid item>
            <Typography className={classes.cardItemLabelStyle}>
              {cancelItem.type} Refund
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.cardItemValueStyle}>
              {formatCurrency(cancelItem.refund)}
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      {cancelItem.type !== 'Policy' ? (
        <Grid className={classes.cardGridStyle}>
          <Grid item>
            <Typography className={classes.cardItemLabelStyle}>
              {cancelItem.outstanding_balance > 0
                ? 'Outstanding Balance'
                : `${cancelItem.type} Refund`}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.cardItemValueStyle}>
              {formatCurrency(Math.abs(cancelItem.outstanding_balance))}
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      <Divider />

      <Typography variant='subtitle2' sx={{ marginTop: '8px' }}>
        {cancelItem.refund_title}
      </Typography>

      {cancelItem.value ? (
        <Typography variant='body2'>{cancelItem.value}</Typography>
      ) : null}

      {cancelItem.version ? (
        <Typography variant='body2'>Version: {cancelItem.version}</Typography>
      ) : null}

      {cancelItem.product_year ? (
        <Typography variant='body2'>
          {cancelItem.product_year.title}: {cancelItem.product_year.value}
        </Typography>
      ) : null}

      {cancelItem.transaction_fee ? (
        <Typography variant='body2'>
          Transaction fee: {cancelItem.transaction_fee}
        </Typography>
      ) : null}
    </>
  );
}

ProductCancelInfo.propTypes = {
  cancelItem: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
    request_date: PropTypes.string,
    effective_date: PropTypes.string,
    end_date: PropTypes.string,
    refund_title: PropTypes.string,
    product_year: PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number,
    }),
    version: PropTypes.string,
    transaction_fee: PropTypes.string,
    outstanding_balance: PropTypes.number,
    refund: PropTypes.number,
  }),
  classes: PropTypes.shape({
    cardGridStyle: PropTypes.string,
    cardItemLabelStyle: PropTypes.string,
    cardItemValueStyle: PropTypes.string,
  }),
};

function CancellationCards({ record, classes }) {
  function createCancellationItems(cancellation) {
    function petAgePlusOne(pet) {
      if (pet.age == UP_TO_5_MONTHS || pet.age == UP_TO_11_MONTHS) {
        return 1;
      }
      return pet.age;
    }

    return (
      <Fragment key={cancellation.id}>
        {getCancellationList(cancellation).map(cancelItem => (
          <Container
            sx={{
              padding: '16px !important',
              border: '1px solid #EBECEE',
              minWidth: '320px',
              marginBottom: '16px',
            }}
            key={`${cancelItem.id}-${cancelItem.type}`}
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '4px',
              }}
            >
              <Grid item>
                <Typography className={classes.cardTitleStyle}>
                  {cancelItem.pet.name}
                </Typography>
              </Grid>

              <Grid item>
                <ProductStatusPill
                  status={cancelItem.internal_cancellation_status}
                />
              </Grid>
            </Grid>

            <Typography
              variant='subtitle2'
              sx={{
                marginBottom: '4px',
                color: 'rgba(0, 0, 0, 0.6)',
                textTransform: 'capitalize',
              }}
            >
              {cancelItem.pet.pumpkin_id} {cancelItem.pet.species}(
              {cancelItem.pet?.gender}) AGE:
              {petAgePlusOne(cancelItem.pet)}
            </Typography>

            <Divider />

            <ProductCancelInfo cancelItem={cancelItem} classes={classes} />
          </Container>
        ))}
      </Fragment>
    );
  }

  // Define the desired order of internal_cancellation_status
  const order = {
    'Cancellation received': 1,
    'Cancellation pending': 2,
    'Cancellation completed': 3,
  };

  return (
    <div style={{ paddingRight: '0' }} key={record.id}>
      <Typography className={classes.titleStyle}>Cancellations</Typography>
      {record.cancellation_assignments
        .sort(
          (a, b) =>
            order[a.internal_cancellation_status] -
              order[b.internal_cancellation_status] ||
            new Date(b.created_at) - new Date(a.created_at)
        )
        .map(createCancellationItems)}
    </div>
  );
}

CancellationCards.propTypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
};

function getChargeType(isChargedAnnually) {
  return isChargedAnnually ? 'yr' : 'mo';
}

function formatPreventCancellation(cancellation, chargeType, transactionFee) {
  return {
    type: 'Prevent',
    value: `${formatCurrency(cancellation.pet_plan.cost)} / ${chargeType}`,
    effective_date: formatISODate(cancellation.pet_plan.plan_effective_date, {
      isGenerated: true,
    }),
    end_date: formatISODate(cancellation.pet_plan.plan_end_date, {
      isGenerated: true,
    }),
    refund_title: 'Preventive Essentials',
    refund: cancellation.prevent_refund,
    transaction_fee: transactionFee,
    outstanding_balance: cancellation?.outstanding_balance,
  };
}

function formatWellnessCancellation(cancellation, chargeType, transactionFee) {
  return {
    type: 'Wellness',
    value: `${formatCurrency(cancellation.wellness.price)} / ${chargeType}`,
    effective_date: formatISODate(cancellation.wellness.coverage_start_date, {
      isGenerated: true,
    }),
    end_date: formatISODate(cancellation.wellness.end_date, {
      isGenerated: true,
    }),
    refund_title: 'Pumpkin Wellness Club Plan',
    version: cancellation.wellness.program_id,
    outstanding_balance: cancellation?.outstanding_balance,
    refund: cancellation.wellness_refund,
  };
}

export function getCancellationList(cancellation) {
  const cancellationList = [];
  if (!cancellation?.cancellation_type) {
    return null;
  }

  const chargeType = getChargeType(cancellation.user.is_charged_annually);
  const transactionFee = `${formatCurrency(
    getTransactionFee(cancellation.user)
  )} / ${chargeType}`;

  // Common fields for all cancellation types
  const commonFields = {
    id: cancellation.id,
    request_date: formatISODate(cancellation.created_at),
    pet: cancellation.pet,
    internal_cancellation_status: cancellation.internal_cancellation_status,
  };

  const discountPercentage =
    cancellation.policy?.multiple_pet_discount_percentage || 0.0;

  switch (cancellation.cancellation_type.toLowerCase()) {
    case 'policy':
      cancellationList.push({
        type: 'Policy',
        value: `${formatCurrency(
          cancellation.policy.premium
        )} / ${chargeType} (${discountPercentage}% discount)`,
        effective_date: formatISODate(cancellation.policy.policy_effective_date, {
          isGenerated: true,
        }),
        end_date: formatISODate(cancellation.policy.policy_end_date, {
          isGenerated: true,
        }),
        refund_title: 'Pumpkin Pet Insurance',
        product_year: { title: 'Policy Year', value: cancellation.policy.year },
        version: cancellation.policy.program_id,
        refund: cancellation.insurance_refund,
        transaction_fee: transactionFee,
        status: cancellation.policy.status,
        ...commonFields,
      });

      /*
        response will return just policy type cancellation, but will include PEP data if there
        is a coupled PEP plan to cancel as well
       */
      if (cancellation?.pet_plan && Object.keys(cancellation.pet_plan).length) {
        cancellationList.push({
          ...formatPreventCancellation(cancellation, chargeType, transactionFee),
          ...commonFields,
        });
      }

      /*
        if cancelling BOTH insurance and wellness, type will be Policy but endpoint will return
        wellness data as well. If the pet has wellness but is ONLY cancelling insurance,
        wellness data does not populate in the response
       */
      if (cancellation?.wellness && Object.keys(cancellation.wellness).length) {
        cancellationList.push({
          ...formatWellnessCancellation(cancellation, chargeType, transactionFee),
          ...commonFields,
        });
      }

      break;

    case 'wellness':
      cancellationList.push({
        ...formatWellnessCancellation(cancellation, chargeType, transactionFee),
        ...commonFields,
      });
      break;

    case 'prevent':
      cancellationList.push({
        ...formatPreventCancellation(cancellation, chargeType, transactionFee),
        ...commonFields,
      });
      break;

    default:
      return null;
  }

  return cancellationList;
}

export default CancellationCards;
