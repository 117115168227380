import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '400',
      letterSpacing: '1.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      textTransform: 'uppercase',
    },
    content: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      letterSpacing: '0.5px',
      color: 'rgba(0, 0, 0, 0.87)',
      marginTop: '8px',
    },
  },
  { name: 'tile' }
);

export default function Tile({ title, content, className, style = {} }) {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, className)} style={style}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.content}>{content}</Typography>
    </div>
  );
}
