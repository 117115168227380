import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import {
  calcQuoteTotalPriceWithoutMonthlyFee,
  calcTotalPerInstallments,
} from '../utils/quoteUtils';
import PetAccordion from './PetAccordion';

function QuoteSummary(props) {
  const { quote, skusToIgnore } = props;

  const quoteTotalPrice = calcQuoteTotalPriceWithoutMonthlyFee(quote);
  const addonMonthlyTotal = calcTotalPerInstallments(quote, 12, skusToIgnore);
  const addonAnnuallyTotal = calcTotalPerInstallments(quote, 1, skusToIgnore);

  const isAnnualBilling =
    quote?.billingOption === 'annually' ||
    quote?.existingUserDetails?.is_charged_annually;

  const billingFrequency = isAnnualBilling ? 'Yearly' : 'Monthly';

  return (
    <div style={{ border: '1px solid', padding: '16px' }}>
      <div
        style={{
          width: '500px',
          paddingBottom: '12px',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '600',
            color: 'black',
          }}
        >
          QUOTE SUMMARY
        </Typography>
        <Button
          color='primary'
          style={{ textTransform: 'none', marginLeft: '18px' }}
          component={Link}
          startIcon={<EditIcon />}
          to={{
            pathname: props.edit ? `/quotes/${quote.id}/2` : `/quotes/create/2`,
          }}
        >
          EDIT
        </Button>
      </div>

      {quote?.quote_pets ? (
        <>
          <Divider style={{ margin: '12px 0px' }} />
          <Typography
            style={{ paddingBottom: '8px' }}
          >{`Pets (${quote.quote_pets.length})`}</Typography>
          {quote.quote_pets.map(quotePet => {
            return (
              <PetAccordion
                isAnnualBilling={isAnnualBilling}
                skusToIgnore={skusToIgnore}
                key={quotePet.id}
                quotePet={quotePet}
              />
            );
          })}
          <Divider style={{ margin: '12px 0px', color: '#373F51' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '0px',
              marginTop: '16px',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
              }}
            >
              Subtotal
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
              }}
            >
              {isAnnualBilling
                ? formatCurrency(quoteTotalPrice * 12)
                : formatCurrency(quoteTotalPrice + addonMonthlyTotal)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '0px',
              marginTop: '16px',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
              }}
            >
              Transaction Fee
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
              }}
            >
              {isAnnualBilling
                ? '$0.0'
                : quote.transaction_fee
                  ? formatCurrency(quote.transaction_fee)
                  : '$0.0'}
            </Typography>
          </div>
          {!addonAnnuallyTotal ? (
            <Divider style={{ margin: '12px 0px', color: '#373F51' }} />
          ) : null}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '0px',
              marginTop: '16px',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
              }}
            >
              {`Total ${billingFrequency} Cost:`}
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              {`${
                isAnnualBilling
                  ? formatCurrency(quoteTotalPrice * 12)
                  : formatCurrency(
                      quoteTotalPrice + quote.transaction_fee + addonMonthlyTotal
                    )
              }`}
            </Typography>
          </div>
          {addonAnnuallyTotal ? (
            <>
              <Divider style={{ margin: '12px 0px', color: '#373F51' }} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingBottom: '0px',
                  marginTop: '16px',
                }}
              >
                <Typography
                  style={{
                    fontSize: '12px',
                  }}
                >
                  Pay Today:
                </Typography>
                <Typography
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  {formatCurrency(
                    (isAnnualBilling
                      ? quoteTotalPrice * 12
                      : quoteTotalPrice +
                        quote.transaction_fee +
                        addonMonthlyTotal) + addonAnnuallyTotal
                  )}
                </Typography>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <CircularProgress data-testid='circular-progress' />
        </div>
      )}
    </div>
  );
}

export default QuoteSummary;
