import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(
  {
    subtitle: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
    },
    preventInfo: {
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: '#2196F3',
    },
    vetClinic: {
      lineHeight: '20px',
      fontWeight: '700',
      color: 'black',
    },
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '500',
      color: 'black',
    },
    container: {
      width: '500px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 24px 0px 24px',
      alignItems: 'center',
    },
    dialogActions: {
      justifyContent: 'center',
      padding: '8px 24px',
      display: 'block',
    },
  },
  { name: 'customer-detail-modal' }
);

function getMostRecentVetClinic(vets = []) {
  return [...vets].sort((l, r) => -l.created_at.localeCompare(r.created_at))[0];
}

function LegacyCustomerDetailModal(props) {
  const { open, onClose, user, onAddInsurance } = props;

  const classes = useStyles();

  const vet = getMostRecentVetClinic(user?.vets);

  return (
    <Dialog open={open} onClose={onClose} aria-label='Show Customer Detail'>
      <div className={classes.container}>
        <Typography className={classes.title}>
          Looks like this customer already exists.
        </Typography>
        <IconButton
          style={{
            color: 'black',
          }}
          onClick={onClose}
          size='large'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent
        style={{ display: 'inline-grid', paddingLeft: '0', paddingRight: '0' }}
      >
        <div style={{ padding: '16px 24px 32px' }}>
          <Typography
            className={classes.subtitle}
            style={{
              margin: '0px 0px 6px 0px',
            }}
          >
            CUSTOMER DETAILS
          </Typography>
          <Typography
            className={classes.vetClinic}
          >{`${user.first_name} ${user.last_name}`}</Typography>
          <Typography>{`PKN: ${user.pumpkin_id}`}</Typography>
          <Typography>{`Email: ${user.email}`}</Typography>
          <Typography>{`Zipcode: ${user?.rating_address?.zipcode}`}</Typography>

          <Typography
            className={classes.subtitle}
            style={{
              margin: '24px 0px 6px 0px',
            }}
          >
            EXISTING PET INFO
          </Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {user.pets
              ? user.pets.map(pet => {
                  return (
                    <div style={{ width: '275px' }} key={pet.id}>
                      <Typography
                        style={{
                          lineHeight: '20px',
                          fontWeight: '700',
                          color: 'black',
                        }}
                      >
                        {pet.name}
                      </Typography>
                      <Typography>{`Age: ${pet.age}`}</Typography>
                      <Typography>{`Species: ${pet.species}`}</Typography>
                      <Typography
                        style={{ marginBottom: '12px' }}
                      >{`Breed: ${pet?.breed_name}`}</Typography>{' '}
                    </div>
                  );
                })
              : null}
          </div>
          {vet ? (
            <>
              <Typography
                className={classes.subtitle}
                style={{
                  margin: '12px 0px 6px 0px',
                }}
              >
                PRIMARY VET INFO
              </Typography>
              <Typography className={classes.vetClinic}>{vet.vet_name}</Typography>
              {vet.has_pep4 ? (
                <Typography className={classes.preventInfo}>
                  Prevent 4.0 Plan Available
                </Typography>
              ) : null}

              <Typography style={{ maxWidth: '200px' }}>
                {vet.normalized_addr}
              </Typography>
            </>
          ) : null}
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant='contained'
          onClick={onAddInsurance}
          color='primary'
          style={{ width: '100%' }}
        >
          ADD A PET
        </Button>

        <Typography
          style={{
            marginTop: '24px',
            textAlign: 'center',
          }}
        >
          Need to make changes?{' '}
          <Link to={`/customers/${user.id}/show`} className={classes.link}>
            Edit customer Details
          </Link>
        </Typography>
      </DialogActions>
    </Dialog>
  );
}

LegacyCustomerDetailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pumpkin_id: PropTypes.string,
    email: PropTypes.string,
    rating_address: PropTypes.shape({
      zipcode: PropTypes.string,
    }),
    pets: PropTypes.array,
    vets: PropTypes.array,
  }),
  onAddInsurance: PropTypes.func,
};

export default LegacyCustomerDetailModal;
