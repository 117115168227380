import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AutocompleteInput } from '@pumpkincare/shared';

export default function ClaimBundleItems(props) {
  const {
    bundleItems,
    onChange,
    onBundleItemDelete,
    onBundleItemAdd,
    preventProductsData,
    preventSubClaim,
  } = props;

  const bundleLineItem = preventSubClaim?.line_items_decision.find(
    lineItem => lineItem.bundle_items.length > 0
  );

  const preventBundleLineItems = bundleItems.length
    ? bundleItems
    : bundleLineItem
    ? [
        {
          ...bundleLineItem,
          bundle_items: bundleLineItem.bundle_items.map(item => {
            return {
              ...item,
              approved_amount: item.approved_amount / 100,
              included_in_prevent: !!item.is_included,
              quantity: parseInt(item.quantity),
              item_type: item.prevent_product_id,
            };
          }),
          approved_amount: bundleLineItem.reimbursible_amount / 100,
        },
      ]
    : [];

  return preventBundleLineItems.map(bundleItem => {
    return (
      <div
        style={{
          marginTop: '24px',
          marginBottom: '48px',
        }}
        key={bundleItem.id}
      >
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          Bundle Items for {bundleItem.description}
        </Typography>
        {bundleItem.bundle_items.map((item, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '16px',
              width: '900px',
            }}
            key={item.id}
          >
            <IconButton
              onClick={() =>
                onBundleItemDelete(bundleItem.id, preventBundleLineItems, index)
              }
              style={{ color: '#EE0004' }}
              size="large">
              <RemoveCircleOutlineIcon />
            </IconButton>
            {preventProductsData.length ? (
              <AutocompleteInput
                options={preventProductsData}
                label='Item Type'
                value={
                  item.item_type
                    ? {
                        name: preventProductsData.find(
                          product => product.id === item.item_type
                        ).name,
                        id: item.item_type,
                      }
                    : null
                }
                onChange={event =>
                  onChange(
                    event.id,
                    index,
                    'item_type',
                    bundleItem.id,
                    preventBundleLineItems
                  )
                }
              />
            ) : null}

            <TextField
              onChange={event =>
                onChange(
                  event.target.value,
                  index,
                  'quantity',
                  bundleItem.id,
                  preventBundleLineItems
                )
              }
              value={item.quantity}
              variant='outlined'
              label='Qty'
              style={{ width: '70px' }}
              inputProps={{ min: 1 }}
              type='number'
            />
            <TextField
              onChange={event =>
                onChange(
                  event.target.value,
                  index,
                  'approved_amount',
                  bundleItem.id,
                  preventBundleLineItems
                )
              }
              value={item.approved_amount}
              label='Amount Approved'
              variant='outlined'
              style={{ width: '180px' }}
              type='number'
            />
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Included in Prevent Plan?</FormLabel>

              <RadioGroup
                value={item.included_in_prevent}
                onChange={() =>
                  onChange(
                    !item.included_in_prevent,
                    index,
                    'included_in_prevent',
                    bundleItem.id,
                    preventBundleLineItems
                  )
                }
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio color='primary' />}
                  label='Yes'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio color='primary' />}
                  label='No'
                />
              </RadioGroup>
            </FormControl>

            <div style={{ width: '48px' }}>
              {bundleItem.bundle_items.length === index + 1 ? (
                <IconButton
                  onClick={() =>
                    onBundleItemAdd(preventBundleLineItems, bundleItem.id)
                  }
                  style={{ color: '#3EC28F' }}
                  size="large">
                  <AddCircleOutlineIcon />
                </IconButton>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    );
  });
}
