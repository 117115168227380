import { useState } from 'react';
import {
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdate,
} from 'react-admin';
import { Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { isStatusBlockedForUpdate } from '@pumpkincare/claims';
import { useOpsList } from '@pumpkincare/users';

import { orderUsersByEmail } from '../../../../../shared/utils/orderUsersByEmail';

export default function ClaimBulkActionButtons(props) {
  const [selectedAssignee, setSelectedAssignee] = useState({});
  const { data: options = [], isLoading: isOpsListLoading } = useOpsList();

  const { selectedIds = [], claims = [], unselectIds } = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('claims');
  const [update, { isLoading }] = useUpdate();

  function handleAssignClaimClick() {
    if (
      selectedIds.some(selectedId => {
        return isStatusBlockedForUpdate(claims[selectedId]?.status);
      })
    ) {
      notify(
        `Completed claims cannot be re-assigned. Please unselect the completed claims and try again.`,
        {
          type: 'warning',
        }
      );
    } else {
      update(
        'claims/assignment',
        {
          data: {
            claims_ids: selectedIds,
            ops_user_id: selectedAssignee,
          },
          meta: { method: 'PATCH' },
        },
        {
          onSuccess: () => {
            refresh();
            notify('Claims succesfully assigned');
            unselectAll('claims');
            unselectIds();
          },
          onError: error => {
            notify(`Claims were not assigned: ${error.message}`, {
              type: 'warning',
            });
          },
        }
      );
    }
  }

  function handleAssigneeChange(event, values) {
    setSelectedAssignee(values?.id || '');
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.name,
  });

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        padding: '8px',
        width: '65%',
        justifyContent: 'space-between',
      }}
    >
      <Typography>{`${selectedIds?.length || 0} Claim${
        selectedIds?.length > 1 ? 's' : ''
      } Selected`}</Typography>
      <Autocomplete
        options={options ? orderUsersByEmail(options) : []}
        filterOptions={filterOptions}
        getOptionLabel={option => option.name}
        style={{ width: 300, marginBottom: '8px' }}
        onChange={handleAssigneeChange}
        renderOption={(props, option, state) => (
          <div {...props} key={option.email} style={{ display: 'block' }}>
            <div
              style={{
                color: '#000000',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.25px',
              }}
            >
              {option.name}
            </div>
            <div
              style={{
                color: '#767676',
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
              }}
            >
              {option.email}
            </div>
          </div>
        )}
        renderInput={params => (
          <TextField {...params} label='ASSIGNEE' variant='outlined' />
        )}
      />
      <Button
        label='Assign Claim'
        variant='contained'
        disabled={isLoading}
        onClick={handleAssignClaimClick}
      />
    </div>
  );
}
