import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classnames from 'classnames';

import { useProductsByState } from '@pumpkincare/quotes';

import { formatCurrency } from '../../../../shared/utils/currencyUtils';
import {
  calcQuotePetDiscount,
  calcQuoteTotalPrice,
  calculateQuoteStates,
} from '../utils/quoteUtils';
import PetQuoteCard from './PetQuoteCard';
import { quoteTabStyle } from './QuoteTabStyle';
import SendQuoteByEmailModal from './SendQuoteByEmailModal';

function QuoteTab(props) {
  const { quote, setQuote, quoteUrl, discount, setShowNonConversionModal } = props;
  const { data: products = {} } = useProductsByState(quote.policy_state);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const skusToIgnore = ['embark-dna-kit'];
  const classes = quoteTabStyle();

  const monthlyFee = quote.transaction_fee ? quote.transaction_fee : 0;

  const totalPrice = quote.quote_pets
    ? quote.quote_pets.reduce((total, pet) => total + pet.totalPrice, 0) + monthlyFee
    : 0;

  const [quoteTotalPrice, setQuoteTotalPrice] = useState(totalPrice);
  const [addonAnnualTotal, setAddonAnnualTotal] = useState(0);
  const [billingOption, setBillingOption] = useState();
  const [isBillingDisabled, setBillingDisabled] = useState();
  const isBilledAnnually =
    billingOption === 'annually' || quote.existingUserDetails?.is_charged_annually;
  const [radioButtonBillingOptionController, setRadioButtonBillingOptionController] =
    useState('monthly');

  const [totalDiscount, setTotalDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (quote.quote_pets) {
      let totalDiscountAdded = 0;
      quote.quote_pets.forEach(quotePet => {
        totalDiscountAdded = totalDiscountAdded + calcQuotePetDiscount(quotePet);
      });
      setTotalDiscount(
        billingOption === 'annually' ||
          quote.existingUserDetails?.is_charged_annually
          ? totalDiscountAdded * 12
          : totalDiscountAdded
      );
    }
  }, [quote, billingOption]);

  useEffect(() => {
    const {
      addonAnnualTotal,
      billingDisabled,
      radioButtonBillingOption,
      totalPrice,
    } = calculateQuoteStates(quote, monthlyFee, billingOption, skusToIgnore);
    setAddonAnnualTotal(addonAnnualTotal);
    // ternary here to avoid setting states to undefined
    billingDisabled ? setBillingDisabled(true) : null;
    radioButtonBillingOption
      ? setRadioButtonBillingOptionController(radioButtonBillingOption)
      : null;
    setQuoteTotalPrice(totalPrice);
  }, [quote, monthlyFee, billingOption, skusToIgnore, addonAnnualTotal]);

  const navigate = useNavigate();
  const notify = useNotify();

  function handleEditOwnerClick() {
    if (quote.existingUserDetails) {
      notify('Edit user details from customer details page', {});
    } else {
      setRadioButtonBillingOptionController('monthly');
      setBillingOption('monthly');

      navigate(props.edit ? `/quotes/${quote.id}` : '/quotes/create');
    }
  }

  function handleSaveQuoteClick() {
    setShowNonConversionModal({ event: 'SAVE', quote: quote });
  }

  function handleNextClick() {
    let error;
    quote.quote_pets.forEach(pet => {
      if (pet.addons?.length) {
        pet.addons.forEach(addon => {
          if (addon.installments !== 1 && isBilledAnnually === true) {
            error = `Please choose a valid installment for add-on: ${addon.name}, pet: ${pet.name}`;
          }
        });
      }
    });
    if (error) {
      notify(error, 'error');
      return;
    }
    navigate(props.edit ? `/quotes/${quote.id}/3` : '/quotes/create/3');
  }

  function handleBillingOptionChange(event) {
    setBillingOption(event.target.value);
    setRadioButtonBillingOptionController(event.target.value);
    if (event.target.value === 'annually') {
      setQuoteTotalPrice(calcQuoteTotalPrice(quote, 0) * 12);
    } else if (event.target.value === 'monthly') {
      setQuoteTotalPrice(calcQuoteTotalPrice(quote, monthlyFee));
    }
    setQuote({ ...quote, billingOption: event.target.value });
  }

  return (
    <>
      <div
        className={!isBilledAnnually ? classes.flexDiv : classes.flexDivCloseButton}
      >
        {!isBilledAnnually ? (
          <CopyToClipboard
            text={quoteUrl}
            onCopy={() => {
              notify('Quote link copied', {});
            }}
          >
            <IconButton
              disabled={isLoading}
              style={{ padding: '0px', color: '#3F51B5' }}
              size='large'
            >
              <LinkIcon style={{ paddingRight: '3px' }} />
              <Typography className={classes.linkText}>QUOTE LINK</Typography>
            </IconButton>
          </CopyToClipboard>
        ) : null}

        <div>
          <IconButton
            disabled={isLoading}
            onClick={() =>
              setShowNonConversionModal({ event: 'CLOSE', quote: quote })
            }
            style={{ padding: '0px', color: '#3F51B5' }}
            size='large'
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Typography
          style={{
            paddingLeft: '32px',
            paddingTop: '32px',
          }}
        >
          Owner
        </Typography>

        <IconButton
          style={{ padding: '0px', width: '40px' }}
          onClick={handleEditOwnerClick}
          size='large'
        >
          <EditIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          width: '705px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <div className={classes.ownerInfoCheckoutTab}>
            <Typography className={classes.textFontSize}>
              {`${quote.first_name} ${quote.last_name}`}
            </Typography>
            <Typography className={classes.textFontSize}>{quote.email}</Typography>
          </div>
          <div className={classes.ownerInfoCheckoutTab}>
            <Typography className={classes.textFontSize}>
              {`${quote.policy_state}, ${quote.policy_zipcode}`}
            </Typography>
            {discount ? (
              <Typography
                className={classes.textFontSize}
              >{`Team: ${discount.name}`}</Typography>
            ) : null}
          </div>
        </div>
        <div>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Billing Options</FormLabel>
            <RadioGroup
              row
              onChange={handleBillingOptionChange}
              defaultValue='monthly'
              value={radioButtonBillingOptionController}
            >
              <FormControlLabel
                value='monthly'
                control={
                  <Radio disabled={isLoading || isBillingDisabled} color='primary' />
                }
                label='Monthly'
              />
              <FormControlLabel
                value='annually'
                control={
                  <Radio disabled={isLoading || isBillingDisabled} color='primary' />
                }
                label='Annually'
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <div style={{ marginBottom: '16px' }}>
        {quote.quote_pets
          ? quote.quote_pets.map(quotePet =>
              quotePet.age ? (
                <PetQuoteCard
                  key={quotePet.id}
                  quotePet={quotePet}
                  quote={quote}
                  setQuote={setQuote}
                  billingOption={billingOption}
                  setBillingOption={setBillingOption}
                  setRadioButtonBillingOptionController={
                    setRadioButtonBillingOptionController
                  }
                  edit={props.edit}
                  products={products}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              ) : null
            )
          : null}
      </div>

      {totalDiscount > 0 ? (
        <>
          <Typography className={classes.discountApplied}>
            Discount Applied:
          </Typography>
          <div
            className={classnames(classes.flexDiv, classes.monthlyTransactionFee)}
          >
            <Typography className={classes.textFontSize}>
              {discount ? `Team Discount - ${discount.name}` : `Discount`}
            </Typography>
            <Typography className={classes.textFontSize}>
              {`-$${totalDiscount.toFixed(2)}`}
            </Typography>
          </div>
          <Divider
            style={{
              margin: '12px 0px 12px 32px',
              width: '720px',
            }}
          />
        </>
      ) : null}
      <div className={classnames(classes.flexDiv, classes.monthlyTransactionFee)}>
        <Typography className={classes.textFontSize}>
          Monthly Transaction Fee
        </Typography>
        <Typography className={classes.textFontSize}>
          {isBilledAnnually ? '$0.00' : `$${monthlyFee.toFixed(2)}`}
        </Typography>
      </div>
      <Divider
        style={{
          margin: '12px 0px 12px 32px',
          width: '720px',
        }}
      />

      <div className={classnames(classes.flexDiv, classes.totalMonthlyRate)}>
        <Typography className={classes.subtitle}>
          {isBilledAnnually ? 'Total Annual Rate' : 'Total Monthly Rate'}
        </Typography>
        <Typography className={classes.subtitle}>
          {formatCurrency(quoteTotalPrice)}
        </Typography>
      </div>

      <div className={classnames(classes.flexDiv, classes.buttonsDiv)}>
        <div style={{ display: 'flex' }}>
          <IconButton
            disabled={isLoading}
            style={{ padding: '0px 8px', color: '#3F51B5' }}
            onClick={handleSaveQuoteClick}
            size='large'
          >
            <SaveIcon style={{ paddingRight: '3px' }} />
            <Typography className={classes.linkText}>SAVE QUOTE</Typography>
          </IconButton>
          {!isBilledAnnually ? (
            <div>
              <IconButton
                disabled={isLoading}
                style={{ padding: '0px 8px', color: '#3F51B5' }}
                onClick={() => setShowSendEmailModal(!showSendEmailModal)}
                size='large'
              >
                <SendIcon style={{ paddingRight: '3px' }} />
                <Typography className={classes.linkText}>SEND</Typography>
              </IconButton>
            </div>
          ) : null}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={isLoading}
            variant='outlined'
            onClick={handleNextClick}
            color='primary'
          >
            Next
          </Button>
        </div>
      </div>
      <SendQuoteByEmailModal
        showDialog={showSendEmailModal}
        setShowDialog={setShowSendEmailModal}
        quote={quote}
      />
    </>
  );
}

export default QuoteTab;
